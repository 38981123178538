import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import LoginSettingsForm from '../LoginSettingsForm';
import './styles.css';

const SettingsCard = () => (
    <Card className="Settings-card">
        <CardHeader>
            hol&apos;s app | Partner
        </CardHeader>
        <CardBody>
            <CardTitle>Einstellungen</CardTitle>
            <LoginSettingsForm />
            <div>
                <Link to="/login">Zurück zur Anmeldung</Link>
            </div>
        </CardBody>
    </Card>
);

export default SettingsCard;
