import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from 'reactstrap';
import LoginForm from 'components/LoginForm';
import './styles.css';

const LoginCard = ({ onSubmit }) => (
    <Card className="Login-card">
        <CardHeader>
            hol&apos;s app | Partner
            <Link to="/login-settings" style={{ float: 'right' }}>Einstellungen</Link>
        </CardHeader>
        <CardBody>
            <CardTitle>Anmeldung</CardTitle>
            <LoginForm onSubmit={onSubmit} />
        </CardBody>
    </Card>
);

LoginCard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default LoginCard;
