import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Sound from 'services/Sound';

import {
    setSounds as setSoundsAction,
    selectSound as selectSoundAction,
} from './actions';

function requireAll(context) {
    const files = {};
    context.keys().forEach(fn => { files[fn] = context(fn).default; });

    return files;
}

class AudioPlayer extends React.Component { // eslint-disable-line react/prefer-stateless-function

    constructor(props) {
        super(props);

        const files = requireAll(require.context('./sounds', true, /\.mp3$/));
        const noSound = { './00-Keiner.mp3': null };
        props.setSounds({ ...noSound, ...files });
        props.selectSound(Object.keys(noSound)[0]); // Select no signal by default
    }

    componentDidUpdate(prevProps) {
        const { sounds, merchantLastFetched, merchant } = this.props;

        if (sounds && prevProps.merchantLastFetched !== merchantLastFetched) {
            Sound.load(sounds[Object.keys(sounds)[merchant.sound]]);
        }
    }

    render() {
        return null;
    }

}

AudioPlayer.propTypes = {
    setSounds: PropTypes.func.isRequired,
    selectSound: PropTypes.func.isRequired,
    sounds: PropTypes.object.isRequired,
    merchant: PropTypes.object,
    merchantLastFetched: PropTypes.instanceOf(Date),
};

function mapStateToProps(state) {
    return {
        isPlaying: state.audioPlayer.isPlaying,
        sounds: state.audioPlayer.sounds,
        merchant: state.app.merchant,
        merchantLastFetched: state.app.merchantLastFetched,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSounds: sounds => dispatch(setSoundsAction(sounds)),
        selectSound: key => dispatch(selectSoundAction(key)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
