import {
    SELECT_PRODUCT_OPTION_GROUP,
    SELECT_PRODUCT_OPTION,
} from './constants';

export const selectProductOptionGroup = productOptionGroupId => ({
    type: SELECT_PRODUCT_OPTION_GROUP,
    productOptionGroupId,
});

export const selectProductOption = productOptionId => ({
    type: SELECT_PRODUCT_OPTION,
    productOptionId,
});
