import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

class WarningDialog extends React.Component {

    constructor(props) {
        super(props);

        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleConfirm(event) {
        this.props.onConfirm(event);
        this.props.toggle();
    }

    handleCancel(event) {
        if (this.props.onCancel) {
            this.props.onCancel(event);
        }
        this.props.toggle();
    }

    render() {
        const {
            isOpen,
            title,
            message,
            confirmButtonText,
            toggle,
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        onClick={this.handleCancel}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleConfirm}
                    >
                        {confirmButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

}

WarningDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string,
};

WarningDialog.defaultProps = {
    title: 'Warnung',
    confirmButtonText: 'Fortfahren',
};

export default WarningDialog;
