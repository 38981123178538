export const CREATE_ORDER_REQUEST = 'Cart/CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'Cart/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'Cart/CREATE_ORDER_ERROR';

export const SET_CART = 'Cart/SET_CART';

export const SET_NOTE = 'Cart/SET_NOTE';
export const SET_OWN_PACKAGING = 'Cart/SET_OWN_PACKAGING';
export const SET_SELECTED_CATEGORY_ID = 'Cart/SET_SELECTED_CATEGORY_ID';
export const SET_PICK_UP_TIME = 'Cart/SET_PICK_UP_TIME';

export const RESET_CART = 'Cart/RESET_CART';

export const PICK_UP_TYPE = Object.freeze({
    now: 'NOW',
    preOrder: 'PRE_ORDER',
});
