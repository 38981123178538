import { toast } from 'react-toastify';
import moment from 'moment';

import OrdersEventSource from 'services/OrdersEventSource';

import { API_URI } from 'config';
import { convertDayToRange, authorizedRequest } from 'utils';

import {
    EVENTSOURCE_ON_ERROR,
    EVENTSOURCE_ON_MESSAGE,
    EVENTSOURCE_ON_OPEN,
    EVENTSOURCE_ON_PING,
    GET_QUEUE_ERROR,
    GET_QUEUE_REQUEST,
    GET_QUEUE_SUCCESS,
    SET_ORDER_STATE_ERROR,
    SET_ORDER_STATE_REQUEST,
    SET_ORDER_STATE_SUCCESS,
    EVENTSOURCE_ON_CLOSE,
} from './constants';

/*
 * ACTIONS
 */

const getQueueRequest = () => ({
    type: GET_QUEUE_REQUEST,
});

const getQueueSuccess = data => ({
    type: GET_QUEUE_SUCCESS,
    data,
});

const getQueueError = error => ({
    type: GET_QUEUE_ERROR,
    error,
});

const setOrderStateRequest = (orderId, state) => ({
    type: SET_ORDER_STATE_REQUEST,
    orderId,
    state,
});

const setOrderStateSuccess = (orderId, state) => ({
    type: SET_ORDER_STATE_SUCCESS,
    orderId,
    state,
});

const setOrderStateError = error => ({
    type: SET_ORDER_STATE_ERROR,
    error,
});

const eventSourceOnOpen = () => ({
    type: EVENTSOURCE_ON_OPEN,
});

const eventSourceOnPing = () => ({
    type: EVENTSOURCE_ON_PING,
});

const eventSourceOnMessage = data => ({
    type: EVENTSOURCE_ON_MESSAGE,
    data,
});

const eventSourceOnError = error => ({
    type: EVENTSOURCE_ON_ERROR,
    error,
});

export const eventSourceOnClose = () => ({
    type: EVENTSOURCE_ON_CLOSE,
});

/*
 * DISPATCHERS
 */

export function openServerSentOrders() {
    return dispatch => {
        OrdersEventSource.open(
            dispatch,
            handleStreamOnOpen,
            eventSourceOnPing,
            handleStreamOnMessage,
            handleStreamOnError,
        );
    };
}

export function handleStreamOnOpen() {
    return dispatch => {
        const { from, to } = convertDayToRange(moment());
        dispatch(eventSourceOnOpen());
        dispatch(fetchQueue(from, to));
    };
}

export function handleStreamOnMessage(data) {
    return dispatch => {
        dispatch(eventSourceOnMessage(data));
    };
}

export function handleStreamOnError(err) {
    return dispatch => {
        dispatch(eventSourceOnError(err));
        setTimeout(() => {
            dispatch(openServerSentOrders());
        }, 3000);
    };
}

export function closeServerSentOrders() {
    return dispatch => {
        OrdersEventSource.close(dispatch, eventSourceOnClose);
    };
}

export function fetchQueueForToday() {
    return dispatch => {
        const today = moment().toISOString();
        const { from, to } = convertDayToRange(today);
        dispatch(fetchQueue(from, to));
    };
}

export function fetchQueue(from, to) {
    const params = new URLSearchParams();

    if (from && to) {
        params.set('from', moment(from).toISOString());
        params.set('to', moment(to).toISOString());
    }

    return dispatch => {
        dispatch(getQueueRequest());

        const url = new URL(`/api/merchant/order?${params.toString()}`, API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(json => {
                dispatch(getQueueSuccess(json));
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Laden der Warteschlange (${error.message})`,
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(getQueueError(error));
                console.log(error);
            });
    };
}

export function changeOrderState(orderId, state, payload = {}) {
    return dispatch => {
        dispatch(setOrderStateRequest(orderId, state));

        const url = new URL(`/api/merchant/order/${orderId}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PATCH',
            payload: { state, ...payload },
        })
            .then(() => {
                dispatch(setOrderStateSuccess(orderId, state));
            })
            .catch(error => {
                toast.error(
                    'Der Status der Bestellung konnte nicht geändert werden.',
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(setOrderStateError(error));
            });
    };
}
