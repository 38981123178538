import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import Storage from 'services/Storage';
import Device from 'services/Device';
import Push from 'services/Push';

import { API_URI } from 'config';
import { request, authorizedRequest } from 'utils';

import { openServerSentOrders, closeServerSentOrders } from 'containers/Monitor/action';
import { isMerchantActivated, checkOpenState } from 'containers/Online/action';
import { fetchMerchantData } from 'containers/App/actions';

import {
    LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from './constants';

/*
 * ACTION CREATORS
 */

function loginRequest() {
    return {
        type: LOGIN_REQUEST,
    };
}

export function loginSuccess(result, queueMonitor) {
    return {
        type: LOGIN_SUCCESS,
        result,
        queueMonitor,
    };
}

function loginError(error) {
    return {
        type: LOGIN_ERROR,
        error,
    };
}

function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

function logoutError(error) {
    return {
        type: LOGOUT_ERROR,
        error,
    };
}

/*
 * DISPATCHERS
 */

/**
 * Set the login state.
 *
 * This is called after the App's initCordova() action, because we need the
 * SecureStorage plugin up and running. If we have a login token in the
 * SecureStorage, this function is called with the JWT token of the storeage.
 *
 * This function is also called after the actual login, see login() action below.
 *
 * @param {string|null} token JWT token
 * @returns {function(*)}
 */
export function setLogin(token = null, queueMonitor = null) {
    return dispatch => {
        if (token) {
            Storage.setToken(token);
        }
        const result = Storage.getLogin();

        if (queueMonitor !== null) {
            Storage.setQueueMonitor(queueMonitor);
        }
        const queueMonitorStorage = Storage.getQueueMonitor();

        if (result) {
            dispatch(loginSuccess(result, queueMonitorStorage));

            // load the activation state after login
            dispatch(isMerchantActivated());
            dispatch(fetchMerchantData());

            // open server sent event
            dispatch(openServerSentOrders());
        }
    };
}

export function login(username, password, queueMonitor) {
    return dispatch => {
        dispatch(loginRequest());

        const url = new URL('/api/login/merchant', API_URI).toString();
        const payload = { username, password };

        if (Device.isDevice()) {
            payload.device = {
                ...Device.getDeviceInfo(),
                token: Push.getPushToken(),
                locale: Storage.getLocale(),
            };
        }

        return request({
            dispatch,
            url,
            method: 'POST',
            payload,
            errorMessageUnauthorized: 'Die Anmeldung ist fehlgeschlagen',
        })
            .then(json => {
                dispatch(setLogin(json.token, queueMonitor));
                dispatch(loginSuccess(json.token, queueMonitor));
                dispatch(checkOpenState(true));
            })
            .catch(error => {
                dispatch(loginError(error));
            });
    };
}

export function logout() {
    return dispatch => {
        dispatch(logoutRequest);

        const url = new URL('/api/logout/merchant', API_URI).toString();
        const payload = {};

        if (Device.isDevice()) {
            payload.device = {
                id: Device.getDeviceInfo().id,
            };
        }

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload,
        })
            .finally(() => Storage.unsetToken())
            .then(() => {
                // close server sent event
                dispatch(closeServerSentOrders());
                dispatch(logoutSuccess());
                toast.info('Sie wurden abgemeldet', { position: toast.POSITION.TOP_CENTER });
                dispatch(push('/login'));
            })
            .catch(error => {
                dispatch(logoutError(error));
                toast.error('Fehler beim Ausloggen', { position: toast.POSITION.TOP_CENTER });
            });
    };
}
