import BrowserNotification from 'services/BrowserNotification';
import { EVENTSOURCE_ON_MESSAGE } from 'containers/Monitor/constants';
import { toggleNewOrderPopup } from 'containers/NewOrderPopup/actions';
import { shouldNotifyNewOrder, shouldNotifyCanceledOrder } from './index';

const notification = ({ dispatch, getState }) => next => action => {
    if (action.type === EVENTSOURCE_ON_MESSAGE) {
        const state = getState();
        const { data: order } = action;

        triggerNotification(dispatch, action, state, order);
    }

    return next(action);
};

function triggerNotification(dispatch, action, state, order) {
    if (shouldNotifyNewOrder(state, order)) {
        BrowserNotification.notify(
            `Neue Bestellung (${order.pickUpNumber})`,
            `${order.products.map(p => `${p.quantity}x ${p.name}`).join(', ')}`
        );

        dispatch(toggleNewOrderPopup(state.app.merchant.newOrderPopup));
    }

    if (shouldNotifyCanceledOrder(state, order)) {
        BrowserNotification.notify(
            `Bestellung storniert (${order.pickUpNumber})`,
            'Die Bestellung wurde vom Kunden storniert'
        );
    }
}

export default notification;
