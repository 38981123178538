import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import PrivateRoute from 'components/PrivateRoute';

import Orders from 'containers/Orders';
import OrderDetails from 'containers/OrderDetails';

import './styles.css';

class History extends React.Component { // eslint-disable-line react/prefer-stateless-function

    render() {
        const { loggedIn, queueMonitor } = this.props;

        return (
            <div className="history-page" ref={e => { this.myRef = e; }}>
                <Container>
                    <Switch>
                        <PrivateRoute
                            exact
                            path="/history/orders"
                            component={Orders}
                            loggedIn={loggedIn}
                            showQueueMonitor={queueMonitor}
                            scrollElement={this.myRef}
                        />
                        <PrivateRoute
                            path="/history/orders/:id"
                            component={OrderDetails}
                            loggedIn={loggedIn}
                            showQueueMonitor={queueMonitor}
                            scrollElement={this.myRef}
                        />

                        <Redirect exact from="/history" to="/history/orders" />
                    </Switch>
                </Container>
            </div>
        );
    }

}

History.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    queueMonitor: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        queueMonitor: state.login.queueMonitor,
    };
}

export default connect(mapStateToProps)(History);
