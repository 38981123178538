import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import LogoutDialog from 'components/LogoutDialog';

import { logout as logoutAction } from 'containers/Login/actions';
import { fetchQueue as fetchQueueAction } from 'containers/Monitor/action';

import logo from 'containers/QueueMonitor/logo.png';
import './styles.css';

function QueueMonitor({ orders, logout, fetchQueue }) {
    const [queuePosition, setQueuePosition] = React.useState(0);
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = React.useState(false);
    const [clicks, setClicks] = React.useState(0);

    React.useEffect(() => {
        fetchQueue();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        const statesToShow = ['OPEN', 'IN_PREPARATION'];
        const queueLength = orders
            .filter(o => statesToShow.includes(o.state))
            .length;

        setQueuePosition(queueLength);
    }, [orders]);
    React.useEffect(() => {
        const dialogTimer = setTimeout(() => {
            setClicks(0);
        }, 500);

        if (clicks >= 5) {
            setIsLogoutDialogOpen(true);
        }

        return () => clearTimeout(dialogTimer);
    }, [clicks]);

    const handleClick = () => {
        setClicks(prevState => prevState + 1);
    };

    const handleLogoutDialogConfirm = () => {
        logout();
        setIsLogoutDialogOpen(false);
    };

    const handleLogoutDialogCancel = () => {
        setIsLogoutDialogOpen(false);
    };

    return (
        <div
            className="queue-monitor"
            onClick={handleClick}
            onKeyPress={handleClick}
            role="button"
            tabIndex={0}
        >
            <Row className="m-0">
                <div className="queue-monitor-vertical-center">
                    <Col>
                        <img
                            src={logo}
                            alt="Hol's App Logo"
                            className="queue-monitor-logo queue-monitor-center-block"
                            draggable="false"
                        />
                    </Col>
                    <Col>
                        <Row>
                            <span className="queue-monitor-floating-text text-center">
                                Wir haben aktuell
                            </span>
                        </Row>
                        <Row>
                            <div className="queue-monitor-counter">
                                {queuePosition}
                            </div>
                        </Row>
                        <Row>
                            <span className="queue-monitor-floating-text text-center">
                                Bestellungen
                                <br />
                                in Bearbeitung
                            </span>
                        </Row>
                    </Col>
                </div>
            </Row>
            <LogoutDialog
                isOpen={isLogoutDialogOpen}
                onConfirm={handleLogoutDialogConfirm}
                onCancel={handleLogoutDialogCancel}
            />
        </div>
    );
}

QueueMonitor.propTypes = {
    orders: PropTypes.array.isRequired,
    logout: PropTypes.func.isRequired,
    fetchQueue: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        orders: state.queue.data,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
        fetchQueue: () => dispatch(fetchQueueAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueueMonitor);
