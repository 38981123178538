import React from 'react';
import PropTypes from 'prop-types';

import ConfirmDialog from 'components/ConfirmDialog';

function LogoutDialog({ isOpen, onConfirm, onCancel }) {
    return (
        <ConfirmDialog
            open={isOpen}
            title="Abmelden"
            message="Wollen Sie sich wirklich abmelden?"
            confirmButtonText="Abmelden"
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    );
}

LogoutDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LogoutDialog;
