import {
    PLAY_AUDIO,
    STOP_AUDIO,
    SELECT_SOUND,
    SET_SOUNDS,
} from './constants';

export const play = loop => ({ type: PLAY_AUDIO, loop });
export const stop = () => ({ type: STOP_AUDIO });

export const selectSound = key => ({ type: SELECT_SOUND, key });
export const setSounds = sounds => ({ type: SET_SOUNDS, sounds });
