import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.info(info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="w-100 h-75 d-flex flex-column align-items-center justify-content-center">
                    <div className="flex-grow-0">
                        <h1>Etwas ist schief gelaufen.</h1>
                    </div>
                    <div className="flex-grow-0">
                        <h1><i className="fa fa-frown-o fa-3x" /></h1>
                    </div>
                    <Button
                        color="secondary"
                        className="mt-5"
                        size="lg"
                        onClick={() => {
                            this.props.history.push('/');
                            window.location.reload(true);
                        }}
                    >
                        Neu Laden
                    </Button>
                </div>
            );
        } else {
            return this.props.children;
        }
    }

}

ErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
};

export default withRouter(ErrorBoundary);
