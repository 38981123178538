import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './styles.css';

const states = [
    'OPEN',
    'IN_PREPARATION',
    'PREPARED',
    'PICKED_UP',
    'CANCELED',
];

class OrderStateButton extends React.Component {

    constructor(props) {
        super(props);

        this.handleNextStateClick = this.handleNextStateClick.bind(this);
    }

    handleNextStateClick() {
        const { currentState, onSetState } = this.props;

        if (currentState !== 'PICKED_UP' && currentState !== 'CANCELED') {
            const idx = states.findIndex(element => element === currentState);
            onSetState(states[idx + 1]);
        }
    }

    render() {
        const { content, currentState, className } = this.props;
        let color = 'primary';

        let buttonIconClass;
        if (currentState === 'OPEN') {
            buttonIconClass = 'fa fa-check';
        } else {
            buttonIconClass = 'fa fa-arrow-right';
        }

        if (currentState === 'OPEN') {
            color = 'warning';
        } else if (currentState === 'IN_PREPARATION') {
            color = 'success';
        } else if (currentState === 'PREPARED') {
            color = 'secondary';
        }

        return (
            <Button
                color={color}
                className={`${className} order-state-button`}
                onClick={this.handleNextStateClick}
            >
                {content && content()} <i className={buttonIconClass} />
            </Button>
        );
    }

}

OrderStateButton.propTypes = {
    currentState: PropTypes.string.isRequired,
    onSetState: PropTypes.func.isRequired,
    content: PropTypes.func,
    className: PropTypes.string,
};

export default OrderStateButton;
