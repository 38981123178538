import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';

import OrderItem from 'containers/OrderItem';
import { USER_TYPE_MAP } from '../../utils';

class LaneBody extends React.Component {

    constructor(props) {
        super(props);

        this.onDrop = this.onDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.className !== nextProps.className
            || this.props.isOpen !== nextProps.isOpen
            || this.props.orderItems.length !== nextProps.orderItems.length
            || JSON.stringify(this.props.orderItems) !== JSON.stringify(nextProps.orderItems);
    }

    onDrop(event) {
        const payload = JSON.parse(event.dataTransfer.getData('text'));
        if (payload.state === 'CANCELED' && payload.canceledBy === USER_TYPE_MAP.CUSTOMER) {
            toast.error(
                'Der Status der Bestellung konnte nicht geändert werden, die Bestellung wurde vom Kunden storniert.',
                { position: toast.POSITION.TOP_CENTER }
            );
        } else {
            this.props.moveOrderToAnotherLane(payload.id, payload.state);
        }

        this.props.onDrop();

        event.preventDefault();
    }

    onDragEnter(event) {
        event.preventDefault();
        if (this.props.onDragEnter) {
            this.props.onDragEnter();
        }
    }

    /* eslint-disable class-methods-use-this */
    onDragOver(event) {
        event.preventDefault();
    }

    render() {
        const {
            className,
            isOpen,
            orderItems,
            onDrop,
        } = this.props;

        const renderedItems = orderItems.map(order => (
            <OrderItem
                key={order._id}
                order={order}
                onDrop={onDrop}
            />
        ));

        return (
            <div
                className={`LaneBody ${className} ${isOpen ? 'panel-opened' : 'panel-closed'}`}
                style={{ height: isOpen ? '100%' : '0%' }}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
            >
                <CSSTransition
                    classNames="fade"
                    timeout={{
                        enter: 100,
                        exit: 100,
                    }}
                >
                    {/* CSSTransition only allows a single child */}
                    <div>
                        {renderedItems}
                    </div>
                </CSSTransition>
            </div>
        );
    }

}

LaneBody.propTypes = {
    orderItems: PropTypes.array.isRequired,
    className: PropTypes.string,
    moveOrderToAnotherLane: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onDragEnter: PropTypes.func,
    onDrop: PropTypes.func.isRequired,
};

// Specifies the default values for props:
LaneBody.defaultProps = {
    isOpen: true,
};

export default LaneBody;
