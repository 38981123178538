import { schema } from 'normalizr';

/*
 * DO NOT EXTRACT PRODUCTS INTO SEPARATE SCHEMA!
 * Products cannot be identified by id, because the quantity depends on the order.
*/
export const customer = new schema.Entity('customers', undefined, { idAttribute: '_id' });
export const merchant = new schema.Entity('merchants', undefined, { idAttribute: '_id' });
export const order = new schema.Entity('orders', {
    customer,
    merchant,
}, { idAttribute: '_id' });
