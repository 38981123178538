import moment from 'moment';

import {
    SET_FILTERS,
    FETCH_ORDERS_REQUEST,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_ERROR,
    DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_ERROR,
} from './constants';

const initialState = {
    loading: false,
    downloadingFile: false,
    downloadError: null,
    filters: {
        month: moment().month(),
        year: moment().year(),
        showAll: false,
    },
    result: [],
    entities: {
        orders: {},
        customers: {},
        merchants: {},
    },
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filters,
                },
            };
        case FETCH_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload,
            };
        case FETCH_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
            };
        case DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
                downLoadingFile: true,
                downloadError: null,
            };
        case DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                downLoadingFile: false,
                downloadError: null,
            };
        case DOWNLOAD_FILE_ERROR:
            return {
                ...state,
                downLoadingFile: false,
                downloadError: action.error,
            };
        default:
            return state;
    }
};

export default ordersReducer;
