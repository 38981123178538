import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';

import OrderStateButton from 'components/OrderStateButton';
import OrderDetailsTable from 'components/OrderDetailsTable';

import {
    STATE_ENUM,
    STATE_MAP,
} from 'utils';

import './styles.css';

function OrderDialog({
    open,
    onClose,
    onCancel,
    order,
    onSetState,
    isCustomerBlocked,
    onBlock,
}) {
    const getNextState = () => {
        let next = '';
        Object.keys(STATE_ENUM).forEach((key, idx) => {
            if (STATE_ENUM[key] === order.state) {
                next = STATE_ENUM[idx + 1];
            }
        });
        return next;
    };

    return (
        <Modal
            isOpen={open}
            toggle={onClose}
        >
            <ModalHeader toggle={onClose}>
                Bestellung: {STATE_MAP[order.state]}
            </ModalHeader>
            <ModalBody>
                <OrderDetailsTable
                    order={order}
                    customer={order.customer}
                    isCustomerBlocked={isCustomerBlocked}
                    onBlock={onBlock}
                    hideFields={['state']}
                />
                {order.state !== 'PICKED_UP' && order.state !== 'CANCELED' &&
                    <div className="clearfix">
                        <div className="float-right">
                            <Button
                                color="danger"
                                onClick={onCancel}
                            >
                                Stornieren
                            </Button>
                            {' '}
                            <OrderStateButton
                                currentState={order.state}
                                onSetState={onSetState}
                                content={() => (
                                    <span>
                                        Weiter ({STATE_MAP[getNextState()]})
                                    </span>
                                )}
                            />
                        </div>
                    </div>
                }
            </ModalBody>
        </Modal>
    );
}

OrderDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSetState: PropTypes.func.isRequired,
    onBlock: PropTypes.func.isRequired,
    isCustomerBlocked: PropTypes.bool,
};

export default OrderDialog;
