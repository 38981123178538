import { USER_TYPE_MAP } from '../utils';

/**
 * Checks if the merchant should be notified about a new order.
 * @param state
 * @param order
 * @return {boolean} True for notification, otherwise false.
 */
export function shouldNotifyNewOrder(state, order) {
    // don't show any notification when in queue monitor mode
    // or notification should be suppressed
    if (state.login.queueMonitor) {
        return false;
    }

    // don't show any notification when order was not created by customer
    if (order.createdBy !== USER_TYPE_MAP.CUSTOMER) {
        return false;
    }

    // dont' show any notification for a pending Stripe order
    if (order.state === 'PENDING' && order.paymentType === 'STRIPE') {
        return false;
    }

    // don't show any notification for an open Stripe order
    // that last event is not checkout.session.completed
    // Stripe orders are received multiple times from the webhook because of the changed lastEvent
    // Stripe orders should only be displayed once
    if (order.state === 'OPEN'
        && order.paymentType === 'STRIPE'
        && order.stripe
        && order.stripe.lastEvent !== 'checkout.session.completed') {
        return false;
    }

    // order is OPEN and was not IN_PREPERATION, PREPARED, ...
    return order.state === 'OPEN'
        && !order.inPreparationAt
        && !order.preparedAt
        && !order.pickedUpAt
        && !order.canceledAt
        && !order.abortedAt;
}

/**
 * Checks if the merchant should be notified about a canceled order.
 * @param state
 * @param order
 * @return {boolean} True for notification, otherwise false.
 */
export function shouldNotifyCanceledOrder(state, order) {
    // don't show any notification when in queue monitor mode
    // or notification should be suppressed
    if (state.login.queueMonitor) {
        return false;
    }

    // don't show any notification if order was not created by customer
    if (order.createdBy !== USER_TYPE_MAP.CUSTOMER) {
        return false;
    }

    // dont' show any notification for a pending Stripe order
    if (order.state === 'PENDING' && order.paymentType === 'STRIPE') {
        return false;
    }

    // don't show any notification for canceled Stripe order
    // that last event is not charge.refunded
    // Stripe orders are received multiple times from the webhook because of the changed lastEvent
    // Stripe orders should only be displayed once
    if (order.state === 'CANCELED'
        && order.paymentType === 'STRIPE'
        && order.stripe
        && order.stripe.lastEvent !== 'charge.refunded') {
        return false;
    }

    return order.state === 'CANCELED'
        && order.canceledBy === USER_TYPE_MAP.CUSTOMER;
}
