import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

function CheckOpenStateDialog({
    open,
    isCurrentlyOpen,
    nextOpeningHours,
    onClose,
    onConfirm,
}) {
    let timeText = '';
    let orderText = '';

    if (isCurrentlyOpen) {
        timeText = 'derzeit geöffnet sein';
        orderText = 'ab sofort';
    } else if (nextOpeningHours) {
        timeText = `um ${nextOpeningHours.from} Uhr wieder geöffnet werden`;
        orderText = 'ab diesem Zeitpunkt automatisch';
    }

    /* eslint-disable max-len */
    return (
        <div>
            <Modal
                isOpen={open}
                toggle={onClose}
            >
                <ModalHeader toggle={onClose}>
                    Warnung
                </ModalHeader>
                <ModalBody>
                    Das Restaurant ist aktuell geschlossen, sollte jedoch {timeText}.
                    Aktivieren Sie den Automatikmodus sodass Bestellungen {orderText} möglich sind.
                </ModalBody>
                <ModalFooter>
                    <div className="float-right">
                        <Button
                            outline
                            onClick={onClose}
                        >
                            Ignorieren
                        </Button>
                        {' '}
                        <Button
                            color="success"
                            onClick={onConfirm}
                        >
                            Automatikmodus aktivieren
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
    /* eslint-enable max-len */
}

CheckOpenStateDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isCurrentlyOpen: PropTypes.bool,
    nextOpeningHours: PropTypes.object,
};

export default CheckOpenStateDialog;
