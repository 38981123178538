import React from 'react';
import { connect } from 'react-redux';
import {
    Link,
    NavLink as RouterNavLink,
    withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Collapse,
    Button,
} from 'reactstrap';
import LogoutDialog from 'components/LogoutDialog';
import ReloadButton from 'components/ReloadButton';
import Online from 'containers/Online';

import { fetchMerchantData as fetchMerchantDataAction } from 'containers/App/actions';
import { logout } from 'containers/Login/actions';
import { fetchQueueForToday as fetchQueueForTodayAction } from 'containers/Monitor/action';

import './styles.css';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collapseOpen: false,
            logoutDialogOpen: false,
        };
    }

    onLogoutConfirm() {
        const { dispatch } = this.props;
        this.setState({ logoutDialogOpen: false });
        dispatch(logout());
    }

    onLogoutCancel() {
        this.setState({ logoutDialogOpen: false });
    }

    openLogoutDialog() {
        this.setState({ logoutDialogOpen: true });
    }

    closeNavBar() {
        this.setState({ collapseOpen: false });
    }

    toggle() {
        this.setState(state => ({
            collapseOpen: !state.collapseOpen,
        }));
    }

    checkConnected() {
        const {
            queueError,
            merchantError,
            isStreamOpen,
        } = this.props;

        return !merchantError
            && !queueError
            && isStreamOpen;
    }

    handleReloadButtonClick() {
        const { fetchMerchantData, fetchQueueForToday } = this.props;

        fetchMerchantData();
        fetchQueueForToday();
    }

    handleNewOrderClick() {
        const { history, location } = this.props;

        // Avoid pushing the same route on the stack if we are already on the page
        if (location.pathname !== '/cart') {
            history.push('/cart');
        } else if (location.pathname === '/cart') {
            history.push('/monitor');
        }
    }

    render() {
        const { location, merchantName } = this.props;
        const {
            collapseOpen,
            logoutDialogOpen,
        } = this.state;

        return (
            <Navbar className="Header-nav position-relative" color="blue" dark expand={false}>
                <i
                    className="Header-menu-button fa fa-bars fa-lg mr-3"
                    role="button"
                    aria-label="menu"
                    onClick={() => this.toggle()}
                    onKeyPress={() => this.toggle()}
                    tabIndex="0"
                />
                <NavbarBrand tag={Link} to="/" className="mr-auto">
                    {merchantName || ''}
                </NavbarBrand>
                <Button
                    size="sm"
                    className="Header-order-button"
                    onClick={() => this.handleNewOrderClick()}
                >
                    {
                        location.pathname !== '/cart'
                            ? 'Neue Bestellung'
                            : 'Monitor'
                    }
                </Button>
                <ReloadButton
                    isConnected={this.checkConnected()}
                    onClick={() => this.handleReloadButtonClick()}
                />
                <Online style={{ marginRight: '1rem' }} />
                <Collapse isOpen={collapseOpen} navbar>
                    <Nav className="ml-auto mt-3" navbar>
                        <NavItem>
                            <NavLink
                                tag={RouterNavLink}
                                to="/monitor"
                                onClick={() => this.closeNavBar()}
                            >
                                <i className="fa fa-desktop fa-lg nav-icon" />
                                {collapseOpen && 'Monitor'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={RouterNavLink}
                                to="/menu"
                                onClick={() => this.closeNavBar()}
                            >
                                <i className="fa fa-file-text fa-lg nav-icon" />
                                {collapseOpen && 'Speisekarte'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={RouterNavLink}
                                to="/product-options"
                                onClick={() => this.closeNavBar()}
                            >
                                <i className="fa fa-sliders fa-lg nav-icon" />
                                {collapseOpen && 'Produktoptionen'}
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={collapseOpen ? '' : 'border-right'}
                            onClick={() => this.closeNavBar()}
                        >
                            <NavLink
                                tag={RouterNavLink}
                                to="/customer-blacklist"
                            >
                                <i className="fa fa-ban fa-lg nav-icon" />
                                {collapseOpen && 'Blockierte Kunden'}
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={collapseOpen ? '' : 'border-right'}
                            onClick={() => this.closeNavBar()}
                        >
                            <NavLink
                                tag={RouterNavLink}
                                to="/history"
                            >
                                <i className="fa fa-history fa-lg nav-icon" />
                                {collapseOpen && 'Bestellungen'}
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={collapseOpen ? '' : 'border-right'}
                            onClick={() => this.closeNavBar()}
                        >
                            <NavLink
                                tag={RouterNavLink}
                                to="/settings"
                            >
                                <i className="fa fa-cog fa-lg nav-icon" />
                                {collapseOpen && 'Einstellungen'}
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={collapseOpen ? '' : 'border-right'}
                            onClick={() => this.closeNavBar()}
                        >
                            <NavLink
                                tag={RouterNavLink}
                                to="/about"
                            >
                                <i className="fa fa-info-circle fa-lg nav-icon" />
                                {collapseOpen && 'Über'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <Button
                                color="link"
                                className="nav-link"
                                onClick={() => this.openLogoutDialog()}
                            >
                                <i className="fa fa-sign-out fa-lg nav-icon" />
                                {collapseOpen && 'Abmelden'}
                            </Button>
                        </NavItem>
                    </Nav>
                </Collapse>
                <LogoutDialog
                    isOpen={logoutDialogOpen}
                    onConfirm={() => this.onLogoutConfirm()}
                    onCancel={() => this.onLogoutCancel()}
                />
            </Navbar>
        );
    }

}

Header.propTypes = {
    dispatch: PropTypes.func.isRequired,
    merchantName: PropTypes.string,
    isStreamOpen: PropTypes.bool,
    fetchMerchantData: PropTypes.func.isRequired,
    fetchQueueForToday: PropTypes.func.isRequired,
    queueError: PropTypes.object,
    merchantError: PropTypes.bool,
    history: PropTypes.object, // react-router hoc
    location: PropTypes.object, // react-router hoc
};

function mapStateToProps(state) {
    return {
        available: state.online.available,
        openingHours: state.app.merchant && state.app.merchant.openingHours,
        merchantName: state.app.merchant && state.app.merchant.name,

        isStreamOpen: state.queue.isStreamOpen,
        queuePending: state.queue.pending,
        queueError: state.queue.error,

        merchantPending: state.app.pending,
        merchantError: state.app.error,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fetchMerchantData: () => dispatch(fetchMerchantDataAction()),
        fetchQueueForToday: () => dispatch(fetchQueueForTodayAction()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
