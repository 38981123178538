import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import appReducer from 'containers/App/reducer';
import loginReducer from 'containers/Login/reducer';
import queueReducer from 'containers/Monitor/reducer';
import menuReducer from 'containers/MenuCard/reducer';
import productOptionGroupsReducer from 'containers/ProductOptions/reducer';
import onlineReducer from 'containers/Online/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import audioPlayerReducer from 'containers/AudioPlayer/reducer';
import customerBlackListReducer from 'containers/CustomerBlackList/reducer';
import ordersReducer from 'containers/Orders/reducer';
import newOrderPopupReducer from 'containers/NewOrderPopup/reducer';
import cartReducer from 'containers/Cart/reducer';

import createReducer from 'reducers';

import soundMiddleware from 'middlewares/sound';
import notificationMiddleware from 'middlewares/notification';
import thunkMiddleware from 'middlewares/thunk';
// import loggerMiddleware from 'middlewares/logger';

const reducers = {
    // Add reducers here
    app: appReducer,
    login: loginReducer,
    queue: queueReducer,
    menu: menuReducer,
    productOptionGroups: productOptionGroupsReducer,
    online: onlineReducer,
    languageProvider: languageProviderReducer,
    audioPlayer: audioPlayerReducer,
    customerBlackList: customerBlackListReducer,
    orders: ordersReducer,
    newOrderPopup: newOrderPopupReducer,
    cart: cartReducer,
};

function configureStore(initialState = {}, history) {
    const middlewares = [
        routerMiddleware(history),
        thunkMiddleware,
        soundMiddleware,
        notificationMiddleware,
        // TODO: TBD loggerMiddleware,
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
    ];

    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // Specify extension’s options like
                // name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;
    /* eslint-enable */

    const store = createStore(
        createReducer(reducers, history),
        initialState,
        composeEnhancers(...enhancers),
    );

    // Hot reload reducers
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(store.asyncReducers));
        });
    }

    return store;
}

export default configureStore;
