class File {

    /**
     * Checks if the file exists in the private data storage of the application.
     * @param {String} fileName The filename to check existance of.
     * @return {Promise<Boolean>} True if exists, otherwise false.
     */
    static fileExistsInDataDirectory(fileName) {
        const { cordova } = window;

        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                cordova.file.dataDirectory,
                dir => {
                    dir.getFile(
                        fileName,
                        { create: false },
                        () => resolve(true),
                        () => resolve(false),
                    );
                },
                error => {
                    console.log(`File: Error opening app data directory: ${error}`);
                    reject(error);
                }
            );
        });

    }

    /**
     * Creates a zero-length file in the private data storage of the application.
     * @param {String} fileName The filename to create.
     * @return {Promise<void>} Calls the success callback of the promise when created.
     */
    static touchFileInDataDirectory(fileName) {
        const { cordova } = window;

        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                cordova.file.dataDirectory,
                dir => {
                    dir.getFile(
                        fileName,
                        { create: true },
                        fileEntry => {
                            console.log(`File: Created file '${fileEntry.name}' in app data directory`);
                            resolve();
                        },
                        error => {
                            console.log(`File: Error creating file '${fileName}' in app data directory: ${error}`);
                            reject(error);
                        }
                    );
                },
                error => {
                    console.log(`File: Error opening app data directory: ${error}`);
                    reject(error);
                }
            );
        });
    }

    /**
     *
     * @param {string} directoryPath The path of the directory where the file will be saved.
     * @param {string} fileName The file name.
     * @param {Blob} blob The file content.
     * @return {Promise}
     */
    static saveFileInDirectory(directoryPath, fileName, blob) {
        return new Promise((resolve, reject) => {
            window.resolveLocalFileSystemURL(
                directoryPath,
                dir => {
                    dir.getFile(
                        fileName,
                        { create: true },
                        fileEntry => {
                            console.log(`File: Created file '${fileEntry.name}'`);
                            console.log(fileEntry.toURL());
                            File.writeFile(fileEntry, blob)
                                .then(() => resolve(fileEntry.toURL()))
                                .catch(reject);
                        },
                        error => {
                            console.log(`File: Error creating file '${fileName}': ${error}`);
                            console.error(error);
                            reject(error);
                        }
                    );
                },
                error => {
                    console.log(`File: Error opening directory: ${error}`);
                    reject(error);
                }
            );
        });
    }

    /**
     * Writes the file content.
     * @param fileEntry The file entry to write to.
     * @param {Blob} blob The file content.
     * @return {Promise}
     */
    static writeFile(fileEntry, blob) {
        return new Promise((resolve, reject) => {
            fileEntry.createWriter(fileWriter => {

                // eslint-disable-next-line no-param-reassign
                fileWriter.onwriteend = () => {
                    console.log(`File: Successfully written file '${fileEntry.name}'`);
                    resolve();
                };

                // eslint-disable-next-line no-param-reassign
                fileWriter.onerror = error => {
                    console.log(`File: Failed to write file: ${error}`);
                    reject();
                };

                fileWriter.write(blob);
            });
        });
    }

}

export default File;
