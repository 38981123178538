import _ from 'lodash';
import {
    EVENTSOURCE_ON_ERROR,
    EVENTSOURCE_ON_MESSAGE,
    EVENTSOURCE_ON_OPEN,
    EVENTSOURCE_ON_PING,
    GET_QUEUE_ERROR,
    GET_QUEUE_REQUEST,
    GET_QUEUE_SUCCESS,
    SET_ORDER_STATE_ERROR,
    SET_ORDER_STATE_REQUEST,
    SET_ORDER_STATE_SUCCESS,
    EVENTSOURCE_ON_CLOSE,
} from './constants';

import { isSameDay, sortOrders } from '../../utils';

const initialState = {
    pending: false,
    isStreamOpen: false,
    data: [],
    error: null,
};

const queue = (state = initialState, action) => {
    switch (action.type) {
        case GET_QUEUE_REQUEST:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case GET_QUEUE_SUCCESS: {
            if (action.data.length === 0) {
                return {
                    ...state,
                    pending: false,
                    data: [],
                    error: null,
                };
            } else if (isSameDay(action.data[0].createdAt, new Date().toISOString())) {
                return {
                    ...state,
                    pending: false,
                    data: sortOrders(action.data),
                    error: null,
                };
            } else {
                return {
                    ...state,
                    pending: false,
                    error: null,
                };
            }
        }
        case GET_QUEUE_ERROR:
            return {
                ...state,
                pending: false,
                data: [],
                error: action.error,
            };
        case SET_ORDER_STATE_REQUEST:
            /* Set state optimistically */
            return {
                ...state,
                pending: true,
                data: _.map(state.data, order => (
                    order._id === action.orderId ? {
                        ...order,
                        state: action.state,
                        prevState: order.state,
                    } : order
                )),
                error: null,
            };
        case SET_ORDER_STATE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
            };
        case SET_ORDER_STATE_ERROR:
            /* Because we set state optimistically, we must return to previous state on error */
            return {
                ...state,
                pending: false,
                data: _.map(state.data, order => (
                    order.prevState ? {
                        ...order,
                        state: order.prevState,
                    } : order
                )),
                error: action.error,
            };
        case EVENTSOURCE_ON_OPEN:
            return {
                ...state,
                isStreamOpen: true,
            };
        case EVENTSOURCE_ON_CLOSE:
            return {
                ...state,
                isStreamOpen: false,
                data: [],
            };
        case EVENTSOURCE_ON_PING:
            return {
                ...state,
                isStreamOpen: true,
            };
        case EVENTSOURCE_ON_MESSAGE: {
            if (isSameDay(action.data.createdAt, new Date().toISOString())) {
                return {
                    ...state,
                    isStreamOpen: true,
                    data: sortOrders(_
                        .remove(state.data, order => order._id !== action.data._id)
                        .concat([action.data])),
                    error: null,
                };
            } else {
                return {
                    ...state,
                    isStreamOpen: true,
                    error: null,
                };
            }
        }
        case EVENTSOURCE_ON_ERROR:
            return {
                ...state,
                isStreamOpen: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default queue;
