import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './styles.css';

function MenuCardColumnButton({ onClick, value, ...other }) {
    return (
        <div className="w-100 flex-grow-0 MenuCardColumnButton">
            <Button
                className="w-100 no-border-radius"
                onClick={onClick}
                {...other}
            >
                <i className="fa fa-plus" /> {value}
            </Button>
        </div>
    );
}

MenuCardColumnButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default MenuCardColumnButton;
