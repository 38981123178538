import React from 'react';
import PropTypes from 'prop-types';

function formatAmount(value, locale) {
    try {
        // pattern "0.00"
        return (Math.trunc(value) / 100).toLocaleString(locale, { minimumFractionDigits: 2 });
    } catch (e) {
        return value;
    }
}

function FormattedAmount({ value, locale }) {
    return (
        <span className="amount">{formatAmount(value, locale)} &euro;</span>
    );
}

FormattedAmount.propTypes = {
    value: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
};

export default FormattedAmount;
