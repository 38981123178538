import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Col,
    Row,
    Button,
} from 'reactstrap';

import {
    getLocaleNumberValidator,
    normalizeLocalizedNumber,
    formatLocalizedNumber,
} from 'utils';

import FormInput from 'components/FormInput';
import DeleteDialog from 'components/DeleteDialog';
import StateMenuHeader from 'components/StateMenuHeader';
import Toggle from 'components/Toggle';

import './styles.css';

class CreateEditProductOptionForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = CreateEditProductOptionForm.getEmptyState(props);

        this.handleChangeProductOptionName = this.handleChangeProductOptionName.bind(this);
        this.handleChangeProductOptionNameInternal = this.handleChangeProductOptionNameInternal.bind(this);
        this.handleChangeProductOptionPriceAdditional = this.handleChangeProductOptionPriceAdditional.bind(this);
        this.handleChangeProductOptionTaxRate = this.handleChangeProductOptionTaxRate.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
        this.initFormValidators = this.initFormValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.saveOrUpdateProductOption = this.saveOrUpdateProductOption.bind(this);

        this.initFormValidators();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.productOption
            && nextProps.productOption._id !== prevState.productOptionId
            && !prevState.deleteDialogOpen) {
            return CreateEditProductOptionForm.getStateFromProps(nextProps);
        }

        if ((!nextProps.new && !nextProps.productOption)
            || nextProps.forceClearFormData
            || nextProps.new !== prevState.new) {
            return CreateEditProductOptionForm.getEmptyState(nextProps);
        }

        if (!prevState.pristine) {
            return null;
        }

        return null;
    }

    static getEmptyState(props) {
        const { productOptionGroup } = props;

        return {
            new: true,
            pristine: true,
            touched: false,
            forceShowValidationErrors: false,
            productOptionId: null,
            productOptionName: '',
            productOptionNameInternal: '',
            productOptionPriceAdditional: 0,
            formattedProductOptionPriceAdditional: '0',
            productOptionTaxRate: 0,
            formattedProductOptionTaxRate: '0',
            productOptionEnabled: true,
            productOptionGroup: productOptionGroup ? productOptionGroup._id : '',
            deleteDialogOpen: false,
        };
    }

    static getStateFromProps(props) {
        const { productOption, locale } = props;
        return {
            new: false,
            pristine: true,
            touched: false,
            productOptionId: productOption._id,
            productOptionName: productOption.name,
            productOptionNameInternal: productOption.nameInternal || '',
            productOptionPriceAdditional:
                Math.trunc(productOption.priceAdditional) / 100, // We receive an integer representation
            formattedProductOptionPriceAdditional:
                formatLocalizedNumber(Math.trunc(productOption.priceAdditional) / 100, locale),
            productOptionTaxRate: Math.trunc(productOption.taxRate) / 100,
            formattedProductOptionTaxRate:
                formatLocalizedNumber(Math.trunc(productOption.taxRate) / 100, locale, 0),
            productOptionEnabled: productOption.enabled,
            productOptionGroup: productOption.optionGroup,
            deleteDialogOpen: false,
        };
    }

    static getNewProductOptionFromPropsAndState(props, state) {
        const { productOption } = props;
        const {
            productOptionName,
            productOptionNameInternal,
            productOptionPriceAdditional,
            productOptionTaxRate,
            productOptionEnabled,
            productOptionGroup,
        } = state;
        const updatedProductOption = {
            ...productOption,
            name: productOptionName,
            nameInternal: productOptionNameInternal,
            priceAdditional: Math.round(productOptionPriceAdditional * 100), // The API needs an integer representation
            taxRate: Math.round(productOptionTaxRate * 100),
            enabled: productOptionEnabled,
            optionGroup: productOptionGroup,
        };

        return updatedProductOption;
    }

    initFormValidators() {
        this.formValidators = {
            isNameValid: () => !!this.state.productOptionName && this.state.productOptionName.length <= 75,
            isNameInternalValid: () => !this.state.productOptionNameInternal ||
                (this.state.productOptionNameInternal && this.state.productOptionNameInternal.length <= 75),
            isPriceAdditionalValid: () => !Number.isNaN(this.state.productOptionPriceAdditional)
                && this.state.productOptionPriceAdditional >= 0,
            isTaxRateValid: () => !Number.isNaN(this.state.productOptionTaxRate)
                && this.state.productOptionTaxRate >= 0
                && this.state.productOptionTaxRate <= 100,
        };
    }

    isFormValid() {

        let isValid = true;
        Object.values(this.formValidators).forEach(validator => {
            if (!validator()) {
                isValid = false;
            }
        });

        return isValid;
    }

    handleOnBlur() {
        this.setState({
            touched: true,
        });
    }

    handleChangeProductOptionName(event) {
        this.setState({
            productOptionName: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductOptionNameInternal(event) {
        this.setState({
            productOptionNameInternal: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductOptionPriceAdditional(event) {
        const { locale, onChange } = this.props;

        const validator = getLocaleNumberValidator(locale);

        if (validator.test(event.target.value)) {
            this.setState({
                formattedProductOptionPriceAdditional: event.target.value,
                productOptionPriceAdditional: normalizeLocalizedNumber(event.target.value, locale),
                pristine: false,
            });
            onChange(event);
        }
    }

    handleChangeProductOptionTaxRate(event) {
        const { locale, onChange } = this.props;

        const validator = getLocaleNumberValidator(locale);

        if (validator.test(event.target.value)) {
            this.setState({
                formattedProductOptionTaxRate: event.target.value,
                productOptionTaxRate: normalizeLocalizedNumber(event.target.value, locale),
                pristine: false,
            });
            onChange(event);
        }
    }

    handleChangeEnabled() {
        this.setState(state => ({
            productOptionEnabled: !state.productOptionEnabled,
            pristine: false,
        }));
        this.props.onChange();
    }

    handleCreate(event, updatedProductOption) {
        this.props.onCreate(updatedProductOption).then(() => {
            this.setState({
                pristine: true,
            });
        });
        event.preventDefault();
    }

    handleUpdate(event) {

        if (this.isFormValid()) {
            this.setState({
                forceShowValidationErrors: false,
            });
        } else {
            this.setState({
                forceShowValidationErrors: true,
            });
            return;
        }


        this.saveOrUpdateProductOption(event);

        event.preventDefault();
    }

    saveOrUpdateProductOption(event) {
        const updatedProductOption =
            CreateEditProductOptionForm.getNewProductOptionFromPropsAndState(this.props, this.state);

        if (this.props.new) {
            this.handleCreate(event, updatedProductOption);
        } else {
            this.props.onUpdate(updatedProductOption).then(() => {
                this.setState({
                    pristine: true,
                });
            });
        }

    }

    handleDelete(event) {
        if (this.props.new) {
            this.setState(CreateEditProductOptionForm.getEmptyState(this.props));
            this.props.onDelete(null);
        } else {
            const deleteProductOption =
                CreateEditProductOptionForm.getNewProductOptionFromPropsAndState(this.props, this.state);
            this.props.onDelete(deleteProductOption);
        }

        event.preventDefault();
    }

    toggleDeleteDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }));
    }

    render() {
        const { locale } = this.props;
        const {
            pristine,
            deleteDialogOpen,
            productOptionName,
            productOptionNameInternal,
            productOptionPriceAdditional,
            formattedProductOptionPriceAdditional,
            productOptionTaxRate,
            formattedProductOptionTaxRate,
            productOptionEnabled,
        } = this.state;

        return (
            <div className="d-flex" style={{ flexDirection: 'column' }}>
                <StateMenuHeader
                    text={this.state.new ? 'Neue Option' : `Option "${productOptionName}"`}
                    available
                    enabled={productOptionEnabled}
                />
                <div className="px-2" style={{ overflow: 'auto' }}>
                    <Form onSubmit={null}>
                        <FormInput
                            label="Bezeichnung:"
                            id="productOptionName"
                            value={productOptionName}
                            valid={this.formValidators.isNameValid()}
                            formFeedBack="Feld darf nicht leer sein und max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductOptionName}
                            onBlur={this.handleOnBlur}
                            focus={this.props.new && !this.state.touched}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <FormInput
                            label="Interner Name:"
                            id="productOptionNameInternal"
                            value={productOptionNameInternal}
                            valid={this.formValidators.isNameInternalValid()}
                            formFeedBack="Feld darf max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductOptionNameInternal}
                            onBlur={this.handleOnBlur}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <Row noGutters className="align-items-top pt-2 mb-3">
                            <Col xs="6" className="pr-2">
                                <FormInput
                                    label="Aufpreis (Brutto):"
                                    id="productOptionPriceAdditional"
                                    type="text"
                                    value={formattedProductOptionPriceAdditional}
                                    valid={this.formValidators.isPriceAdditionalValid()}
                                    formFeedBack="Aufrpeis darf nicht negativ sein."
                                    onChange={this.handleChangeProductOptionPriceAdditional}
                                    onBlur={() => {
                                        this.handleOnBlur();
                                        this.setState({
                                            productOptionPriceAdditional:
                                                Number(productOptionPriceAdditional).toFixed(2),
                                            formattedProductOptionPriceAdditional:
                                                formatLocalizedNumber(productOptionPriceAdditional, locale),
                                        });
                                    }}
                                    inputGroupText="&euro;"
                                    alignInputTextRight
                                    forceHideValidationErrors={
                                        this.state.pristine && !this.state.forceShowValidationErrors
                                    }
                                    forceShowValidationErrors={this.state.forceShowValidationErrors}
                                    inputMode="decimal"
                                />
                            </Col>
                            <Col xs="6" className="pl-2">
                                <FormInput
                                    label="Steuersatz:"
                                    id="productOptionTaxRate"
                                    type="text"
                                    value={formattedProductOptionTaxRate}
                                    valid={this.formValidators.isTaxRateValid()}
                                    formFeedBack="Steuersatz muss zwischen 0 und 100 % liegen."
                                    onChange={this.handleChangeProductOptionTaxRate}
                                    onBlur={() => {
                                        this.handleOnBlur();
                                        this.setState({
                                            productOptionTaxRate: Number(productOptionTaxRate).toFixed(2),
                                            formattedProductOptionTaxRate:
                                                formatLocalizedNumber(productOptionTaxRate, locale, 0),
                                        });
                                    }}
                                    inputGroupText="&#37;"
                                    alignInputTextRight
                                    forceHideValidationErrors={
                                        this.state.pristine && !this.state.forceShowValidationErrors
                                    }
                                    forceShowValidationErrors={this.state.forceShowValidationErrors}
                                    inputMode="decimal"
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="w-100 flex-grow-0 product-option-form-toggle-area px-2">
                    <Row noGutters className="form-toggle-row">
                        <Toggle
                            id="toggle-product-option-enabled"
                            label={`Option ${productOptionEnabled ? 'sichtbar' : 'versteckt'}`}
                            checked={productOptionEnabled}
                            onChange={this.handleChangeEnabled}
                        />
                    </Row>
                    <Row noGutters className="align-items-center pt-2">
                        <Col className="pr-1" xs="10">
                            <Button
                                className="w-100"
                                disabled={pristine}
                                onClick={this.handleUpdate}
                            >
                                Option speichern
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100"
                                color="danger"
                                onClick={this.toggleDeleteDialog}
                            >
                                <i className="fa fa-trash-o" />
                            </Button>
                        </Col>
                    </Row>
                    <DeleteDialog
                        isOpen={deleteDialogOpen}
                        toggle={this.toggleDeleteDialog}
                        onConfirm={this.handleDelete}
                        object={{
                            header: this.props.new ? 'Änderungen verwerfen' : 'Option löschen',
                            body: this.props.new
                                ? 'Wollen Sie die nicht gespeicherten Änderungen wieder verwerfen?'
                                : 'Wollen Sie das Option wirklich löschen?',
                            confirmButtonText: this.props.new ? 'Änderungen verwerfen' : 'Option löschen',
                        }}
                    />
                </div>
            </div>
        );
    }

}

// eslint wrongly reports unused prop types
CreateEditProductOptionForm.propTypes = {
    locale: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    new: PropTypes.bool,  // eslint-disable-line react/no-unused-prop-types
    productOption: PropTypes.object,  // eslint-disable-line react/no-unused-prop-types
    productOptionGroup: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    forceClearFormData: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

CreateEditProductOptionForm.defaultProps = {
    forceClearFormData: false,
};

export default CreateEditProductOptionForm;
