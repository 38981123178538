import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { clearPushNotifications as clearPushNotificationsAction } from 'containers/App/actions';
import { fetchQueueForToday as fetchQueueForTodayAction } from 'containers/Monitor/action';

const WHITELISTED_PUSH_TYPES = [
    'NEW',
    'CANCELED',
];

class PushReceiver extends React.Component {

    handleNotification(pushNotification) {
        const {
            location,
            dispatch,
            clearPushNotifications,
            fetchQueueForToday,
        } = this.props;

        const { holsAppPushType } = pushNotification;

        if (WHITELISTED_PUSH_TYPES.indexOf(holsAppPushType) !== -1) {
            // tap:true the user has tapped on the push message
            const {
                // holsAppOrderId: orderId,
                // holsAppOrderState: orderState,
                tap,
            } = pushNotification;

            if (tap === true) {
                // the application was not in foreground (background or coldstart), so set the current date
                fetchQueueForToday();
                if (location.pathname !== '/monitor') {
                    dispatch(push('/monitor'));
                }
            }
        }

        // once handled, clear push notification from Redux store
        clearPushNotifications();
    }

    render() {
        const { loggedIn, pushNotification } = this.props;
        if (loggedIn && pushNotification) {
            this.handleNotification(pushNotification);
        }

        return null;
    }

}

PushReceiver.propTypes = {
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    pushNotification: PropTypes.object,
    clearPushNotifications: PropTypes.func,
    fetchQueueForToday: PropTypes.func,
    dispatch: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,
        loggedIn: state.login.loggedIn,
        pushNotification: state.app.pushNotification,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearPushNotifications: () => dispatch(clearPushNotificationsAction()),
        fetchQueueForToday: () => dispatch(fetchQueueForTodayAction()),
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PushReceiver);
