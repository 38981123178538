import { API_URI } from 'config';
import { authorizedRequest } from 'utils';

import {
    MERCHANT_STATE_ERROR,
    MERCHANT_STATE_REQUEST,
    MERCHANT_STATE_SUCCESS,
    IS_MERCHANT_ACTIVATED_ERROR,
    IS_MERCHANT_ACTIVATED_REQUEST,
    IS_MERCHANT_ACTIVATED_SUCCESS,
    CHECK_OPEN_STATE,
} from './constants';

/*
 * ACTION CREATORS
 */

const setMerchantStateRequest = () => ({
    type: MERCHANT_STATE_REQUEST,
});

const setMerchantStateSuccess = data => ({
    type: MERCHANT_STATE_SUCCESS,
    data,
});

const setMerchantStateError = error => ({
    type: MERCHANT_STATE_ERROR,
    error,
});

const isMerchantActivatedRequest = () => ({
    type: IS_MERCHANT_ACTIVATED_REQUEST,
});

const isMerchantActivatedSuccess = data => ({
    type: IS_MERCHANT_ACTIVATED_SUCCESS,
    data,
});

const isMerchantActivatedError = error => ({
    type: IS_MERCHANT_ACTIVATED_ERROR,
    error,
});

export const checkOpenState = check => ({
    type: CHECK_OPEN_STATE,
    check,
});

/*
 * DISPATCHERS
 */

export function setMerchantState(state) {
    return dispatch => {
        dispatch(setMerchantStateRequest());

        const url = new URL('/api/merchant/available', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PATCH',
            payload: { available: state },
        })
            .then(() => {
                dispatch(setMerchantStateSuccess(state));
            })
            .catch(error => {
                dispatch(setMerchantStateError(error));
            });
    };
}

export function isMerchantActivated() {
    return dispatch => {
        dispatch(isMerchantActivatedRequest());

        const url = new URL('/api/merchant/available', API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(json => {
                dispatch(isMerchantActivatedSuccess(json.available));
            })
            .catch(error => {
                dispatch(isMerchantActivatedError(error));
            });
    };
}
