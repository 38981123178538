import React from 'react';
import { Container } from 'reactstrap';

import Device from '../../services/Device';
import Push from '../../services/Push';

import { API_URI } from '../../config';

import './styles.css';

function About() {

    /*
     * webpack.DefinePlugin will inject the variable '__VERSION__'.
     * To change it, the app needs to be recompiled.
     */
    const injectedVersion = __VERSION__; // eslint-disable-line no-undef
    const buildTarget = __BUILD_TARGET__; // eslint-disable-line no-undef

    const { id: deviceId } = Device.getDeviceInfo();
    const pushId = Push.getPushToken();

    return (
        <div className="about-page">
            <Container>
                <h3 className="mt-4">Über</h3>

                <p>Bei Fragen wenden sie sich an <a href="mailto:partners@hols-app.at ">partners@hols-app.at</a>.</p>

                <p style={{ marginBottom: 20 }}>
                    <strong>App Version:</strong> {injectedVersion}<br />
                    { buildTarget === 'debug' &&
                        <span><strong>API URI:</strong> {API_URI}<br /></span>
                    }
                    { buildTarget === 'debug' &&
                        <span><strong>Device Identifier:</strong> {deviceId || '-'}<br /></span>
                    }
                    { buildTarget === 'debug' &&
                        <span><strong>Push Token:</strong> {pushId ? `${pushId.substring(0, 15)}…` : '-'}</span>
                    }
                </p>
                <hr />

                <h4>Impressum</h4>

                <p>
                    <strong>Medieninhaber:</strong> LIMA ZULU GmbH
                </p>
                <p>
                    Argentinierstraße 21<br />
                    1040 Wien<br />
                    Austria (Europe)
                </p>
                <p>
                    <strong>Tel:</strong> +43 1 9049007-0<br />
                    <strong>Fax:</strong> +43 1 5057473<br />
                    <strong>Email:</strong> <a href="mailto:contact@lima.or.at">contact@lima.or.at</a>
                </p>
                <p>
                    <strong>Firmenbuch:</strong> FN 441222g (HG Wien)<br />
                    <strong>UID:</strong> AT U71443208
                </p>
                <p>
                    <strong>Unternehmensgegenstand:</strong><br />Erforschung, Entwurf, Entwicklung, Erprobung, Betrieb,
                    Verkauf und Handel mit mobilen Applikationen
                </p>
                <p>
                    <strong>Gewerbe- und Aufsichtsbehörde:</strong> <br />Magistrat Wien, Magistratisches Bezirksamt für
                    den 4 und 5. Bezirk
                </p>
                <hr />
                <p>
                    Mitglied der Wirtschaftskammer Wien Fachgruppe UBIT
                </p>
                <p>
                    <strong>Anwendbare Rechtsvorschrift:</strong><br /> Gewerbeordnung 1994 (GewO) abrufbar unter&nbsp;
                    <a
                        href="https://www.ris.bka.gv.at/Bundesrecht/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.ris.bka.gv.at/Bundesrecht/
                    </a>
                </p>
                <hr />

                <h4>
                    Urheberrecht
                </h4>
                <p>
                    Der Inhalt, einschließlich Bilder, und die Gestaltung der Webseiten von LIMA ZULU unterliegen dem
                    Schutz des Urheberrechts und anderer Gesetze zum Schutz geistigen Eigentums, unabhängig von einer
                    gesonderten Kennzeichnung.
                </p>
                <p>
                    Die Speicherung und Vervielfältigung der Dokumente ist nur für den persönlichen und informativen
                    Gebrauch gestattet. Jede sonstige Vervielfältigung, Verbreitung, Wieder- und Weitergabe und sonstige
                    Nutzung, insbesondere die Verwendung von Texten, Textteilen, Bildmaterial oder Software, die direkt
                    oder indirekt von der Webseite von LIMA ZULU abgerufen oder verwendet werden kann, ist ohne
                    vorangehende schriftliche Zustimmung von LIMA ZULU nicht gestattet. Die Veränderung des Inhalts
                    dieser Seiten einschließlich des sogenannten Framings und ähnlicher Maßnahmen ist nicht gestattet.
                    Durch diese Webseite wird keine Lizenz zur Nutzung des geistigen Eigentums von LIMA ZULU oder von
                    verbundenen Unternehmen gewährt. Soweit nicht anders angegeben, sind sämtliche auf den Webseiten
                    von LIMA ZULU verwendeten Marken markenrechtlich geschützt. Dies gilt insbesondere für
                    Unternehmenslogos und Kennzeichen.
                </p>
                <p>
                    Diese Webseiten enthalten auch Links oder Verweise auf Websites Dritter. Dies Verwendung dieser
                    Links auf den Webseiten von LIMA ZULU zu Webseiten Dritter stellt keine Zustimmung zu den dort
                    verfügbaren Inhalten durch LIMA ZULU dar. LIMA ZULU übernimmt keine Verantwortung für die
                    Verfügbarkeit oder den Inhalt solcher Webseiten und keine Haftung für Schäden oder Verletzungen,
                    die aus der Nutzung - gleich welcher Art - solcher Inhalte entstehen. Links zu anderen Webseiten
                    werden Nutzern der Webseiten von LIMA ZULU lediglich als Annehmlichkeit zur Verfügung gestellt.
                    Das Herstellen einer Verbindung zu diesen Websites geschieht auf eigene Gefahr und Verantwortung
                    des Nutzers.
                </p>
                <p>
                    Copyright © {new Date().getFullYear()} LIMA ZULU GmbH
                </p>
                <p>
                    Powered by&nbsp;
                    <a
                        href="https://www.rise-world.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="external-link"
                    >
                        RISE
                    </a>
                </p>

            </Container>
        </div>
    );
}

export default About;
