import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import './style.css';

function MenuCardCategoryItem({
    category,
    selected,
    onClick,
    onKeyPress,
    numberOfProducts,
    hideArrowUp,
    onArrowUpClick,
    hideArrowDown,
    onArrowDownClick,
    disableArrows,
}) {
    let categoryColor = '#000';

    if (!category.enabled) {
        categoryColor = '#aaa';
    }

    return (
        <Row
            role="menuitem"
            tabIndex="0"
            className={
                `menu-card-category-item mt-2 align-items-center ${selected && 'menu-card-category-item-selected'}`
            }
            key={category._id}
            onClick={onClick}
            onKeyPress={onKeyPress}
            noGutters
        >
            <Col
                className="flex-grow-1 truncated-name"
                style={{ color: categoryColor }}
            >
                {category.name}
            </Col>
            <Col className="flex-grow-0 mx-2 menu-card-category-number">
                {numberOfProducts}
            </Col>
            <Col
                className="menu-card-product-item-arrow flex-grow-0 mx-2"
                style={{
                    visibility: hideArrowUp ? 'hidden' : 'visible',
                    display: disableArrows && 'none',
                }}
                onClick={onArrowUpClick}
            >
                <i className="fa fa-arrow-up text-secondary" />
            </Col>
            <Col
                className="menu-card-product-item-arrow flex-grow-0 mx-2"
                style={{
                    visibility: hideArrowDown ? 'hidden' : 'visible',
                    display: disableArrows && 'none',
                }}
                onClick={onArrowDownClick}
            >
                <i className="fa fa-arrow-down text-secondary" />
            </Col>
        </Row>
    );
}

MenuCardCategoryItem.propTypes = {
    category: PropTypes.any.isRequired,
    numberOfProducts: PropTypes.number,
    selected: PropTypes.bool,
    hideArrowUp: PropTypes.bool,
    hideArrowDown: PropTypes.bool,
    disableArrows: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    onArrowUpClick: PropTypes.func,
    onArrowDownClick: PropTypes.func,
};

export default MenuCardCategoryItem;
