import React from 'react';
import PropTypes from 'prop-types';
import {
    Col,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
} from 'reactstrap';


class FormInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showValidationErrors: false,
            focus: props.focus,
        };

        this.inputRef = element => {
            this.input = element;
        };

        this.focusInput = () => {
            if (this.input) this.input.focus();
        };

        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.buildInput = this.buildInput.bind(this);
    }

    componentDidMount() {
        if (this.state.focus) {
            this.focusInput();
        }
    }

    componentDidUpdate() {
        if (this.state.focus) {
            this.focusInput();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        const nextState = {};

        if (nextProps.focus && nextProps.focus !== prevState.focus) {
            nextState.focus = nextProps.focus;
        }

        if (nextProps.forceHideValidationErrors) {
            nextState.showValidationErrors = false;
        }

        if (nextProps.forceShowValidationErrors) {
            nextState.showValidationErrors = true;
        }

        return nextState;
    }

    handleOnBlur() {
        if (this.props.onBlur) this.props.onBlur();

        this.setState({
            showValidationErrors: true,
            focus: false,
        });
    }

    buildInput() {

        const input = () => (
            <Input
                disabled={this.props.disabled}
                className={this.props.alignInputTextRight ? 'text-right' : ''}
                invalid={this.state.showValidationErrors && !this.props.valid}
                innerRef={this.inputRef}
                id={this.props.id}
                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.handleOnBlur}
                type={this.props.type}
                pattern={this.props.pattern}
                inputMode={this.props.inputMode}
            />
        );

        const formFeedBack = () => {
            if (this.state.showValidationErrors && this.props.formFeedBack) {
                return (
                    <FormFeedback>
                        {this.props.formFeedBack}
                    </FormFeedback>
                );
            } else {
                return null;
            }
        };

        const formText = () => {
            if (this.props.formText) {
                return (
                    <FormText>
                        {this.props.formText}
                    </FormText>
                );
            } else {
                return null;
            }
        };

        const inputWithHelperText = () => {
            if (this.props.inputGroupText) {
                return (
                    <React.Fragment>
                        <InputGroup>
                            {input()}
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{this.props.inputGroupText}</InputGroupText>
                            </InputGroupAddon>
                            {formFeedBack()}
                            {formText()}
                        </InputGroup>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {input()}
                        {formFeedBack()}
                        {formText()}
                    </React.Fragment>
                );
            }
        };

        if (this.props.alignLabelRight) {
            return (
                <React.Fragment>
                    <Label for={this.props.id} sm={4}>
                        {this.props.label}
                    </Label>
                    <Col sm={8}>
                        {inputWithHelperText()}
                    </Col>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Label for={this.props.id}>
                        {this.props.label}
                    </Label>
                    {inputWithHelperText()}
                </React.Fragment>
            );
        }

    }

    render() {
        return (
            <FormGroup className="mt-2" row={this.props.alignLabelRight}>
                {this.buildInput()}
            </FormGroup>
        );
    }

}

FormInput.propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.any.isRequired, // form input value
    label: PropTypes.string.isRequired, // label of the form input
    valid: PropTypes.bool, // validity of the input field
    focus: PropTypes.bool, // if set to true, focus is set
    forceHideValidationErrors: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    forceShowValidationErrors: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    formText: PropTypes.string, // text below the input field that remains unchanged
    formFeedBack: PropTypes.string, // text below the input field for feedback
    onChange: PropTypes.func.isRequired, // called when value changes
    onBlur: PropTypes.func, // called when input field looses focus
    alignLabelRight: PropTypes.bool, // if true label is positioned to the next of the field
    inputGroupText: PropTypes.string, // if set an input group text is set
    type: PropTypes.string, // input field type
    alignInputTextRight: PropTypes.bool, // right align the text of the input field
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
};

FormInput.defaultProps = {
    valid: true,
    focus: false,
    disabled: false,
    forceHideValidationErrors: false,
    forceShowValidationErrors: false,
    alignLabelRight: false,
    type: 'text',
};

export default FormInput;
