import React from 'react';
import PropTypes from 'prop-types';

function StateMenuHeader({ text, available, enabled }) {
    const headers = [];

    if (!available) { headers.push(<h5 key="available">Aufgegessen</h5>); }
    if (!enabled) { headers.push(<h5 key="enabled">Deaktiviert</h5>); }

    return (
        <div className="p-2 flex-grow-0">
            {(() => {
                if (available && enabled) {
                    return <h4 className="category-name">{text}</h4>;
                }
                return (
                    <div style={{ color: '#dc3545' }}>
                        <h4 className="category-name">{text}</h4>
                        {headers}
                    </div>
                );
            })()}
        </div>
    );
}

StateMenuHeader.propTypes = {
    text: PropTypes.string.isRequired,
    available: PropTypes.bool,
    enabled: PropTypes.bool,
};

StateMenuHeader.defaultProps = {
    available: true,
};

export default StateMenuHeader;
