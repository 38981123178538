
/*
 * ACTIONS
 */

export const EVENTSOURCE_ON_OPEN = 'Queue/EVENTSOURCE_ON_OPEN';
export const EVENTSOURCE_ON_PING = 'Queue/EVENTSOURCE_ON_PING';
export const EVENTSOURCE_ON_MESSAGE = 'Queue/EVENTSOURCE_ON_MESSAGE';
export const EVENTSOURCE_ON_ERROR = 'Queue/EVENTSOURCE_ON_ERROR';
export const EVENTSOURCE_ON_CLOSE = 'Queue/EVENTSOURCE_ON_CLOSE';

export const GET_QUEUE_REQUEST = 'Queue/GET_QUEUE_REQUEST';
export const GET_QUEUE_SUCCESS = 'Queue/GET_QUEUE_SUCCESS';
export const GET_QUEUE_ERROR = 'Queue/GET_QUEUE_ERROR';

export const SET_ORDER_STATE_REQUEST = 'Queue/SET_ORDER_STATE_REQUEST';
export const SET_ORDER_STATE_SUCCESS = 'Queue/SET_ORDER_STATE_SUCCESS';
export const SET_ORDER_STATE_ERROR = 'Queue/SET_ORDER_STATE_ERROR';
