import {
    SELECT_CATEGORY,
    SELECT_PRODUCT,
} from './constants';

export const selectCategory = categoryId => ({
    type: SELECT_CATEGORY,
    categoryId,
});

export const selectProduct = productId => ({
    type: SELECT_PRODUCT,
    productId,
});
