import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';

import OrderDialog from 'components/OrderDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import CancelDialog from 'components/CancelDialog';
import OrderStateButton from 'components/OrderStateButton';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import FormattedAmount from 'components/FormattedAmount';
import OwnPackagingIcon from 'components/OwnPackagingIcon';
import PreorderIcon from 'components/PreorderIcon';

import { changeOrderState as changeOrderStateAction } from 'containers/Monitor/action';
import { blockCustomer as blockCustomerAction } from 'containers/CustomerBlackList/action';
import { formatPhoneNumber, USER_TYPE_MAP, PAYMENT_STATE_MAP } from 'utils';

import './styles.css';

const removeDragAndDropFeedbackEvent = new CustomEvent('remove-drag-and-drop-feedback', {
    bubbles: true,
});

class OrderItem extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.getTimeFromX = this.getTimeFromX.bind(this);
        this.updateTimeFromNow = this.updateTimeFromNow.bind(this);
        this.handleSetState = this.handleSetState.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleBlockCustomer = this.handleBlockCustomer.bind(this);
        this.isCustomerBlocked = this.isCustomerBlocked.bind(this);
        this.handleCancelDialogConfirm = this.handleCancelDialogConfirm.bind(this);

        this.state = {
            orderDialogOpen: false,
            confirmDialogOpen: false,
            cancelDialogOpen: false,
            timeFromNow: this.getTimeFromX(this.props.order.createdAt),
        };
    }

    componentDidMount() {
        this.updateTimeFromNowPeriodically = setInterval(this.updateTimeFromNow, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.updateTimeFromNowPeriodically);

        // cancel a running drag operation for this order item prior to removing it from the DOM
        // see #65, Safari gets stuck if the DOM element is removed during drag
        this.ref.current.ondrag = e => {
            e.preventDefault();
            return false;
        };

        // clears the highlighting of the lane body, if any
        this.props.onDrop();
    }

    /* eslint-disable class-methods-use-this */
    onDragStart(event, payload) {
        event.dataTransfer.setData('text', payload);
    }

    onDragEnd(event) {
        event.target.dispatchEvent(removeDragAndDropFeedbackEvent);
    }
    /* eslint-enable */

    getTimeFromX(timeStamp) {
        return moment.utc(timeStamp)
            .locale(this.props.locale)
            .fromNow();
    }

    updateTimeFromNow() {
        this.setState({
            timeFromNow: this.getTimeFromX(this.props.order.createdAt),
        });
    }

    handleSetState(state) {
        this.props.changeOrderState(this.props.order._id, state);
    }

    handleBlockCustomer() {
        this.setState({ orderDialogOpen: false });
        this.props.blockCustomer({
            _id: this.props.order.customer._id,
            phoneNumber: this.props.order.customer.phoneNumber,
        }).then(() => {
            toast.success('Kunde wurde blockiert.', { position: toast.POSITION.TOP_CENTER });
        }).catch(() => toast.error(
            'Fehler beim Blockieren des Kunden.',
            { position: toast.POSITION.TOP_CENTER },
        ));
    }

    isCustomerBlocked() {
        const { customerBlackList, order } = this.props;

        return order.customer && customerBlackList
            .map(entry => entry.customer._id)
            .includes(order.customer._id);
    }

    handleCancelDialogConfirm(canceledReasons, canceledComment) {
        this.setState({ cancelDialogOpen: false });
        this.props.changeOrderState(this.props.order._id, 'CANCELED', {
            canceledReasons,
            canceledComment,
        });
    }

    render() {
        const { order } = this.props;
        const {
            orderDialogOpen,
            confirmDialogOpen,
            cancelDialogOpen,
        } = this.state;

        const payload = {
            id: order._id,
            state: order.state,
            canceledBy: order.canceledBy,
        };

        let displayTime = '';
        let displayIcon = null;
        if (order.pickUpCustomerAt) {
            displayTime = moment(order.pickUpCustomerAt).format('HH:mm');
            displayIcon = <PreorderIcon />;
        } else {
            displayTime = this.state.timeFromNow;
            displayIcon = <i className="fa fa-clock-o fa-lg" />;
        }

        const cardHeader = order.createdBy === USER_TYPE_MAP.CUSTOMER
            ? (
                <React.Fragment>
                    {displayIcon} {displayTime}
                    <FormattedPhoneNumber
                        className={this.isCustomerBlocked() ? 'text-danger' : ''}
                        phoneNumber={order.customer.phoneNumber}
                        styled
                    />
                </React.Fragment>
            )
            : (
                <React.Fragment>
                    {displayIcon} {displayTime}
                    <span className="float-right">Restaurant</span>
                </React.Fragment>
            );

        let cardHeaderClass = '';
        if (order.pickUpType.toLowerCase() === 'pre_order') {
            cardHeaderClass = 'order-type-pre_order';
        } else if (order.createdBy === USER_TYPE_MAP.MERCHANT) {
            cardHeaderClass = 'order-type-merchant';
        }

        const paymentFailed = order.stripe?.lastEvent?.match(/failed$/);

        /* eslint-disable jsx-a11y/click-events-have-key-events */
        return (
            <div
                id={order._id}
                data-testid="order-item"
                ref={this.ref}
                draggable
                onDragStart={e => this.onDragStart(e, JSON.stringify(payload))}
                onDragEnd={this.onDragEnd}
                onClick={() => this.setState({ orderDialogOpen: true })}
                role="button"
                tabIndex={0}
            >
                <Card
                    className="order-item-card mr-2 ml-2 mt-2 mb-3"
                >
                    <CardHeader className={cardHeaderClass}>
                        {cardHeader}
                    </CardHeader>
                    <CardBody>
                        <div className="float-right">
                            <h5 className="order-item-pickUpNumber">
                                {order.pickUpNumber}
                            </h5>
                            {order.state !== 'PICKED_UP' && order.state !== 'CANCELED' &&
                                <OrderStateButton
                                    currentState={order.state}
                                    onSetState={this.handleSetState}
                                />
                            }
                        </div>
                        <ul className="order-item-list">
                            {order.products.map(product => (
                                <li
                                    key={product._id}
                                    style={{
                                        color: product.color || '#000',
                                    }}
                                >
                                    {product.quantity}x {product.nameInternal || product.name}
                                    {product.optionGroups && product.optionGroups.map(optionGroup => (
                                        <ul
                                            key={optionGroup._id}
                                            className="order-item-options-list"
                                        >
                                            {optionGroup.options && optionGroup.options.map(option => (
                                                <li key={option._id}>
                                                    <small>{option.nameInternal || option.name}</small>
                                                </li>
                                            ))}
                                        </ul>
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <small className="text-secondary">{order.note}</small>
                        {order.ownPackaging && order.note &&
                            <br />
                        }
                        {order.ownPackaging &&
                            <small className="text-secondary">
                                <OwnPackagingIcon /> Ich bringe meine Verpackung
                            </small>
                        }
                        {order.paymentType === 'STRIPE' && (order.ownPackaging || order.note) &&
                            <br />
                        }
                        {order.paymentType === 'STRIPE' &&
                            <small className={`text-secondary order-item-payment-${order.paymentState?.toLowerCase()}`}>
                                <i className="fa fa-credit-card" />
                                &nbsp;
                                Zahlung <strong>{PAYMENT_STATE_MAP[order.paymentState]}</strong>
                                {paymentFailed &&
                                    <span style={{ color: '#dc4535' }}>
                                        &nbsp;
                                        - Zahlung kann zurzeit nicht abgeschlossen werden
                                    </span>
                                }
                            </small>
                        }
                        <div className="order-item-price">
                            {/* Whitespaces inside span blocks are intentional */}
                            <span>Gesamt: </span>
                            <FormattedAmount
                                value={order.price}
                                locale="de"
                            />
                        </div>
                    </CardBody>
                </Card>
                <OrderDialog
                    open={orderDialogOpen}
                    order={order}
                    onClose={() => this.setState({ orderDialogOpen: false })}
                    onCancel={() => this.setState({ cancelDialogOpen: true })}
                    onBlock={() => this.setState({ confirmDialogOpen: true })}
                    onSetState={this.handleSetState}
                    isCustomerBlocked={this.isCustomerBlocked()}
                />
                {order.createdBy === USER_TYPE_MAP.CUSTOMER &&
                    <ConfirmDialog
                        confirmColor="danger"
                        open={confirmDialogOpen}
                        title="Bestätigung"
                        message={
                            `Wollen Sie ${formatPhoneNumber(order.customer.phoneNumber)} wirklich blockieren?`
                        }
                        onConfirm={() => {
                            this.setState({
                                confirmDialogOpen: false,
                            });
                            this.handleBlockCustomer();
                        }}
                        onCancel={() => this.setState({
                            confirmDialogOpen: false,
                        })}
                        confirmButtonText="Blockieren"
                    />
                }
                <CancelDialog
                    open={cancelDialogOpen}
                    onClose={() => this.setState({ cancelDialogOpen: false })}
                    onCancel={() => this.setState({ cancelDialogOpen: false })}
                    onConfirm={this.handleCancelDialogConfirm}
                />
            </div>
        );
    }
    /* eslint-enable jsx-a11y/click-events-have-key-events */

}

OrderItem.propTypes = {
    order: PropTypes.any.isRequired,
    locale: PropTypes.string.isRequired,
    changeOrderState: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    blockCustomer: PropTypes.func.isRequired,
    customerBlackList: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
        customerBlackList: state.customerBlackList.blockedCustomers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeOrderState: (id, state, payload) => dispatch(changeOrderStateAction(id, state, payload)),
        blockCustomer: customer => dispatch(blockCustomerAction(customer)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
