
/*
 * ACTIONS
 */

export const GET_CUSTOMER_BLACKLIST_REQUEST = 'Queue/GET_CUSTOMER_BLACKLIST_REQUEST';
export const GET_CUSTOMER_BLACKLIST_SUCCESS = 'Queue/GET_CUSTOMER_BLACKLIST_SUCCESS';
export const GET_CUSTOMER_BLACKLIST_ERROR = 'Queue/GET_CUSTOMER_BLACKLIST_ERROR';

export const BLOCK_CUSTOMER_REQUEST = 'Queue/BLOCK_CUSTOMER_REQUEST';
export const BLOCK_CUSTOMER_SUCCESS = 'Queue/BLOCK_CUSTOMER_SUCCESS';
export const BLOCK_CUSTOMER_ERROR = 'Queue/BLOCK_CUSTOMER_ERROR';

export const UNBLOCK_CUSTOMER_REQUEST = 'Queue/UNBLOCK_CUSTOMER_REQUEST';
export const UNBLOCK_CUSTOMER_SUCCESS = 'Queue/UNBLOCK_CUSTOMER_SUCCESS';
export const UNBLOCK_CUSTOMER_ERROR = 'Queue/UNBLOCK_CUSTOMER_ERROR';
