import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateEditCategoryForm from 'components/CreateEditCategoryForm';
import CreateEditProductForm from 'components/CreateEditProductForm';
import MenuCardCategoryItem from 'components/MenuCardCategoryItem';
import MenuCardProductItem from 'components/MenuCardProductItem';
import MenuCardColumnButton from 'components/MenuCardColumnButton';
import DeleteDialog from 'components/DeleteDialog';

import {
    changeCategoryPosition as changeCategoryPositionAction,
    changeProductPosition as changeProductPositionAction,
    createCategory as createCategoryAction,
    createProduct as createProductAction,
    deleteCategory as deleteCategoryAction,
    deleteProduct as deleteProductAction,
    updateCategory as updateCategoryAction,
    updateProduct as updateProductAction,
} from 'containers/App/actions';

import {
    selectLocale,
    selectCategories,
    selectMerchant,
    selectProducts,
    selectSelectedCategory,
    selectSelectedProduct,
} from 'containers/App/selectors';

import { selectCategory as selectCategoryAction, selectProduct as selectProductAction } from './actions';

import './styles.css';

class MenuCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newCategory: false,
            newProduct: false,
            categoryFormPristine: true,
            productFormPristine: true,
            dialogContext: {
                selectedCategory: null,
                selectedProduct: null,
            },
            isClearCategoryFormDataDialogOpen: false,
            isClearProductFormDataDialogOpen: false,
            forceClearProductForm: false,
            forceClearCategoryForm: false,
        };

        this.confirmChangeCategory = this.confirmChangeCategory.bind(this);
        this.confirmChangeProduct = this.confirmChangeProduct.bind(this);

        this.handleClickCategory = this.handleClickCategory.bind(this);
        this.handleClickProduct = this.handleClickProduct.bind(this);

        this.handleClickNewCategory = this.handleClickNewCategory.bind(this);
        this.handleClickNewProduct = this.handleClickNewProduct.bind(this);

        this.handleChangeCategoryForm = this.handleChangeCategoryForm.bind(this);
        this.handleCreateCategory = this.handleCreateCategory.bind(this);
        this.handleUpdateCategory = this.handleUpdateCategory.bind(this);
        this.handleDeleteCategory = this.handleDeleteCategory.bind(this);

        this.handleChangeProductForm = this.handleChangeProductForm.bind(this);
        this.handleCreateProduct = this.handleCreateProduct.bind(this);
        this.handleUpdateProduct = this.handleUpdateProduct.bind(this);
        this.handleDeleteProduct = this.handleDeleteProduct.bind(this);

        this.handleMoveCategoryPositionDown = this.handleMoveCategoryPositionDown.bind(this);
        this.handleMoveCategoryPositionUp = this.handleMoveCategoryPositionUp.bind(this);
        this.handleMoveProductPositionDown = this.handleMoveProductPositionDown.bind(this);
        this.handleMoveProductPositionUp = this.handleMoveProductPositionUp.bind(this);
        this.toggleClearProductFormDataDialog = this.toggleClearProductFormDataDialog.bind(this);
        this.toggleClearCategoryFormDataDialog = this.toggleClearCategoryFormDataDialog.bind(this);
        this.handleClearProductFormData = this.handleClearProductFormData.bind(this);
        this.handleClearCategoryFormData = this.handleClearCategoryFormData.bind(this);
        this.handleNewCategory = this.handleNewCategory.bind(this);
        this.handleNewProduct = this.handleNewProduct.bind(this);
    }

    confirmChangeCategory(category) {

        if (!this.state.productFormPristine || !this.state.categoryFormPristine) {
            this.setState({
                isClearCategoryFormDataDialogOpen: true,
                dialogContext: {
                    selectedCategory: category,
                },
            });
        } else {
            this.handleClickCategory(category);
        }
    }

    confirmChangeProduct(product) {
        if (!this.state.productFormPristine) {
            this.setState({
                isClearProductFormDataDialogOpen: true,
                dialogContext: {
                    selectedProduct: product,
                },
            });
        } else {
            this.handleClickProduct(product);
        }
    }

    handleClickCategory(category) {
        this.props.selectCategory(category._id);
        this.props.selectProduct(null);
        this.setState({
            newCategory: false,
            newProduct: false,
            categoryFormPristine: true,
            productFormPristine: true,
        });
    }

    handleClickProduct(product) {
        this.props.selectProduct(product._id);
        this.setState({
            newProduct: false,
            productFormPristine: true,
        });
    }

    handleClickNewCategory(event) {
        if (!this.state.categoryFormPristine) {
            this.setState({
                isClearCategoryFormDataDialogOpen: true,
            });
        } else {
            this.handleNewCategory();
        }

        event.preventDefault();
    }

    handleNewCategory() {
        this.props.selectCategory(null);
        this.props.selectProduct(null);
        this.setState({
            newCategory: true,
            categoryFormPristine: true,
        });
    }

    handleClickNewProduct(event) {
        if (!this.state.productFormPristine) {
            this.setState({
                isClearProductFormDataDialogOpen: true,
            });
        } else {
            this.handleNewProduct();
        }
        event.preventDefault();
    }

    handleNewProduct() {
        this.props.selectProduct(null);
        this.setState({
            newProduct: true,
            productFormPristine: true,
        });
    }

    handleChangeCategoryForm() {
        this.setState({
            categoryFormPristine: false,
            forceClearCategoryForm: false,
        });
    }

    handleCreateCategory(category) {
        return this.props.createCategory(category).then(() => {
            this.setState({
                newCategory: false,
                categoryFormPristine: true,
            });
        });
    }

    handleUpdateCategory(category) {
        return this.props.updateCategory(category).then(() => {
            this.setState({
                newCategory: false,
                categoryFormPristine: true,
            });
        });
    }

    handleDeleteCategory(category) {
        if (category) {
            this.props.deleteCategory(category);
        }
        this.setState({
            newCategory: false,
            categoryFormPristine: true,
        });
    }

    handleChangeProductForm() {
        this.setState({
            productFormPristine: false,
            forceClearProductForm: false,
        });
    }

    handleCreateProduct(product) {
        return this.props.createProduct(product).then(() => {
            this.setState({
                newProduct: false,
                productFormPristine: true,
            });
        });
    }

    handleUpdateProduct(product) {
        return this.props.updateProduct(product).then(() => {
            this.setState({
                newProduct: false,
                productFormPristine: true,
            });
        });
    }

    handleDeleteProduct(product) {
        if (product) {
            this.props.deleteProduct(product);
        }
        this.setState({
            newProduct: false,
            productFormPristine: true,
        });
    }

    handleMoveCategoryPositionDown(category) {
        const {
            categories,
            changeCategoryPosition,
            merchant,
        } = this.props;

        const index = categories.findIndex(elem => elem._id === category._id);
        if (index < categories.length - 1) {
            changeCategoryPosition(category, index + 1, merchant._version);
        }
    }

    handleMoveCategoryPositionUp(category) {
        const {
            categories,
            changeCategoryPosition,
            merchant,
        } = this.props;

        const index = categories.findIndex(elem => elem._id === category._id);
        if (index > 0) {
            changeCategoryPosition(category, index - 1, merchant._version);
        }
    }

    handleMoveProductPositionDown(product) {
        const {
            products,
            selectedCategory,
            changeProductPosition,
            merchant,
        } = this.props;

        const index = products.findIndex(elem => elem._id === product._id);

        if (index < products.length - 1) {

            const productsFilteredByCategory = products
                .filter(elem => elem.category === selectedCategory._id);

            const indexFilteredByCategory = productsFilteredByCategory.findIndex(elem => elem._id === product._id);

            if (indexFilteredByCategory < productsFilteredByCategory.length - 1) {
                const realIndexOfFollowingProduct = products
                    .findIndex(elem => elem._id === productsFilteredByCategory[indexFilteredByCategory + 1]._id);
                changeProductPosition(product, realIndexOfFollowingProduct, merchant._version);
            }
        }
    }

    handleMoveProductPositionUp(product) {
        const {
            products,
            selectedCategory,
            changeProductPosition,
            merchant,
        } = this.props;

        const index = products.findIndex(elem => elem._id === product._id);

        if (index > 0) {

            const productsFilteredByCategory = products
                .filter(elem => elem.category === selectedCategory._id);

            const indexFilteredByCategory = productsFilteredByCategory.findIndex(elem => elem._id === product._id);

            if (indexFilteredByCategory > 0) {
                const realIndexOfPreviousProduct = products
                    .findIndex(elem => elem._id === productsFilteredByCategory[indexFilteredByCategory - 1]._id);
                changeProductPosition(product, realIndexOfPreviousProduct, merchant._version);
            }
        }
    }

    toggleClearCategoryFormDataDialog() {
        this.setState(state => ({
            isClearCategoryFormDataDialogOpen: !state.isClearCategoryFormDataDialogOpen,
            dialogContext: {
                selectedCategory: null,
            },
        }));
    }

    toggleClearProductFormDataDialog() {
        this.setState(state => ({
            isClearProductFormDataDialogOpen: !state.isClearProductFormDataDialogOpen,
            dialogContext: {
                selectedProduct: null,
            },
        }));
    }

    handleClearCategoryFormData(event) {

        const { selectedCategory } = this.state.dialogContext;

        if (selectedCategory) {
            this.handleClickCategory(selectedCategory);
        } else {
            this.setState({
                forceClearCategoryForm: true,
            });
            this.handleNewCategory();
        }

        event.preventDefault();
    }

    handleClearProductFormData(event) {

        const { selectedProduct } = this.state.dialogContext;

        if (selectedProduct) {
            this.handleClickProduct(selectedProduct);
        } else {
            this.setState({
                forceClearProductForm: true,
            });
            this.handleNewProduct();
        }

        event.preventDefault();
    }

    render() {
        const {
            locale,
            merchant,
            categories,
            products,
            selectedCategory,
            selectedProduct,
        } = this.props;
        const {
            isClearCategoryFormDataDialogOpen,
            isClearProductFormDataDialogOpen,
            newCategory,
            newProduct,
            forceClearCategoryForm,
            forceClearProductForm,
        } = this.state;

        const showCategoryCol = (selectedCategory != null || newCategory);
        const showProductCol = showCategoryCol && (selectedProduct != null || newProduct);

        if (!merchant) {
            return null;
        }

        return (
            <Row noGutters className="h-100 w-100">
                <Col
                    className="flex-column border-right pt-3 h-100"
                    style={{ display: 'flex' }}
                >
                    <div className="flex-grow-0 p-2">
                        <h4>Kategorien</h4>
                    </div>
                    <div className="flex-grow-1 h-100 overflow-auto px-2" role="listbox">
                        {categories
                            .map((category, index) => (
                                <MenuCardCategoryItem
                                    key={category._id}
                                    category={category}
                                    numberOfProducts={
                                        products.filter(product => product.category === category._id).length
                                    }
                                    selected={
                                        selectedCategory && selectedCategory._id === category._id
                                    }
                                    hideArrowUp={index === 0}
                                    hideArrowDown={index === categories.length - 1}
                                    onClick={() => this.confirmChangeCategory(category)}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.handleClickCategory(event, category);
                                        }
                                    }}
                                    onArrowDownClick={() => this.handleMoveCategoryPositionDown(category)}
                                    onArrowUpClick={() => this.handleMoveCategoryPositionUp(category)}
                                />
                            ))
                        }
                    </div>
                    <MenuCardColumnButton
                        onClick={this.handleClickNewCategory}
                        value="Kategorie hinzufügen"
                    />
                </Col>

                <Col
                    className="flex-column border-right pt-3 h-100"
                    style={{ display: showCategoryCol ? 'flex' : 'none' }}
                >
                    <CreateEditCategoryForm
                        new={newCategory}
                        category={selectedCategory}
                        forceClearFormData={forceClearCategoryForm}
                        onChange={this.handleChangeCategoryForm}
                        onCreate={this.handleCreateCategory}
                        onUpdate={this.handleUpdateCategory}
                        onDelete={this.handleDeleteCategory}
                    />
                    <div className="flex-grow-1 overflow-auto p-2" role="listbox">
                        {selectedCategory && products
                            .filter(product => product.category === selectedCategory._id)
                            .map((product, index, arr) => (
                                <MenuCardProductItem
                                    key={product._id}
                                    locale={locale}
                                    product={product}
                                    selected={
                                        selectedProduct && selectedProduct._id === product._id
                                    }
                                    hideArrowUp={index === 0}
                                    hideArrowDown={index === arr.length - 1}
                                    onClick={() => this.confirmChangeProduct(product)}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.handleClickProduct(event, product);
                                        }
                                    }}
                                    onArrowUpClick={() => this.handleMoveProductPositionUp(product)}
                                    onArrowDownClick={() => this.handleMoveProductPositionDown(product)}
                                />
                            ))
                        }
                    </div>
                    <MenuCardColumnButton
                        onClick={this.handleClickNewProduct}
                        value="Produkt hinzufügen"
                        disabled={newCategory}
                    />
                </Col>

                <Col
                    className="product-form-col flex-column border-right pt-3 h-100"
                    style={{ display: showProductCol ? 'flex' : 'none', overflow: 'auto' }}
                >
                    <CreateEditProductForm
                        locale={locale}
                        new={newProduct}
                        product={selectedProduct}
                        category={selectedCategory}
                        forceClearFormData={forceClearProductForm}
                        optionGroups={merchant.productOptionGroups}
                        onChange={this.handleChangeProductForm}
                        onCreate={this.handleCreateProduct}
                        onUpdate={this.handleUpdateProduct}
                        onDelete={this.handleDeleteProduct}
                    />
                </Col>

                {!showProductCol &&
                    <Col
                        className="border-left d-flex justify-content-center align-items-center"
                        style={{ flexGrow: showCategoryCol ? 1 : 2 }}
                    >
                        {showCategoryCol ?
                            <span>
                                {selectedCategory && products
                                    .filter(product => product.category === selectedCategory._id).length
                                    ? 'Bitte wählen Sie ein Produkt aus.'
                                    : 'Keine Produkte vorhanden.'
                                }
                            </span> :
                            <span>
                                {categories.length
                                    ? 'Bitte wählen Sie eine Kategorie aus.'
                                    : 'Keine Kategorien vorhanden.'
                                }
                            </span>
                        }
                    </Col>
                }

                <DeleteDialog
                    isOpen={isClearCategoryFormDataDialogOpen}
                    onConfirm={this.handleClearCategoryFormData}
                    toggle={this.toggleClearCategoryFormDataDialog}
                    object={{
                        header: 'Änderungen verwerfen',
                        body: 'Nicht gespeicherte Änderungen wieder verwerfen?',
                        confirmButtonText: 'Änderungen verwerfen',
                    }}
                />

                <DeleteDialog
                    isOpen={isClearProductFormDataDialogOpen}
                    onConfirm={this.handleClearProductFormData}
                    toggle={this.toggleClearProductFormDataDialog}
                    object={{
                        header: 'Änderungen verwerfen',
                        body: 'Nicht gespeicherte Änderungen wieder verwerfen?',
                        confirmButtonText: 'Änderungen verwerfen',
                    }}
                />
            </Row>
        );
    }

}

MenuCard.propTypes = {
    locale: PropTypes.string.isRequired,
    merchant: PropTypes.object,
    categories: PropTypes.array,
    products: PropTypes.array,
    createProduct: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    selectCategory: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    selectedCategory: PropTypes.object,
    selectedProduct: PropTypes.object,
    changeCategoryPosition: PropTypes.func.isRequired,
    changeProductPosition: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: selectLocale(state),
        merchant: selectMerchant(state),
        categories: selectCategories(state),
        products: selectProducts(state),
        selectedCategory: selectSelectedCategory(state),
        selectedProduct: selectSelectedProduct(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createProduct: product => dispatch(createProductAction(product)),
        updateProduct: product => dispatch(updateProductAction(product)),
        deleteProduct: product => dispatch(deleteProductAction(product)),
        createCategory: category => dispatch(createCategoryAction(category)),
        updateCategory: category => dispatch(updateCategoryAction(category)),
        deleteCategory: id => dispatch(deleteCategoryAction(id)),
        selectCategory: categoryId => dispatch(selectCategoryAction(categoryId)),
        selectProduct: productId => dispatch(selectProductAction(productId)),
        changeCategoryPosition: (category, position, _version) => (
            dispatch(changeCategoryPositionAction(category, position, _version))
        ),
        changeProductPosition: (product, position, _version) => (
            dispatch(changeProductPositionAction(product, position, _version))
        ),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuCard));
