import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import LoadingIcon from '../../components/LoadingIcon';
import { API_URI } from '../../config';
import {
    authorizeMerchant as authorizeMerchantAction,
    fetchClientId as fetchClientIdAction, fetchMerchantData as fetchMerchantDataAction,
} from '../App/actions';

class StripeActivation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stripeProcessing: false,
        };

        this.openStripeActivationPage = this.openStripeActivationPage.bind(this);
    }

    /**
     * Called when the settings page is loaded after Stripe activation
     * has been called in browser.
     */
    componentDidMount() {
        this.doAuthoriatzion();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.authorizationCode !== this.props.authorizationCode;
    }

    /**
     * Called when the settings props have been updated (code history.location params)
     * when the settings page is opened from the Deeplink plugin.
     */
    componentDidUpdate() {
        if (this.props.authorizationCode) {
            this.doAuthoriatzion();
        }
    }

    /**
     * Opens the Stripe authorization page.
     */
    openStripeActivationPage() {
        this.props.fetchClientId()
            .then(({ clientId }) => {
                const apiUrl = new URL(API_URI);
                apiUrl.pathname = '/api/stripe/oauth-redirect';
                apiUrl.searchParams.set('platform', window.cordova.platformId.toUpperCase());

                const url = new URL('https://dashboard.stripe.com/oauth/authorize');
                url.searchParams.set('response_type', 'code');
                url.searchParams.set('scope', 'read_write');
                url.searchParams.set('client_id', clientId);
                url.searchParams.set('redirect_uri', apiUrl.toString());

                window.location.href = url.toString();
            });
    }

    /**
     * Sends the authorization code to the backend.
     */
    doAuthoriatzion() {
        const {
            fetchMerchantData,
            authorizationCode,
            authorizeMerchant,
            history,
        } = this.props;
        const { stripeProcessing } = this.state;

        if (authorizationCode && !stripeProcessing) {
            this.setState({ stripeProcessing: true });
            authorizeMerchant(authorizationCode)
                .finally(() => {
                    this.setState({ stripeProcessing: false });
                    fetchMerchantData();
                    history.replace('/settings');
                });
        }
    }

    render() {
        const { stripeProcessing } = this.state;
        const { disabled } = this.props;

        return (
            <Button
                className="mr-4"
                size="sm"
                color="secondary"
                disabled={!!disabled || stripeProcessing}
                onClick={this.openStripeActivationPage}
            >
                {stripeProcessing && (
                    <React.Fragment>
                        <LoadingIcon />
                        {' '}
                    </React.Fragment>
                )}
                Stripe aktivieren
            </Button>
        );
    }

}

StripeActivation.propTypes = {
    authorizationCode: PropTypes.string,
    fetchMerchantData: PropTypes.func.isRequired,
    fetchClientId: PropTypes.func.isRequired,
    authorizeMerchant: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMerchantData: () => dispatch(fetchMerchantDataAction()),
        fetchClientId: () => dispatch(fetchClientIdAction()),
        authorizeMerchant: code => dispatch(authorizeMerchantAction(code)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeActivation);
