import { toast } from 'react-toastify';

import { API_URI } from 'config';
import { authorizedRequest } from 'utils';

import {
    BLOCK_CUSTOMER_ERROR,
    BLOCK_CUSTOMER_REQUEST,
    BLOCK_CUSTOMER_SUCCESS,
    GET_CUSTOMER_BLACKLIST_ERROR,
    GET_CUSTOMER_BLACKLIST_REQUEST,
    GET_CUSTOMER_BLACKLIST_SUCCESS,
    UNBLOCK_CUSTOMER_ERROR,
    UNBLOCK_CUSTOMER_REQUEST,
    UNBLOCK_CUSTOMER_SUCCESS,
} from './constants';

/*
 * ACTIONS
 */

const getCustomerBlacklistRequest = () => ({
    type: GET_CUSTOMER_BLACKLIST_REQUEST,
});

const getCustomerBlacklistSuccess = data => ({
    type: GET_CUSTOMER_BLACKLIST_SUCCESS,
    data,
});

const getCustomerBlacklisteError = error => ({
    type: GET_CUSTOMER_BLACKLIST_ERROR,
    error,
});

const blockCustomerRequest = () => ({
    type: BLOCK_CUSTOMER_REQUEST,
});

const blockCustomerSuccess = data => ({
    type: BLOCK_CUSTOMER_SUCCESS,
    data,
});

const blockCustomerError = error => ({
    type: BLOCK_CUSTOMER_ERROR,
    error,
});

const unblockCustomerRequest = () => ({
    type: UNBLOCK_CUSTOMER_REQUEST,
});

const unblockCustomerSuccess = blackListEntryId => ({
    type: UNBLOCK_CUSTOMER_SUCCESS,
    blackListEntryId,
});

const unblockCustomerError = error => ({
    type: UNBLOCK_CUSTOMER_ERROR,
    error,
});

/*
 * DISPATCHERS
 */

export function fetchBlockedCustomers() {

    return dispatch => {
        dispatch(getCustomerBlacklistRequest());

        const url = new URL('/api/merchant/customer-blacklist', API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(json => {
                dispatch(getCustomerBlacklistSuccess(json));
            })
            .catch(error => {
                toast.error(
                    'Fehler beim Laden der blockierten Kunden.',
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(getCustomerBlacklisteError(error));
                console.log(error);
            });
    };
}

export function blockCustomer(customer, reason) {

    const blackListEntry = {
        customer: {
            _id: customer._id,
            phoneNumber: customer.phoneNumber,
        },
        reason,
    };

    return dispatch => {
        dispatch(blockCustomerRequest());

        const url = new URL('/api/merchant/customer-blacklist', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: blackListEntry,
        })
            .then(json => {
                dispatch(blockCustomerSuccess(json));
            })
            .catch(error => {
                dispatch(blockCustomerError(error));
                throw error;
            });
    };
}

export function unblockCustomer(blackListEntry) {

    return dispatch => {
        dispatch(unblockCustomerRequest());

        const url = new URL(`/api/merchant/customer-blacklist/${blackListEntry}`, API_URI).toString();

        return authorizedRequest({ dispatch, url, method: 'DELETE' })
            .then(() => {
                dispatch(unblockCustomerSuccess(blackListEntry));
            })
            .catch(error => {
                toast.error(
                    'Fehler beim Aufheben der Kundenblockierung.',
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(unblockCustomerError(error));
                console.log(error);
            });
    };

}
