import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

import FormInput from 'components/FormInput';

import { CANCEL_ENUM } from 'utils';

import './styles.css';

const reasonsArray = Object
    .keys(CANCEL_ENUM)
    .reduce((prev, cur) => ({ ...prev, [cur]: false }), {});

function CancelDialog({
    open,
    onClose,
    onConfirm,
    onCancel,
}) {
    const [reasons, setReasons] = useState(reasonsArray);
    const [cancelComment, setCancelComment] = useState('');

    const handleChangeCheckbox = event => {
        const { name } = event.target;
        setReasons(r => ({ ...r, [name]: !r[name] }));
    };

    const handleChangeCancelComment = event => {
        setCancelComment(event.target.value);
    };

    const handleConfirm = () => {
        const selectedReasons = Object
            .keys(reasons)
            .filter(key => reasons[key] === true);
        onConfirm(selectedReasons, cancelComment);
    };

    const checkboxes = Object.keys(CANCEL_ENUM).map(i => (
        <FormGroup check key={`cancel-cb-${i}`}>
            <Label htmlFor={i} className="form-checkbox-label" check>
                <Input
                    type="checkbox"
                    id={i}
                    name={i}
                    checked={reasons[i]}
                    onChange={handleChangeCheckbox}
                />
                {CANCEL_ENUM[i]}
            </Label>
        </FormGroup>
    ));

    return (
        <Modal
            isOpen={open}
            toggle={onClose}
        >
            <ModalHeader toggle={onClose}>
                Bestellung stornieren
            </ModalHeader>
            <ModalBody>
                {checkboxes}
                <FormInput
                    type="textarea"
                    label="Ergänzender Kommentar:"
                    id="canceledComment"
                    valid
                    formFeedBack="Geben Sie eine Begründung an."
                    value={cancelComment}
                    onChange={handleChangeCancelComment}
                />
            </ModalBody>
            <ModalFooter>
                <div style={{ width: '100%' }} className="clearfix">
                    <div className="float-right">
                        <Button
                            outline
                            onClick={onCancel}
                        >
                            Abbrechen
                        </Button>
                        {' '}
                        <Button
                            color="danger"
                            onClick={handleConfirm}
                        >
                            Bestellung stornieren
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}

CancelDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default CancelDialog;
