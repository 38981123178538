import logo from 'services/logo.png';

/**
 * Service for notifications using the Web Notifications API.
 */
class BrowserNotification {

    /**
     * Initializes the Notification service.
     */
    static init() {
        if (BrowserNotification.showNotification()
            && Notification.permission !== 'granted') {
            this.requestPermission()
                .then(() => {
                    console.log('Browser Notification: Service initialized');
                });
        }
    }

    /**
     * Request user for browser notifications
     * @return {Promise<NotificationPermission>}
     */
    static requestPermission() {
        // fix for Safari (see #276)
        return Promise.resolve(Notification.requestPermission())
            .then(permission => {
                if (permission === 'granted') {
                    console.log('Browser Notification: User accepted notifications');
                } else {
                    console.log('Browser Notification: User declined notifications');
                }
                return permission;
            });
    }

    static showNotification() {
        return 'Notification' in window
            && window.cordova
            && window.cordova.platformId === 'browser';
    }

    /**
     * Request browser notifications and display browser notification if granted.
     * @param {string} title The title to show
     * @param {string} body The message to show
     * @param {string} img The img to show as notification icon
     */
    static notify(title, body, img = logo) {
        if (!BrowserNotification.showNotification()) {
            return;
        }

        const openNotification = () => new Notification(title, { body, icon: img, silent: true });

        if (Notification.permission !== 'granted') {
            this.requestPermission().then(permission => {
                if (permission === 'granted') {
                    openNotification();
                }
            });
        } else {
            openNotification();
        }
    }

}

export default BrowserNotification;
