export const CORDOVA_INIT_DONE = 'App/CORDOVA_INIT_DONE';
export const UPDATE_CONNECTION_INFO = 'App/UPDATE_CONNECTION_INFO';

export const PUSH_NOTIFICATION_RECEIVE = 'App/PUSH_NOTIFICATON_RECEIVE';
export const PUSH_NOTIFICATION_CLEAR = 'App/PUSH_NOTIFICATON_CLEAR';

export const FETCH_MERCHANT_DATA_REQUEST = 'App/FETCH_MERCHANT_DATA_REQUEST';
export const FETCH_MERCHANT_DATA_SUCCESS = 'App/FETCH_MERCHANT_DATA_SUCCESS';
export const FETCH_MERCHANT_DATA_ERROR = 'App/FETCH_MERCHANT_DATA_ERROR';

export const UPDATE_MERCHANT_DATA_REQUEST = 'App/UPDATE_MERCHANT_DATA_REQUEST';
export const UPDATE_MERCHANT_DATA_SUCCESS = 'App/UPDATE_MERCHANT_DATA_SUCCESS';
export const UPDATE_MERCHANT_DATA_ERROR = 'App/UPDATE_MERCHANT_DATA_ERROR';

export const VERIFY_TOKEN_REQUEST = 'App/VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'App/VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'App/VERIFY_TOKEN_ERROR';

export const LOAD_API_VERSION_REQUEST = 'App/LOAD_API_VERSION_REQUEST';
export const LOAD_API_VERSION_SUCCESS = 'App/LOAD_API_VERSION_SUCCESS';
export const LOAD_API_VERSION_ERROR = 'App/LOAD_API_VERSION_ERROR';

export const CREATE_CATEGORY_REQUEST = 'Menu/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'Menu/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'Menu/CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'Menu/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'Menu/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'Menu/UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = 'Menu/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'Menu/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'Menu/DELETE_CATEGORY_ERROR';

export const CREATE_PRODUCT_REQUEST = 'Menu/CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'Menu/CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'Menu/CREATE_PRODUCT_ERROR';

export const UPDATE_PRODUCT_REQUEST = 'Menu/UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'Menu/UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'Menu/UPDATE_PRODUCT_ERROR';

export const DELETE_PRODUCT_REQUEST = 'Menu/DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'Menu/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'Menu/DELETE_PRODUCT_ERROR';

export const CHANGE_PRODUCT_POSITION_REQUEST = 'Menu/CHANGE_PRODUCT_POSITION_REQUEST';
export const CHANGE_PRODUCT_POSITION_SUCCESS = 'Menu/CHANGE_PRODUCT_POSITION_SUCCESS';
export const CHANGE_PRODUCT_POSITION_ERROR = 'Menu/CHANGE_PRODUCT_POSITION_ERROR';

export const CHANGE_CATEGORY_POSITION_REQUEST = 'Menu/CHANGE_CATEGORY_POSITION_REQUEST';
export const CHANGE_CATEGORY_POSITION_SUCCESS = 'Menu/CHANGE_CATEGORY_POSITION_SUCCESS';
export const CHANGE_CATEGORY_POSITION_ERROR = 'Menu/CHANGE_CATEGORY_POSITION_ERROR';

export const CREATE_PRODUCT_OPTION_GROUP_REQUEST = 'Menu/CREATE_PRODUCT_OPTION_GROUP_REQUEST';
export const CREATE_PRODUCT_OPTION_GROUP_SUCCESS = 'Menu/CREATE_PRODUCT_OPTION_GROUP_SUCCESS';
export const CREATE_PRODUCT_OPTION_GROUP_ERROR = 'Menu/CREATE_PRODUCT_OPTION_GROUP_ERROR';

export const UPDATE_PRODUCT_OPTION_GROUP_REQUEST = 'Menu/UPDATE_PRODUCT_OPTION_GROUP_REQUEST';
export const UPDATE_PRODUCT_OPTION_GROUP_SUCCESS = 'Menu/UPDATE_PRODUCT_OPTION_GROUP_SUCCESS';
export const UPDATE_PRODUCT_OPTION_GROUP_ERROR = 'Menu/UPDATE_PRODUCT_OPTION_GROUP_ERROR';

export const DELETE_PRODUCT_OPTION_GROUP_REQUEST = 'Menu/DELETE_PRODUCT_OPTION_GROUP_REQUEST';
export const DELETE_PRODUCT_OPTION_GROUP_SUCCESS = 'Menu/DELETE_PRODUCT_OPTION_GROUP_SUCCESS';
export const DELETE_PRODUCT_OPTION_GROUP_ERROR = 'Menu/DELETE_PRODUCT_OPTION_GROUP_ERROR';

export const CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST = 'Menu/CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST';
export const CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS = 'Menu/CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS';
export const CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR = 'Menu/CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR';

export const CREATE_PRODUCT_OPTION_REQUEST = 'Menu/CREATE_PRODUCT_OPTION_REQUEST';
export const CREATE_PRODUCT_OPTION_SUCCESS = 'Menu/CREATE_PRODUCT_OPTION_SUCCESS';
export const CREATE_PRODUCT_OPTION_ERROR = 'Menu/CREATE_PRODUCT_OPTION_ERROR';

export const UPDATE_PRODUCT_OPTION_REQUEST = 'Menu/UPDATE_PRODUCT_OPTION_REQUEST';
export const UPDATE_PRODUCT_OPTION_SUCCESS = 'Menu/UPDATE_PRODUCT_OPTION_SUCCESS';
export const UPDATE_PRODUCT_OPTION_ERROR = 'Menu/UPDATE_PRODUCT_OPTION_ERROR';

export const DELETE_PRODUCT_OPTION_REQUEST = 'Menu/DELETE_PRODUCT_OPTION_REQUEST';
export const DELETE_PRODUCT_OPTION_SUCCESS = 'Menu/DELETE_PRODUCT_OPTION_SUCCESS';
export const DELETE_PRODUCT_OPTION_ERROR = 'Menu/DELETE_PRODUCT_OPTION_ERROR';

export const CHANGE_PRODUCT_OPTION_POSITION_REQUEST = 'Menu/CHANGE_PRODUCT_OPTION_POSITION_REQUEST';
export const CHANGE_PRODUCT_OPTION_POSITION_SUCCESS = 'Menu/CHANGE_PRODUCT_OPTION_POSITION_SUCCESS';
export const CHANGE_PRODUCT_OPTION_POSITION_ERROR = 'Menu/CHANGE_PRODUCT_OPTION_POSITION_ERROR';

export const UPDATE_DEVICE_INFORMATION_REQUEST = 'App/UPDATE_DEVICE_INFORMATION_REQUEST';
export const UPDATE_DEVICE_INFORMATION_SUCCESS = 'App/UPDATE_DEVICE_INFORMATION_SUCCESS';
export const UPDATE_DEVICE_INFORMATION_ERROR = 'App/UPDATE_DEVICE_INFORMATION_ERROR';

export const FETCH_CLIENT_ID_REQUEST = 'App/FETCH_CLIENT_ID_REQUEST';
export const FETCH_CLIENT_ID_SUCCESS = 'App/FETCH_CLIENT_ID_SUCCESS';
export const FETCH_CLIENT_ID_ERROR = 'App/FETCH_CLIENT_ID_ERROR';

export const AUTHORIZE_MERCHANT_REQUEST = 'App/AUTHORIZE_MERCHANT_REQUEST';
export const AUTHORIZE_MERCHANT_SUCCESS = 'App/AUTHORIZE_MERCHANT_SUCCESS';
export const AUTHORIZE_MERCHANT_ERROR = 'App/AUTHORIZE_MERCHANT_ERROR';
