import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoginCard from 'components/LoginCard';
import { login as loginAction } from './actions';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(username, password, queueMonitor) {
        this.props.login(username, password, queueMonitor);
    }

    render() {
        const { loggedIn, queueMonitor } = this.props;

        if (loggedIn) {
            if (queueMonitor) {
                return <Redirect to="/queue-monitor" />;
            }

            return <Redirect to="/monitor" />;
        }

        return (
            <div>
                <LoginCard onSubmit={this.onSubmit} />
            </div>
        );
    }

}

Login.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    queueMonitor: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
        queueMonitor: state.login.queueMonitor,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password, queueMonitor) => dispatch(loginAction(username, password, queueMonitor)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
