/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import { polyfill } from 'mobile-drag-drop';

import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import configureStore from 'store';
import { translationMessages } from 'i18n';

// Bootstrap, font-awesome, react-virtualized styles are imported into main.scss!
import './main.scss';
import './favicon.ico';
import Toast from 'containers/Toast';

// add shim for Object.fromEntries
import { shim as fromEntriesShim } from 'object.fromentries';

if (!Object.fromEntries) {
    fromEntriesShim();
}

const initialState = {};
const history = createHashHistory();

const store = configureStore(initialState, history);

polyfill({
    // drag and drop options (e.g. add scroll behaviour)
});

// Test via a getter in the options object to see if the passive property is accessed
let supportsPassive = false;
try {
    const opts = Object.defineProperty({}, 'passive', {
        get() {
            supportsPassive = true;
            return true;
        },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
} catch (e) {
    // do nothing
}

if (supportsPassive) {
    console.log('Browser supports passive event listeners.');
}

// this is necessary for making mobile-drag-drop work for iOS Safari 10.x and higher
window.addEventListener('touchmove', () => { }, supportsPassive ? { passive: false } : false);

const platformId = window.cordova ? window.cordova.platformId : '';
document.body.classList.add(platformId);

const render = messages => {
    ReactDOM.render(
        <Provider store={store}>
            <LanguageProvider messages={messages}>
                <ConnectedRouter history={history}>
                    <Toast />
                    <App />
                </ConnectedRouter>
            </LanguageProvider>
        </Provider>,
        document.getElementById('app')
    );
};

if (module.hot) {
    module.hot.accept(['./i18n', './containers/App'], () => {
        ReactDOM.unmountComponentAtNode(document.getElementById('app'));
        render(translationMessages);
    });
}

render(translationMessages);
