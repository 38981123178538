import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import CheckOpenStateDialog from 'components/CheckOpenStateDialog';

import StateDialog from 'containers/StateDialog';

import { fetchQueueForToday as fetchQueueForTodayAction } from 'containers/Monitor/action';

import {
    isOpen,
    formatHours,
    getOpeningHoursForToday,
    getNextOpeningHoursForToday,
} from 'utils';

import {
    setMerchantState as setMerchantStateAction,
    isMerchantActivated as isMerchantActivatedAction,
    checkOpenState as checkOpenStateAction,
} from './action';

function shouldOpenCheckOpenDialog(props, state) {
    return props.available
        && props.openingHours
        && props.shouldCheckOpenState === true
        && state.checkOpenStateDialogOpen === false;
}

class Online extends React.Component {

    static getDerivedStateFromProps(props, state) {
        const newState = { availableOldValue: props.available };

        if (props.available !== state.availableOldValue && props.available !== 'CLOSED') {
            props.fetchQueueForToday();
        }

        if (shouldOpenCheckOpenDialog(props, state)) {
            props.checkOpenState(false);
            if (props.available === 'CLOSED'
                && (getNextOpeningHoursForToday(props.openingHours) || isOpen(props.openingHours))) {
                newState.checkOpenStateDialogOpen = true;
            }
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            checkOpenStateDialogOpen: false,
            // eslint-disable-next-line react/no-unused-state
            availableOldValue: null,
        };

        this.onConfirmState = this.onConfirmState.bind(this);
        this.openStateDialog = this.openStateDialog.bind(this);
        this.onDialogCancel = this.onDialogCancel.bind(this);
        this.handleCheckOpenStateDialogClose = this.handleCheckOpenStateDialogClose.bind(this);
        this.handleCheckOpenStateDialogConfirm = this.handleCheckOpenStateDialogConfirm.bind(this);

        this.onlineInterval = null;
        this.renderInterval = null;
    }

    componentDidMount() {
        this.onlineInterval = setInterval(this.props.isMerchantActivated, 30000);
        this.renderInterval = setInterval(() => this.forceUpdate(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.onlineInterval);
        clearInterval(this.renderInterval);
    }

    onDialogCancel() {
        this.setState({ dialogOpen: false });
    }

    onConfirmState(select) {
        this.setState({ dialogOpen: false });
        this.props.setMerchantState(select);
    }

    buttonColor() {
        if (!this.props.isStreamOpen) {
            return 'secondary';
        }

        if (this.props.available === 'MANUAL') {
            return 'success';
        } else if (this.props.available === 'CLOSED') {
            return 'danger';
        } else {
            if (isOpen(this.props.openingHours)) {
                return 'success';
            }
            return 'danger';
        }
    }

    buttonText() {
        if (!this.props.isStreamOpen) {
            return 'Keine Verbindung';
        }

        if (this.props.available === 'MANUAL') {
            return 'Geöffnet (manuell)';
        } else if (this.props.available === 'CLOSED') {
            return 'Geschlossen (manuell)';
        } else {
            if (isOpen(this.props.openingHours)) {
                return formatHours(getOpeningHoursForToday(this.props.openingHours));
            }
            return 'Geschlossen';
        }
    }

    openStateDialog() {
        this.setState({ dialogOpen: true });
    }

    handleCheckOpenStateDialogClose() {
        this.setState({ checkOpenStateDialogOpen: false });
    }

    handleCheckOpenStateDialogConfirm() {
        this.props.setMerchantState('OPENING_HOURS');
        this.setState({ checkOpenStateDialogOpen: false });
    }

    render() {
        const {
            available,
            isStreamOpen,
            openingHours,
        } = this.props;
        const {
            dialogOpen,
            checkOpenStateDialogOpen,
        } = this.state;

        return (
            <div>
                <Button
                    disabled={!isStreamOpen}
                    style={this.props.style}
                    color={this.buttonColor()}
                    size="sm"
                    onClick={this.openStateDialog}
                    data-testid="available-button"
                >
                    {this.props.available === 'OPENING_HOURS' ?
                        <i className="fa fa-clock-o">&nbsp;</i> : ''}
                    {this.buttonText()}
                </Button>
                <StateDialog
                    open={dialogOpen}
                    title=" Online Bestellstatus ändern"
                    available={available}
                    onConfirm={this.onConfirmState}
                    onCancel={this.onDialogCancel}
                />
                <CheckOpenStateDialog
                    open={checkOpenStateDialogOpen}
                    isCurrentlyOpen={isOpen(openingHours)}
                    nextOpeningHours={getNextOpeningHoursForToday(openingHours)}
                    onClose={this.handleCheckOpenStateDialogClose}
                    onConfirm={this.handleCheckOpenStateDialogConfirm}
                />
            </div>
        );
    }

}

Online.propTypes = {
    setMerchantState: PropTypes.func.isRequired,
    available: PropTypes.string,
    openingHours: PropTypes.array,
    style: PropTypes.object,
    isStreamOpen: PropTypes.bool.isRequired,
    isMerchantActivated: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    fetchQueueForToday: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    shouldCheckOpenState: PropTypes.bool.isRequired,
    checkOpenState: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        merchant: state.app.merchant,
        available: state.online.available,
        openingHours: state.app.merchant && state.app.merchant.openingHours,
        isStreamOpen: state.queue.isStreamOpen,
        shouldCheckOpenState: state.online.shouldCheckOpenState,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setMerchantState: state => dispatch(setMerchantStateAction(state)),
        isMerchantActivated: () => dispatch(isMerchantActivatedAction()),
        fetchQueueForToday: () => dispatch(fetchQueueForTodayAction()),
        checkOpenState: check => dispatch(checkOpenStateAction(check)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Online);
