import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

function ConfirmDialog({
    open,
    title,
    message,
    confirmButtonText,
    onConfirm,
    onCancel,
    confirmColor,
}) {
    const color = confirmColor || 'secondary';

    return (
        <div>
            <Modal
                isOpen={open}
                toggle={onCancel}
            >
                <ModalHeader toggle={onCancel}>{title}</ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button outline onClick={onCancel}>Abbrechen</Button>{' '}
                    <Button color={color} onClick={onConfirm}>
                        {confirmButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

ConfirmDialog.propTypes = {
    confirmColor: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmDialog;
