import { TOGGLE_NEW_ORDER_POPUP } from './constants';

const initialState = {
    isVisible: false,
};

const newOrderPopUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NEW_ORDER_POPUP:
            return {
                ...state,
                isVisible: action.isVisible,
            };
        default:
            return state;
    }
};

export default newOrderPopUpReducer;
