import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import { isOpen, getOpeningHoursForToday, formatFromTo } from '../../utils';

import './styles.css';

class StateDialog extends React.Component {

    constructor(props) {
        super(props);

        this.setManualState = this.setManualState.bind(this);
        this.setOpeningHoursState = this.setOpeningHoursState.bind(this);
        this.setClosedState = this.setClosedState.bind(this);
    }

    setManualState() {
        this.props.onConfirm('MANUAL');
    }

    setOpeningHoursState() {
        this.props.onConfirm('OPENING_HOURS');
    }

    setClosedState() {
        this.props.onConfirm('CLOSED');
    }

    buttonColor() {

        if (isOpen(this.props.openingHours)) {
            return 'success';
        }
        return 'danger';
    }

    openingHours() {
        const hours = getOpeningHoursForToday(this.props.openingHours);
        return hours.map((h, id) => {
            const hour = formatFromTo(h.from, h.to);
            return { id, hour };
        });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.open}
                    toggle={this.props.onCancel}
                    size="lg"
                >
                    <ModalHeader toggle={this.props.onCancel}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        <h5>
                            Automatisch nach Öffnungszeiten (Default)
                        </h5>
                        <p className="small">
                            Bestellungen können anhand der Öffnungszeiten aufgegeben werden.
                            Ihr Restaurant wird automatisch entsprechend der Öffnungszeiten geöffnet
                            und geschlossen. Am Beginn des Tages müssen Sie einmalig den Start bestätigen.
                        </p>
                        <strong className="">
                            Öffnungszeiten heute:
                        </strong>

                        <ul className="state-dialog-hours-list">
                            {this.props.openingHours && this.openingHours().length > 0 ?
                                (this.openingHours()).map(h => (<li key={h.id}>{h.hour}</li>))
                                : 'geschlossen'
                            }
                        </ul>
                        <Button
                            className="button-wide"
                            color={this.props.available === 'OPENING_HOURS' ? 'secondary' : this.buttonColor()}
                            disabled={this.props.available === 'OPENING_HOURS'}
                            onClick={this.setOpeningHoursState}
                        >
                            {this.props.available === 'OPENING_HOURS' ? <i className="fa fa-check" /> : ''}
                            {this.props.available === 'OPENING_HOURS' ? ' Ausgewählt' : 'Automatikmodus aktivieren'}
                        </Button>{' '}
                        <span>
                            {isOpen(this.props.openingHours) ? '(Aktuell geöffnet)' : '(Aktuell geschlossen)'}
                        </span>
                        <hr />
                        <h5>
                            Restaurant manuell öffnen
                        </h5>
                        <p className="small">
                            Ihr Restaurant wird für Kunden als geöffnet angezeigt.
                            Bestellungen können aufgegeben werden, bis Sie das Restaurant selbst schließen.
                        </p>
                        <Button
                            className="button-wide"
                            color={this.props.available === 'MANUAL' ? 'secondary' : 'success'}
                            disabled={this.props.available === 'MANUAL'}
                            onClick={this.setManualState}
                        >
                            {this.props.available === 'MANUAL' ? <i className="fa fa-check" /> : ''}
                            {this.props.available === 'MANUAL' ? 'Ausgewählt' : 'Restaurant manuell öffnen' }
                        </Button>{' '}
                        <hr />
                        <h5>
                            Restaurant manuell schließen
                        </h5>
                        <p className="small">
                            Ihr Restaurant wird für Kunden als geschlossen angezeigt.
                            Bestellungen können daher nicht aufgegeben werden, bis Sie das Restaurant selbst öffnen.
                        </p>
                        <Button
                            className=""
                            color={this.props.available === 'CLOSED' ? 'secondary' : 'danger'}
                            disabled={this.props.available === 'CLOSED'}
                            onClick={this.setClosedState}
                        >
                            {this.props.available === 'CLOSED' ? <i className="fa fa-check" /> : ''}
                            {this.props.available === 'CLOSED' ? 'Ausgewählt' : 'Restaurant manuell schließen' }
                        </Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}

StateDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    available: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    openingHours: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        openingHours: state.app.merchant && state.app.merchant.openingHours,
    };
}

export default connect(mapStateToProps)(StateDialog);
