import React from 'react';
import {
    Col,
    Container,
    Row,
    Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { includeValueBetween } from 'utils';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import {
    fetchBlockedCustomers as fetchBlockedCustomersAction,
    unblockCustomer as unblockCustomerAction,
} from './action';

import './styles.css';

class CustomerBlackList extends React.Component {

    componentDidMount() {
        this.props.fetchBlockedCustomers();
    }

    render() {
        return (
            <div className="customer-blacklist-page">
                <Container>
                    <h3 className="mt-4">Blockierte Kunden</h3>
                    <Row className="mt-4">
                        <Col>
                            <span className="font-weight-bold">
                                Kunde
                            </span>
                        </Col>
                        <Col>
                            <span className="font-weight-bold">
                                Aktionen
                            </span>
                        </Col>
                    </Row>
                    <hr />
                    {!this.props.customerBlackList.length && (
                        <Row className="mt-3">
                            <Col>
                                Es wurden bisher keine Kunden blockiert.
                            </Col>
                        </Row>
                    )}
                    {includeValueBetween(this.props.customerBlackList.map(entry => (
                        <Row key={entry._id}>
                            <Col className="align-self-center">
                                <FormattedPhoneNumber phoneNumber={entry.customer.phoneNumber} />
                            </Col>
                            <Col>
                                <Button
                                    onClick={() => this.props.unblockCustomer(entry._id).then(() => {
                                        toast.success(
                                            'Blockierung aufgehoben',
                                            { position: toast.POSITION.TOP_CENTER }
                                        );
                                    })}
                                >
                                    Blockierung aufheben
                                </Button>
                            </Col>
                        </Row>
                    )), <hr />)}
                </Container>
            </div>
        );
    }

}

CustomerBlackList.propTypes = {
    customerBlackList: PropTypes.array.isRequired,
    fetchBlockedCustomers: PropTypes.func.isRequired,
    unblockCustomer: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        customerBlackList: state.customerBlackList.blockedCustomers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fetchBlockedCustomers: () => dispatch(fetchBlockedCustomersAction()),
        unblockCustomer: blackListEntryId => dispatch(unblockCustomerAction(blackListEntryId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerBlackList));
