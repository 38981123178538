import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import './styles.css';
import { USER_TYPE_MAP } from '../../utils';

class LaneHeader extends React.Component {

    constructor(props) {
        super(props);

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.className !== nextProps.className
            || this.props.numberOfOrders !== nextProps.numberOfOrders;
    }

    onDragEnter(event) {
        event.preventDefault();
        if (this.props.onDragEnter) this.props.onDragEnter();
    }

    onDrop(event) {
        const payload = JSON.parse(event.dataTransfer.getData('text'));
        if (payload.state === 'CANCELED' && payload.canceledBy === USER_TYPE_MAP.CUSTOMER) {
            toast.error(
                'Der Status der Bestellung konnte nicht geändert werden, die Bestellung wurde vom Kunden storniert.',
                { position: toast.POSITION.TOP_CENTER }
            );
        } else {
            this.props.moveOrderToAnotherLane(payload.id, payload.state);
        }

        this.props.onDrop();
    }

    render() {
        return (
            <div
                role="presentation"
                className={`LaneHeader border-bottom pt-2 ${this.props.className}`}
                onClick={this.props.onClick}
                onDragEnter={this.onDragEnter}
                onDragOver={(ev => ev.preventDefault())}
                onDrop={this.onDrop}
            >
                <div className="LaneHeader-number float-right mr-2">
                    <h5>{this.props.numberOfOrders}</h5>
                </div>
                <div className="LaneHeader-title ml-2">
                    <h5>{this.props.headline}</h5>
                </div>
            </div>
        );
    }

}

LaneHeader.propTypes = {
    headline: PropTypes.string.isRequired,
    numberOfOrders: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    onDragEnter: PropTypes.func,
    moveOrderToAnotherLane: PropTypes.func.isRequired,
    className: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
};


export default LaneHeader;
