import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT_SUCCESS,
} from './constants';

const initialState = {
    loading: false,
    loggedIn: false,
    queueMonitor: false,
    result: null,
    error: null,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                loggedIn: false,
                result: null,
                error: null,
                queueMonitor: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                result: action.result,
                queueMonitor: action.queueMonitor,
                error: null,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                result: null,
                error: action.error,
                queueMonitor: false,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                result: null,
                error: null,
                queueMonitor: false,
            };

        default:
            return state;
    }
};

export default loginReducer;
