import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConnectionErrorBox from 'components/ConnectionErrorBox';
import UpgradeScreen from 'components/UpgradeScreen';
import Routes from 'routes';

import AudioPlayer from 'containers/AudioPlayer';
import Header from 'containers/Header';
import ErrorBoundary from 'containers/ErrorBoundary';
import NewOrderPopup from 'containers/NewOrderPopup';

import { API_VERSION } from 'config';
import Sound from 'services/Sound';

import { initCordova as initCordovaAction } from './actions';

class App extends Component {

    constructor(props) {
        super(props);

        this.handleGlobalListeners = this.handleGlobalListeners.bind(this);

        document.addEventListener('click', this.handleGlobalListeners);
        document.addEventListener('dragstart', this.handleGlobalListeners);
    }

    componentDidMount() {
        const {
            history,
            initCordova,
        } = this.props;

        initCordova(history);
    }

    handleGlobalListeners() {
        if (this.props.isPlaying) {
            Sound.stop();
        }
    }

    render() {
        const { StatusBar } = window;
        const {
            loggedIn,
            isConnected,
            cordovaInitDone,
            apiVersion,
            queueMonitor,
        } = this.props;

        const isHeaderVisible = loggedIn && !queueMonitor;
        let content = '';

        if (!cordovaInitDone) {
            return <div />;
        }

        // the webview initially displays the launch image
        // set the background color of the webview to white opaque once this component is rendered
        StatusBar.setWebViewOpaque();

        if (!isConnected) {
            content = <ConnectionErrorBox />;
        } else if (apiVersion && apiVersion !== API_VERSION) {
            content = <UpgradeScreen />;
        } else {
            content = <Routes isLoggedIn={loggedIn} showQueueMonitor={queueMonitor} />;
        }

        return (
            <ErrorBoundary>
                <div className="App h-100">
                    {isHeaderVisible && <Header />}
                    {content}
                    <AudioPlayer />
                    <NewOrderPopup />
                </div>
            </ErrorBoundary>
        );
    }

}

App.propTypes = {
    isPlaying: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    isConnected: PropTypes.bool.isRequired,
    initCordova: PropTypes.func.isRequired,
    cordovaInitDone: PropTypes.bool,
    apiVersion: PropTypes.number,
    queueMonitor: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        isPlaying: state.audioPlayer.isPlaying,
        loggedIn: state.login.loggedIn,
        isConnected: state.app.isConnected,
        cordovaInitDone: state.app.cordovaInitDone,
        apiVersion: state.app.apiVersion,
        queueMonitor: state.login.queueMonitor,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        initCordova: history => dispatch(initCordovaAction(history)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
