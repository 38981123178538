import React from 'react';
import PropTypes from 'prop-types';

function formatPrice(value, locale) {
    try {
        // pattern "0.00"
        return (Math.trunc(value) / 100).toLocaleString(locale, { minimumFractionDigits: 2 });
    } catch (e) {
        return value;
    }
}

/**
 * Component for visualization of price
 * @param {number} price - price in cent
 */
function Price({ price, locale, tag: Tag = 'small' }) {

    return (
        <Tag className="text-nowrap">
            {formatPrice(price, locale)} €
        </Tag>
    );
}

Price.propTypes = {
    price: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    tag: PropTypes.string,
};

export default Price;
