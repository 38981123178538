import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LaneHeader from '../../components/LaneHeader';
import LaneBody from '../../components/LaneBody';
import { changeOrderState as changeOrderStateAction } from './action';
import { fetchBlockedCustomers as fetchBlockedCustomersAction } from '../CustomerBlackList/action';

class Monitor extends Component {

    constructor(props) {
        super(props);

        this.setVisibilityOfCollapsibleLaneHeaders = this.setVisibilityOfCollapsibleLaneHeaders.bind(this);
        this.setDragAndDropFeedback = this.setDragAndDropFeedback.bind(this);
        this.toggleLaneForDoneOrders = this.toggleLaneForDoneOrders.bind(this);
        this.toggleLaneForDeliveredOrders = this.toggleLaneForDeliveredOrders.bind(this);
        this.toggleLaneForCanceledOrders = this.toggleLaneForCanceledOrders.bind(this);
        this.moveOrderToAnotherLane = this.moveOrderToAnotherLane.bind(this);
        this.clearDropHighlighting = this.clearDropHighlighting.bind(this);

        this.state = {
            isLaneForDoneOrdersOpen: true,
            isLaneForDeliveredOrdersOpen: false,
            isLaneForCanceledOrdersOpen: false,

            isLaneForNewOrdersHighlighted: false,
            isLaneForInPreparationOrdersHighlighted: false,
            isLaneForDoneOrdersHighlighted: false,
            isLaneForDeliveredOrdersHighlighted: false,
            isLaneForCanceledOrdersHighlighted: false,
        };

        props.fetchBlockedCustomers();
    }

    setVisibilityOfCollapsibleLaneHeaders(showDoneOrders, showDeliveredOrders, showCanceledOrders) {
        this.setState({
            isLaneForDoneOrdersOpen: showDoneOrders,
            isLaneForDeliveredOrdersOpen: showDeliveredOrders,
            isLaneForCanceledOrdersOpen: showCanceledOrders,
        });
    }

    setDragAndDropFeedback({
        isLaneForNewOrdersHighlighted = false,
        isLaneForInPreparationOrdersHighlighted = false,
        isLaneForDoneOrdersHighlighted = false,
        isLaneForDeliveredOrdersHighlighted = false,
        isLaneForCanceledOrdersHighlighted = false,
    }) {
        this.setState({
            isLaneForNewOrdersHighlighted,
            isLaneForInPreparationOrdersHighlighted,
            isLaneForDoneOrdersHighlighted,
            isLaneForDeliveredOrdersHighlighted,
            isLaneForCanceledOrdersHighlighted,
        });
    }

    toggleLaneForDoneOrders(event) {
        event.preventDefault();
        this.setVisibilityOfCollapsibleLaneHeaders(true, false, false);
    }

    toggleLaneForDeliveredOrders(event) {
        event.preventDefault();
        this.setVisibilityOfCollapsibleLaneHeaders(false, true, false);
    }

    toggleLaneForCanceledOrders(event) {
        event.preventDefault();
        this.setVisibilityOfCollapsibleLaneHeaders(false, false, true);
    }

    moveOrderToAnotherLane(state) {
        return (orderId, oldState) => {
            if (state !== oldState) {
                this.props.changeOrderState(orderId, state);
            }
        };
    }

    clearDropHighlighting() {
        this.setState({
            isLaneForNewOrdersHighlighted: false,
            isLaneForInPreparationOrdersHighlighted: false,
            isLaneForDoneOrdersHighlighted: false,
            isLaneForDeliveredOrdersHighlighted: false,
            isLaneForCanceledOrdersHighlighted: false,
        });
    }

    /* eslint-disable max-len */
    render() {
        return (
            <div
                ref={node => { this.node = node; }}
                className="row no-gutters h-100"
                style={{ width: `${100}%` }}
            >
                <div
                    className="col border-right Column"
                    onDragEnter={() => this.setDragAndDropFeedback({
                        isLaneForNewOrdersHighlighted: true,
                    })}
                >
                    <LaneHeader
                        className={`LaneHeader-openOrders ${this.state.isLaneForNewOrdersHighlighted && 'LaneHeader-highlightOnDragOver'}`}
                        headline="Offene Bestellungen"
                        numberOfOrders={this.props.openOrders.length}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('OPEN')}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneBody
                        className={`${this.state.isLaneForNewOrdersHighlighted && 'LaneBody-highlightOnDragOver'}`}
                        orderItems={this.props.openOrders}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('OPEN')}
                        onDrop={this.clearDropHighlighting}
                    />
                </div>
                <div
                    className="col border-left border-right Column"
                    onDragEnter={() => this.setDragAndDropFeedback({
                        isLaneForInPreparationOrdersHighlighted: true,
                    })}
                >
                    <LaneHeader
                        className={`LaneHeader-ordersInProgress  ${this.state.isLaneForInPreparationOrdersHighlighted && 'LaneHeader-highlightOnDragOver'}`}
                        headline="In Zubereitung"
                        numberOfOrders={this.props.inPreparationOrders.length}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('IN_PREPARATION')}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneBody
                        className={`${this.state.isLaneForInPreparationOrdersHighlighted && 'LaneBody-highlightOnDragOver'}`}
                        orderItems={this.props.inPreparationOrders}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('IN_PREPARATION')}
                        onDrop={this.clearDropHighlighting}
                    />
                </div>
                <div className="col border-left Column">
                    <LaneHeader
                        className={`LaneHeader-readyOrders ${this.state.isLaneForDoneOrdersHighlighted && 'LaneHeader-highlightOnDragOver'}`}
                        headline="Abholbereit"
                        numberOfOrders={this.props.preparedOrders.length}
                        onClick={this.toggleLaneForDoneOrders}
                        onDragEnter={() => {
                            this.setVisibilityOfCollapsibleLaneHeaders(true, false, false);
                            this.setDragAndDropFeedback({
                                isLaneForDoneOrdersHighlighted: true,
                            });
                        }}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('PREPARED')}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneBody
                        className={`${this.state.isLaneForDoneOrdersHighlighted && 'LaneBody-highlightOnDragOver'} stackable-lane-body`}
                        isOpen={this.state.isLaneForDoneOrdersOpen}
                        orderItems={this.props.preparedOrders}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('PREPARED')}
                        onDragEnter={() => this.setDragAndDropFeedback({
                            isLaneForDoneOrdersHighlighted: true,
                        })}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneHeader
                        className={`LaneHeader-pickedOrders ${this.state.isLaneForDeliveredOrdersHighlighted && 'LaneHeader-highlightOnDragOver'}`}
                        headline="Bereits abgeholt"
                        numberOfOrders={this.props.pickedUpOrders.length}
                        onClick={this.toggleLaneForDeliveredOrders}
                        onDragEnter={() => {
                            this.setVisibilityOfCollapsibleLaneHeaders(false, true, false);
                            this.setDragAndDropFeedback({
                                isLaneForDeliveredOrdersHighlighted: true,
                            });
                        }}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('PICKED_UP')}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneBody
                        className={`${this.state.isLaneForDeliveredOrdersHighlighted && 'LaneBody-highlightOnDragOver'} stackable-lane-body`}
                        isOpen={this.state.isLaneForDeliveredOrdersOpen}
                        orderItems={this.props.pickedUpOrders}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('PICKED_UP')}
                        onDragEnter={() => this.setDragAndDropFeedback({
                            isLaneForDeliveredOrdersHighlighted: true,
                        })}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneHeader
                        className={`Laneheader${this.state.isLaneForCanceledOrdersHighlighted && 'LaneHeader-highlightOnDragOver'}`}
                        headline="Storniert"
                        numberOfOrders={this.props.canceledOrders.length}
                        onClick={this.toggleLaneForCanceledOrders}
                        onDragEnter={() => {
                            this.setVisibilityOfCollapsibleLaneHeaders(false, false, true);
                            this.setDragAndDropFeedback({
                                isLaneForCanceledOrdersHighlighted: true,
                            });
                        }}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('CANCELED')}
                        onDrop={this.clearDropHighlighting}
                    />
                    <LaneBody
                        className={`${this.state.isLaneForCanceledOrdersHighlighted && 'LaneBody-highlightOnDragOver'} stackable-lane-body`}
                        isOpen={this.state.isLaneForCanceledOrdersOpen}
                        orderItems={this.props.canceledOrders}
                        moveOrderToAnotherLane={this.moveOrderToAnotherLane('CANCELED')}
                        onDragEnter={() => this.setDragAndDropFeedback({
                            isLaneForCanceledOrdersHighlighted: true,
                        })}
                        onDrop={this.clearDropHighlighting}
                    />
                </div>
            </div>
        );
    }
    /* eslint-enable max-len */

}

Monitor.propTypes = {
    openOrders: PropTypes.array.isRequired,
    inPreparationOrders: PropTypes.array.isRequired,
    preparedOrders: PropTypes.array.isRequired,
    pickedUpOrders: PropTypes.array.isRequired,
    canceledOrders: PropTypes.array.isRequired,
    changeOrderState: PropTypes.func.isRequired,
    fetchBlockedCustomers: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        openOrders: state.queue.data
            .filter(order => order.state === 'OPEN'),
        inPreparationOrders: state.queue.data
            .filter(order => order.state === 'IN_PREPARATION'),
        preparedOrders: state.queue.data
            .filter(order => order.state === 'PREPARED'),
        pickedUpOrders: state.queue.data
            .filter(order => order.state === 'PICKED_UP')
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        canceledOrders: state.queue.data
            .filter(order => order.state === 'CANCELED')
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
        monitorDate: state.queue.monitorDate,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        changeOrderState: (orderId, state) => dispatch(changeOrderStateAction(orderId, state)),
        fetchBlockedCustomers: () => dispatch(fetchBlockedCustomersAction()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Monitor));
