import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

class LanguageProvider extends React.PureComponent {

    constructor(props) {
        super(props);

        /* Override moments humanized strings to shorten them */
        moment.updateLocale('en', {
            relativeTime: {
                future: 'in %s',
                past: '%s ago',
                s: '< 1 min',
                ss: '< 1 min',
                m: '1 min',
                mm(number) {
                    return `${number} min`;
                },
                h: '1 h',
                hh: '%d h',
                d: 'a day',
                dd: '%d days',
                M: 'a month',
                MM: '%d months',
                y: 'a year',
                yy: '%d years',
            },
        });

        moment.updateLocale('de', {
            relativeTime: {
                future: 'in %s',
                past: 'vor %s',
                s: '< 1 min',
                ss: '< 1 min',
                m: '1 min',
                mm(number) {
                    return `${number} min`;
                },
                h: '1 h',
                hh: '%d h',
                d: 'ein Tag',
                dd: '%d Tagen',
                M: 'ein Monat',
                MM: '%d Monaten',
                y: 'ein Jahr',
                yy: '%d Jahren',
            },
        });
    }

    render() {
        return (
            <IntlProvider
                locale={this.props.locale}
                key={this.props.locale}
                messages={this.props.messages[this.props.locale]}
            >
                {React.Children.only(this.props.children)}
            </IntlProvider>
        );
    }

}

LanguageProvider.propTypes = {
    locale: PropTypes.string.isRequired,
    messages: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
    };
}

export default connect(mapStateToProps)(LanguageProvider);
