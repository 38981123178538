import { createSelector } from 'reselect';

const selectLocale = state => (
    state.languageProvider.locale
);

const selectMerchant = state => (
    state.app.merchant
);

const selectCategories = state => (
    state.app.merchant?.categories ?? []
);

const selectProducts = state => (
    state.app.merchant?.products ?? []
);

const selectSelectedCategoryId = state => (
    state.menu.selectedCategoryId
);

const selectSelectedProductId = state => (
    state.menu.selectedProductId
);

const selectSelectedCategory = createSelector(
    selectSelectedCategoryId,
    selectCategories,
    (id, categories) => categories.find(elem => elem._id === id)
);

const selectSelectedProduct = createSelector(
    selectSelectedProductId,
    selectProducts,
    (id, products) => products.find(elem => elem._id === id)
);

const selectProductsForSelectedCategory = createSelector(
    selectSelectedCategoryId,
    selectProducts,
    (id, products) => products.filter(elem => elem.category === id)
);

const selectProductOptionGroups = state => (
    state.app.merchant?.productOptionGroups ?? []
);

const selectProductOptions = state => (
    state.app.merchant?.productOptions ?? []
);

const selectSelectedProductOptionGroupId = state => (
    state.productOptionGroups.selectedProductOptionGroupId
);

const selectSelectedProductOptionId = state => (
    state.productOptionGroups.selectedProductOptionId
);

const selectSelectedProductOptionGroup = createSelector(
    selectSelectedProductOptionGroupId,
    selectProductOptionGroups,
    (id, productOptionGroups) => productOptionGroups.find(elem => elem._id === id)
);

const selectSelectedProductOption = createSelector(
    selectSelectedProductOptionId,
    selectProductOptions,
    (id, productOptions) => productOptions.find(elem => elem._id === id)
);

export {
    selectLocale,
    selectMerchant,
    selectCategories,
    selectProducts,
    selectSelectedCategoryId,
    selectSelectedProductId,
    selectSelectedCategory,
    selectSelectedProduct,
    selectProductsForSelectedCategory,
    selectProductOptionGroups,
    selectProductOptions,
    selectSelectedProductOptionGroup,
    selectSelectedProductOption,
};
