import React from 'react';
import PropTypes from 'prop-types';
import {
    Col,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

function FormSelect({
    id,
    label,
    onChange,
    value,
    options,
    disabled,
}) {
    const optionFields = Object.keys(options).map(key => (
        <option value={key} key={`opt-${key}`}>{options[key]}</option>
    ));

    const inputElement = (
        <Input
            id={id}
            name="select"
            type="select"
            onChange={onChange}
            value={value}
            disabled={disabled}
        >
            {optionFields}
        </Input>
    );

    if (label == null) {
        return (
            <FormGroup className="mt-2">
                {inputElement}
            </FormGroup>
        );
    }

    return (
        <FormGroup className="mt-2" row>
            <Label for={id} sm={4}>
                {label}
            </Label>
            <Col sm={8}>
                {inputElement}
            </Col>
        </FormGroup>
    );
}

FormSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default FormSelect;
