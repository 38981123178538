import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import {
    Alert,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Row,
} from 'reactstrap';

import Toggle from 'components/Toggle';
import FormattedOrderNumber from 'components/FormattedOrderNumber';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import FormattedAmount from 'components/FormattedAmount';

import {
    MONTH_NAMES,
    STATE_MAP,
    USER_TYPE_MAP,
    PAYMENT_TYPE_MAP,
    convertMonthToRange,
} from 'utils';

import {
    fetchExcel as fetchExcelAction,
    fetchOrders as fetchOrdersAction,
    setFilters as setFiltersAction,
} from './actions';
import { selectMerchant } from '../App/selectors';
import {
    selectOrders,
    selectCustomers,
    selectFilters,
    selectFilteredOrders,
} from './selectors';

import './styles.css';

let __lastScrollTop = 0;

class Orders extends React.Component {

    constructor(props) {
        super(props);

        this.rowEvents = {
            onClick: (e, row) => {
                props.history.push(`/history/orders/${row._id}`);
            },
        };

        this.rowStyle = {
            cursor: 'pointer',
        };

        this.handleSelectYearAndMonth = this.handleSelectYearAndMonth.bind(this);
        this.handleToggleShowAll = this.handleToggleShowAll.bind(this);
        this.handleClickDownloadMonth = this.handleClickDownloadMonth.bind(this);
    }

    componentDidMount() {
        const { filters, location } = this.props;

        // OrderDetails page sends a from state with its URL.
        // We only want to fetch by opening the page via the main nav.
        if (!(location.state && location.state.from)) {
            this.submitOrderRequest(filters.year, filters.month);
        }
    }

    componentWillUnmount() {
        const { scrollElement } = this.props;

        if (scrollElement && scrollElement.scrollTop) {
            __lastScrollTop = this.props.scrollElement.scrollTop;
        }
    }

    handleClickDownloadMonth() {
        const { fetchExcel, filters } = this.props;

        const { from, to } = convertMonthToRange(filters.year, filters.month);

        fetchExcel(from, to);
    }

    handleSelectYearAndMonth(e) {
        const { setFilters } = this.props;
        const [year, month] = e.target.value.split('-');

        setFilters({ year, month });
        __lastScrollTop = 0;

        this.submitOrderRequest(year, month);
    }

    handleToggleShowAll() {
        const { setFilters, filters } = this.props;
        setFilters({ showAll: !filters.showAll });
    }

    submitOrderRequest(year, month) {
        const { fetchOrders } = this.props;

        const { from, to } = convertMonthToRange(year, month);

        fetchOrders(from, to);
    }

    generateSelectOptions(end) {
        const start = moment();
        const yearAndMonth = [];

        while (start > end) {
            yearAndMonth.push(moment(start));
            start.subtract(1, 'month');
        }

        return yearAndMonth.map(i => (
            <option
                key={`${i.year()}-${i.month()}`}
                value={`${i.year()}-${i.month()}`}
            >
                {`${MONTH_NAMES[i.month()]}, ${i.year()} `}
            </option>
        ));
    }

    render() {
        const {
            scrollElement,
            locale,
            merchant,
            filters,
            orders,
            filteredOrders,
            customers,
        } = this.props;

        let optionsEnd = moment().subtract(12, 'months');
        if (merchant && moment(merchant.createdAt).isAfter(optionsEnd)) {
            optionsEnd = moment(merchant.createdAt);
        }

        const selectOptions = this.generateSelectOptions(optionsEnd);

        if (!scrollElement) {
            // scrollElement can be null/undefined (e.g. Navigating directly to this page).
            // We need to pick up the reference for the DOM element first.
            return <Redirect to="/history" />;
        }
        setTimeout(() => {
            this.props.scrollElement.scrollTop = __lastScrollTop;
        }, 1);

        let filtered = {};
        if (orders) {
            filtered = filters.showAll ? orders : filteredOrders;
        }

        const hover = window.cordova.platformId === 'browser';

        return (
            <div className="orders-container">
                <h3>Bestellungen</h3>
                <hr />
                <Form>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="yearAndMonth"
                                    id="selectYear"
                                    value={`${filters.year}-${filters.month}`}
                                    onChange={this.handleSelectYearAndMonth}
                                >
                                    {selectOptions}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup style={{ paddingTop: '.4rem' }}>
                                <Toggle
                                    id="showAll"
                                    label="Alle Bestellungen anzeigen"
                                    checked={filters.showAll}
                                    onChange={this.handleToggleShowAll}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <Button className="pull-right" onClick={this.handleClickDownloadMonth}>
                                <i className="fa fa-download" /> Export (Excel)
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <BootstrapTable
                    bootstrap4
                    hover={hover}
                    bordered={false}
                    classes="orders-table"
                    keyField="supportId"
                    rowEvents={this.rowEvents}
                    rowStyle={this.rowStyle}
                    defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
                    noDataIndication={() => <Alert color="light">Keine Bestellungen gefunden!</Alert>}
                    data={Object.values(filtered)}
                    columns={[
                        {
                            dataField: 'supportId',
                            text: 'Bestellnummer',
                            sort: true,
                            formatter: (cellContent, row) => <FormattedOrderNumber value={row.supportId} />,
                        },
                        {
                            dataField: 'createdAt',
                            text: 'Erstellt am',
                            sort: true,
                            formatter: (cellContent, row) => moment(row.createdAt).format('lll'),
                        },
                        {
                            dataField: 'phoneNumber',
                            text: 'Erstellt von',
                            sort: true,
                            formatter: (cellContent, row) => (
                                row.createdBy === USER_TYPE_MAP.CUSTOMER
                                    ? <FormattedPhoneNumber phoneNumber={customers[row.customer].phoneNumber} />
                                    : 'Restaurant'
                            ),
                        },
                        {
                            dataField: 'price',
                            text: 'Preis',
                            sort: true,
                            headerAlign: 'right',
                            align: 'right',
                            formatter: (cellContent, row) => (
                                <React.Fragment>
                                    <FormattedAmount locale={locale} value={row.price} />
                                </React.Fragment>
                            ),
                        },
                        {
                            dataField: 'paymentType',
                            text: 'Zahlungsart',
                            sort: true,
                            formatter: (cellContent, row) => PAYMENT_TYPE_MAP[row.paymentType],
                        },
                        {
                            dataField: 'state',
                            text: 'Status',
                            sort: true,
                            formatter: (cellContent, row) => STATE_MAP[row.state],
                        },
                        {
                            dataField: 'ownPackaging',
                            text: 'Verpackung',
                            sort: true,
                            formatter: cellContent => cellContent
                                ? 'Kunde'
                                : 'Restaurant',
                        },
                    ]}
                />
            </div>
        );
    }

}

Orders.propTypes = {
    scrollElement: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    merchant: PropTypes.object,
    locale: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    orders: PropTypes.object,
    filteredOrders: PropTypes.any,
    customers: PropTypes.object,

    fetchExcel: PropTypes.func.isRequired,
    fetchOrders: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
        merchant: selectMerchant(state),
        filters: selectFilters(state),
        orders: selectOrders(state),
        filteredOrders: selectFilteredOrders(state),
        customers: selectCustomers(state),
    };
}

const mapDispatchToProps = {
    fetchExcel: fetchExcelAction,
    fetchOrders: fetchOrdersAction,
    setFilters: setFiltersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
