import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    Form,
    Row,
} from 'reactstrap';

import FormInput from 'components/FormInput';
import DeleteDialog from 'components/DeleteDialog';
import Toggle from 'components/Toggle';
import StateMenuHeader from 'components/StateMenuHeader';

class CreateEditCategoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = CreateEditCategoryForm.getEmptyState();

        this.handleChangeCategoryName = this.handleChangeCategoryName.bind(this);
        this.handleChangeCategoryDescription = this.handleChangeCategoryDescription.bind(this);
        this.handleChangeCategoryEnabled = this.handleChangeCategoryEnabled.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.initFormValidators = this.initFormValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        this.initFormValidators();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.category
            && nextProps.category._id !== prevState.categoryId
            && !prevState.deleteDialogOpen) {
            return CreateEditCategoryForm.getStateFromProps(nextProps);
        }

        if (nextProps.forceClearFormData || nextProps.new !== prevState.new) {
            return CreateEditCategoryForm.getEmptyState();
        }

        if (!prevState.pristine) {
            return null;
        }

        return null;
    }

    static getEmptyState() {
        return {
            new: true,
            pristine: true,
            touched: false,
            categoryId: null,
            categoryName: '',
            categoryDescription: '',
            categoryEnabled: true,
            deleteDialogOpen: false,
        };
    }

    static getStateFromProps(props) {
        const { category } = props;

        return {
            new: false,
            pristine: true,
            touched: false,
            forceShowValidationErrors: false,
            categoryId: category._id,
            categoryName: category.name,
            categoryDescription: category.description || '',
            categoryEnabled: category.enabled,
            deleteDialogOpen: false,
        };
    }

    static getNewCategoryFromPropsAndState(props, state) {
        const { category } = props;
        const {
            categoryName,
            categoryDescription,
            categoryEnabled,
        } = state;
        const updatedCategory = {
            ...category,
            name: categoryName,
            description: categoryDescription,
            enabled: categoryEnabled,
        };

        // if (categoryDescription.length <= 0) {
        //     updatedCategory.description = null;
        // }

        return updatedCategory;
    }

    initFormValidators() {
        this.formValidators = {
            isCategoryNameValid: () => !!this.state.categoryName && this.state.categoryName.length <= 35,
        };
    }

    isFormValid() {

        let isValid = true;
        Object.values(this.formValidators).forEach(validator => {
            if (!validator()) {
                isValid = false;
            }
        });

        return isValid;
    }

    handleChangeCategoryName(event) {
        this.setState({
            categoryName: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeCategoryDescription(event) {
        this.setState({
            categoryDescription: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeCategoryEnabled(event) {
        this.setState(state => ({
            categoryEnabled: !state.categoryEnabled,
            pristine: false,
            touched: true,
        }));
        this.props.onChange(event);
    }

    handleOnBlur() {
        this.setState({
            touched: true,
        });
    }

    handleCreate(event, updatedCategory) {
        this.props.onCreate(updatedCategory).then(() => {
            this.setState({
                pristine: true,
            });
        });
        event.preventDefault();
    }

    handleUpdate(event) {

        if (this.isFormValid()) {
            this.setState({
                forceShowValidationErrors: false,
            });
        } else {
            this.setState({
                forceShowValidationErrors: true,
            });
            return;
        }

        const updatedCategory = CreateEditCategoryForm.getNewCategoryFromPropsAndState(this.props, this.state);

        if (this.props.new) {
            this.handleCreate(event, updatedCategory);
        } else {
            this.props.onUpdate(updatedCategory).then(() => {
                this.setState({
                    pristine: true,
                });
            });
        }

        event.preventDefault();
    }

    handleDelete(event) {
        if (this.props.new) {
            this.setState(CreateEditCategoryForm.getEmptyState());
            this.props.onDelete(null);
        } else {
            const deleteCategory = CreateEditCategoryForm.getNewCategoryFromPropsAndState(this.props, this.state);
            this.props.onDelete(deleteCategory);
        }

        event.preventDefault();
    }

    toggleDeleteDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }));
    }

    render() {
        const {
            pristine,
            categoryName,
            categoryDescription,
            deleteDialogOpen,
            categoryEnabled,
        } = this.state;

        return (
            <div>
                <StateMenuHeader
                    text={this.state.new ? 'Neue Kategorie' : `Kategorie "${categoryName}"`}
                    available
                    enabled
                />
                <div className="flex-grow-0 px-2">
                    <Form onSubmit={null}>
                        <FormInput
                            label="Bezeichnung:"
                            formFeedBack="Feld darf nicht leer sein und max. 35 Zeichen enthalten."
                            id="categoryName"
                            valid={this.formValidators.isCategoryNameValid()}
                            value={categoryName}
                            onChange={this.handleChangeCategoryName}
                            onBlur={this.handleOnBlur}
                            focus={this.props.new && !this.state.touched}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <FormInput
                            label="Beschreibung:"
                            id="categoryDescription"
                            type="textarea"
                            value={categoryDescription}
                            onChange={this.handleChangeCategoryDescription}
                            onBlur={this.handleOnBlur}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                    </Form>
                    <Row noGutters className="form-toggle-row">
                        <Toggle
                            id="toggle-category-enabled"
                            label={`Kategorie ${categoryEnabled ? 'sichtbar' : 'versteckt'}`}
                            checked={categoryEnabled}
                            onChange={this.handleChangeCategoryEnabled}
                        />
                    </Row>
                    <Row noGutters className="align-items-center pt-2">
                        <Col className="pr-1" xs="10">
                            <Button
                                className="w-100"
                                disabled={pristine}
                                onClick={this.handleUpdate}
                            >
                                Kategorie speichern
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100"
                                color="danger"
                                onClick={this.toggleDeleteDialog}
                            >
                                <i className="fa fa-trash-o" />
                            </Button>
                        </Col>
                        <DeleteDialog
                            isOpen={deleteDialogOpen}
                            toggle={this.toggleDeleteDialog}
                            onConfirm={this.handleDelete}
                            object={{
                                header: this.props.new ? 'Änderungen verwerfen' : 'Kategorie löschen',
                                body: this.props.new
                                    ? 'Wollen Sie die nicht gespeicherten Änderungen wieder verwerfen?'
                                    : `Wollen Sie die Kategorie ${categoryName} und die
                                        dazu gehörigen Produkte löschen?`,
                                confirmButtonText: this.props.new ? 'Änderungen verwerfen' : 'Kategorie löschen',
                            }}
                        />
                    </Row>
                </div>
            </div>
        );
    }

}

CreateEditCategoryForm.propTypes = {
    new: PropTypes.bool,
    category: PropTypes.object,  // eslint-disable-line react/no-unused-prop-types
    forceClearFormData: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

CreateEditCategoryForm.defaultProps = {
    forceClearFormData: false,
};

export default CreateEditCategoryForm;
