import {
    SELECT_CATEGORY,
    SELECT_PRODUCT,
} from './constants';

const initialState = {
    selectedCategoryId: null,
    selectedProductId: null,
};

const menu = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CATEGORY:
            return {
                ...state,
                selectedCategoryId: action.categoryId,
            };
        case SELECT_PRODUCT:
            return {
                ...state,
                selectedProductId: action.productId,
            };
        default:
            return state;
    }
};

export default menu;
