import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Table, Row, Col } from 'reactstrap';

import FormattedAmount from 'components/FormattedAmount';
import FormattedOrderNumber from 'components/FormattedOrderNumber';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';

import {
    selectOrders,
    selectResult,
    selectCustomers,
    // selectProducts,
} from 'containers/Orders/selectors';

import {
    STATE_MAP,
    PAYMENT_STATE_MAP,
    PAYMENT_TYPE_MAP,
    CANCEL_ENUM,
    USER_TYPE_MAP,
} from 'utils';

import './styles.css';

class OrderDetails extends React.Component {

    componentDidMount() {
        if (this.props.scrollElement && this.props.scrollElement.scrollTop) {
            this.props.scrollElement.scrollTop = 0;
        }
    }

    renderCanceledReasons(order) {
        if (order.canceledReasons) {
            let rowspan = order.canceledReasons.length;

            if (order.canceledComment) {
                rowspan += 1;
            }

            return order.canceledReasons.map((r, i) => {
                if (i === 0) { // First element: set rowspan
                    return (
                        <tr key={`r-${r}`}>
                            <th rowSpan={rowspan}>Stornobegründung</th>
                            <td>{CANCEL_ENUM[r]}</td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={`r-${r}`}>
                            <td>{CANCEL_ENUM[r]}</td>
                        </tr>
                    );
                }
            });
        }

        return null;
    }

    renderProducts(locale, order) {
        return order.products.map(p => ([
            <tr key={`${order._id}-${p._id}`}>
                <td>
                    {p.name}
                </td>
                <td className="align-right">
                    {p.quantity}
                </td>
                <td className="align-right">
                    <FormattedAmount locale={locale} value={p.price} />
                </td>
                <td className="align-right">
                    {p.taxRate / 100} &#37;
                </td>
                <td className="align-right">
                    <FormattedAmount locale={locale} value={p.quantity * p.price} />
                </td>
            </tr>,
            p.optionGroups && p.optionGroups.map(optionGroup => (
                optionGroup.options && optionGroup.options.map(option => (
                    <tr className="order-details-table-options" key={`${order._id}-${p._id}-${option._id}}`}>
                        <td>
                            {option.name}
                        </td>
                        <td className="align-right">
                            {p.quantity}
                        </td>
                        <td className="align-right">
                            <FormattedAmount locale={locale} value={option.priceAdditional} />
                        </td>
                        <td className="align-right">
                            {option.taxRate / 100} &#37;
                        </td>
                        <td className="align-right">
                            <FormattedAmount locale={locale} value={p.quantity * option.priceAdditional} />
                        </td>
                    </tr>
                ))
            )),
        ]));
    }

    renderPaymentState(order) {
        if (order.paymentState) {
            const testLink = order.stripe.sessionId.includes('_test_') ? 'test/' : '';

            return (
                <tr>
                    <th>Zahlungsstatus</th>
                    <td className="text-right">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://dashboard.stripe.com/${testLink}payments/${order.stripe.paymentIntentId}`}
                        >
                            {PAYMENT_STATE_MAP[order.paymentState]} <i className="fa fa-external-link" />
                        </a>
                    </td>
                </tr>
            );
        }
        return null;
    }

    render() {
        const {
            location,
            match,
            locale,
            customers,
            orders,
            result,
        } = this.props;

        if (result.length <= 0) {
            return <Redirect to="/history/orders" />;
        }

        const orderId = match.params.id;
        const order = orders[orderId];
        const customer = customers[order.customer];

        if (!order) {
            return <Redirect to="/history/orders" />;
        }

        const createdAt = order.createdAt ? moment(order.createdAt).format('lll') : '';
        const preparedAt = order.preparedAt ? moment(order.preparedAt).format('lll') : '';
        const pickedUpAt = order.pickedUpAt ? moment(order.pickedUpAt).format('lll') : '';
        const canceledAt = order.canceledAt ? moment(order.canceledAt).format('lll') : '';
        let canceledBy = '';
        if (order.canceledBy === USER_TYPE_MAP.CUSTOMER) {
            canceledBy = 'Kunde';
        }
        if (order.canceledBy === USER_TYPE_MAP.MERCHANT) {
            canceledBy = 'Restaurant';
        }

        return (
            <div className="order-details-container">
                <Link
                    to={{
                        pathname: '/history/orders',
                        state: { from: location },
                    }}
                >
                    Zurück
                </Link>
                <hr />
                <Row>
                    <Col md={12}>
                        <h2>Bestelldetails</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Table className="order-details-table" style={{ marginBottom: 0 }}>
                            <tbody>
                                <tr>
                                    <th>Abholnummer</th>
                                    <td className="text-right">{order.pickUpNumber}</td>
                                </tr>
                                <tr>
                                    <th>Bestellnummer</th>
                                    <td className="text-right">
                                        <FormattedOrderNumber value={order.supportId} />
                                    </td>
                                </tr>
                                {(() => {
                                    if (order.pickUpCustomerAt) {
                                        return (
                                            <tr>
                                                <th>Abholung</th>
                                                <td className="text-right">
                                                    {moment(order.pickUpCustomerAt).format('lll')}
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr>
                                                <th>Abholung</th>
                                                <td className="text-right">Sofort</td>
                                            </tr>
                                        );
                                    }
                                })()}
                                {(() => {
                                    if (order.ownPackaging) {
                                        return (
                                            <tr>
                                                <th>Verpackung</th>
                                                <td className="text-right">Kunde</td>
                                            </tr>
                                        );
                                    } else {
                                        return (
                                            <tr>
                                                <th>Verpackung</th>
                                                <td className="text-right">Restaurant</td>
                                            </tr>
                                        );
                                    }
                                })()}
                                {this.renderCanceledReasons(order)}
                                <tr>
                                    <th>Erstellt von</th>
                                    <td className="text-right">
                                        {
                                            order.createdBy === USER_TYPE_MAP.CUSTOMER
                                                ? <FormattedPhoneNumber phoneNumber={customer.phoneNumber} />
                                                : 'Restaurant'
                                        }
                                    </td>
                                </tr>
                                {(() => {
                                    if (order.note && order.note.length > 0) {
                                        return (
                                            <tr>
                                                <th>Anmerkungen</th>
                                                <td className="text-right">{order.note}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })()}
                                <tr>
                                    <th>Status</th>
                                    <td className="text-right">{STATE_MAP[order.state]}</td>
                                </tr>
                                <tr>
                                    <th>Zahlungsart</th>
                                    <td className="text-right">{PAYMENT_TYPE_MAP[order.paymentType]}</td>
                                </tr>
                                {this.renderPaymentState(order)}
                                <tr>
                                    <th>Preis</th>
                                    <td className="text-right">
                                        <FormattedAmount locale={locale} value={order.price} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={6}>
                        <Table className="order-details-table">
                            <tbody>
                                <tr>
                                    <th>Bestellt</th>
                                    <td className="text-right">{createdAt}</td>
                                </tr>
                                <tr>
                                    <th>Zubereitet</th>
                                    <td className="text-right">{preparedAt}</td>
                                </tr>
                                <tr>
                                    <th>Abgeholt</th>
                                    <td className="text-right">{pickedUpAt}</td>
                                </tr>
                                <tr>
                                    <th>Storniert</th>
                                    <td className="text-right">{canceledAt}</td>
                                </tr>
                                <tr>
                                    <th>Storniert von</th>
                                    <td className="text-right">
                                        {canceledBy}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h3>Produkte</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table className="order-details-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="align-right">Menge</th>
                                    <th className="align-right">Stückpreis (Brutto)</th>
                                    <th className="align-right">Steuersatz</th>
                                    <th className="align-right">Gesamtpreis (Brutto)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderProducts(locale, order)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }

}

OrderDetails.propTypes = {
    scrollElement: PropTypes.any,
    location: PropTypes.object,
    match: PropTypes.object,
    locale: PropTypes.string.isRequired,
    result: PropTypes.array.isRequired,
    orders: PropTypes.object.isRequired,
    customers: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProvider.locale,
        result: selectResult(state),
        orders: selectOrders(state),
        customers: selectCustomers(state),
    };
}

export default connect(mapStateToProps)(OrderDetails);
