import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Row,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';

import Sound from 'services/Sound';

import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import Toggle from 'components/Toggle';

import {
    fetchMerchantData as fetchMerchantDataAction,
    updateMerchantData as updateMerchantDataAction,
    fetchClientId as fetchClientIdAction,
    authorizeMerchant as authorizeMerchantAction,
} from 'containers/App/actions';
import { selectSound as selectSoundAction } from 'containers/AudioPlayer/actions';

import './styles.css';
import StripeActivation from '../StripeActivation';

/* eslint-disable react/no-unused-state */
class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pristine: true,
            canceled: false,
            pickUpNumberPrefix: '',
            maxShoppingCartProducts: '',
            oldPassword: '',
            newPassword: '',
            newPasswordRepeated: '',
            selectedSound: '',
            soundRepeat: false,
            newOrderPopup: false,
            ownPackagingEnabled: false,
            preOrderEnabled: false,
            preOrderSortOffset: 0,
            paymentTypeStripeAllowed: false,
            paymentTypeStripe: false,
            paymentTypeOnSite: false,
            stripeProcessing: false,
            authorizationCode: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.initFormValidators = this.initFormValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);

        this.initFormValidators();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.pristine && nextProps.merchant) {
            const merchantSound = Object.keys(nextProps.sounds)[nextProps.merchant.sound];
            if (merchantSound !== nextProps.selectedSound) {
                nextProps.selectSound(Object.keys(nextProps.sounds)[nextProps.merchant.sound]);
            }

            const params = new URLSearchParams(nextProps.history.location.search);
            const authorizationCode = params.get('code');

            return {
                phoneNumber: nextProps.merchant.phoneNumber || '',
                pickUpNumberPrefix: nextProps.merchant.pickUpNumberPrefix || '',
                maxShoppingCartProducts: nextProps.merchant.maxShoppingCartProducts || '',
                selectedSound: merchantSound || '',
                soundRepeat: nextProps.merchant.newOrderSoundRepeat,
                newOrderPopup: nextProps.merchant.newOrderPopup,
                preOrderEnabled: nextProps.merchant.preOrderEnabled,
                ownPackagingEnabled: nextProps.merchant.ownPackagingEnabled,
                preOrderSortOffset:
                    nextProps.merchant.preOrderSortOffset >= 0 ? nextProps.merchant.preOrderSortOffset : '',
                paymentTypeStripeAllowed: nextProps.merchant.paymentTypeStripeAllowed,
                paymentTypeStripe: nextProps.merchant.paymentTypesEnabled.indexOf('STRIPE') >= 0 && true,
                paymentTypeOnSite: nextProps.merchant.paymentTypesEnabled.indexOf('ON_SITE') >= 0 && true,
                authorizationCode,
            };
        }

        return null;
    }

    componentDidMount() {
        this.props.fetchMerchantData();
    }

    componentWillUnmount() {
        this.props.selectSound(Object.keys(this.props.sounds)[this.props.merchant.sound]);
    }

    // eslint-disable-next-line react/sort-comp
    initFormValidators() {
        this.formValidators = {
            isPickUpNumberPrefixValid: () => !this.state.pickUpNumberPrefix
                || (this.state.pickUpNumberPrefix
                    && this.state.pickUpNumberPrefix.length <= 3),
            isMaxShoppingCartProductsValid: () => !!this.state.maxShoppingCartProducts
                && !Number.isNaN(this.state.maxShoppingCartProducts)
                && this.state.maxShoppingCartProducts >= 1
                && this.state.maxShoppingCartProducts <= 99,
            isOldPasswordValid: () => !(this.state.newPassword || this.state.newPasswordRepeated)
                || !!this.state.oldPassword,
            isNewPasswordValid: () => (!this.state.oldPassword || !!this.state.newPassword) &&
                (!this.state.newPassword || (this.state.newPassword && this.state.newPassword.length >= 8 &&
                    this.state.newPassword !== this.state.oldPassword)),
            isNewPasswordRepeatedValid: () => !this.state.newPassword
                || this.state.newPassword === this.state.newPasswordRepeated,
            isPreOrderSortOffsetValid: () => !Number.isNaN(this.state.preOrderSortOffset)
                && this.state.preOrderSortOffset >= 0,
        };
    }

    isFormValid() {
        let isValid = true;
        Object.values(this.formValidators).forEach(validator => {
            if (!validator()) {
                isValid = false;
            }
        });

        return isValid;
    }

    handleSubmit() {
        if (!this.isFormValid()) {
            return;
        }

        const { updateMerchantData, merchant, sounds } = this.props;
        const {
            pickUpNumberPrefix,
            maxShoppingCartProducts,
            oldPassword,
            newPassword,
            selectedSound,
            soundRepeat,
            newOrderPopup,
            ownPackagingEnabled,
            preOrderEnabled,
            preOrderSortOffset,
            paymentTypeStripe,
            paymentTypeOnSite,
        } = this.state;
        const { _version } = merchant;

        const updatedMerchant = {
            pickUpNumberPrefix,
            preOrderEnabled,
            preOrderSortOffset: Number(preOrderSortOffset),
            sound: Object.keys(sounds).indexOf(selectedSound),
            newOrderSoundRepeat: soundRepeat,
            newOrderPopup,
            ownPackagingEnabled,
            _version,
            paymentTypesEnabled: [],
        };

        const parsedMax = Number(maxShoppingCartProducts);

        updatedMerchant.paymentTypesEnabled = paymentTypeStripe
            // Remove all before pushing to avoid duplicates
            ? [...updatedMerchant.paymentTypesEnabled.filter(e => e !== 'STRIPE'), 'STRIPE']
            : updatedMerchant.paymentTypesEnabled.filter(e => e !== 'STRIPE');

        updatedMerchant.paymentTypesEnabled = paymentTypeOnSite
            // Remove all before pushing to avoid duplicates
            ? [...updatedMerchant.paymentTypesEnabled.filter(e => e !== 'ON_SITE'), 'ON_SITE']
            : updatedMerchant.paymentTypesEnabled.filter(e => e !== 'ON_SITE');

        if (parsedMax > 0) {
            updatedMerchant.maxShoppingCartProducts = parsedMax;
        }

        if (newPassword) {
            updatedMerchant.oldPassword = oldPassword;
            updatedMerchant.newPassword = newPassword;
        }

        updateMerchantData(updatedMerchant).then(() => {
            this.setState({
                pristine: true,
                oldPassword: '',
                newPassword: '',
                newPasswordRepeated: '',
            });
        });
    }

    getStripeSetting() {
        const { merchant, history } = this.props;
        const { paymentTypeStripe, paymentTypeStripeAllowed, authorizationCode } = this.state;

        if (merchant?.stripe?.id) {
            return (
                <Toggle
                    id="paymentTypeStripe"
                    checked={paymentTypeStripe}
                    onChange={() => this.setState({
                        paymentTypeStripe: !paymentTypeStripe,
                        pristine: false,
                    })}
                />
            );
        } else {
            return (
                <StripeActivation
                    authorizationCode={authorizationCode}
                    history={history}
                    disabled={!paymentTypeStripeAllowed}
                />
            );
        }
    }

    render() {
        const { merchant, sounds } = this.props;
        const {
            canceled,
            pickUpNumberPrefix,
            maxShoppingCartProducts,
            oldPassword,
            newPassword,
            newPasswordRepeated,
            selectedSound,
            soundRepeat,
            newOrderPopup,
            ownPackagingEnabled,
            preOrderEnabled,
            preOrderSortOffset,
            paymentTypeOnSite,
        } = this.state;

        if (canceled) {
            return <Redirect to="/monitor" />;
        }

        if (!merchant) {
            return <div>Loading...</div>;
        }

        const options = Object.keys(sounds).reduce((acc, cur) => {
            const regex = /(\.\/)([0-9]{2}-)(.+?)(\.[^.]*$|$)/;
            // eslint-disable-next-line
            acc[cur] = regex.exec(cur)[3];
            return acc;
        }, {});

        return (
            <div className="settings-page">
                <Container className="settings-container">
                    <Row className="align-items-center pt-2" style={{ marginBottom: 30 }}>
                        <Col sm={4}>
                            <h3>
                                Einstellungen
                            </h3>
                        </Col>
                        <Col sm={8}>
                            <div className="pull-right">
                                <Button
                                    className="mr-2"
                                    outline
                                    onClick={() => this.setState({ canceled: true })}
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    disabled={this.state.pristine}
                                    onClick={this.handleSubmit}
                                >
                                    <i className="fa fa-save" /> Speichern
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Form>
                        {merchant.customerNumber &&
                            <FormInput
                                disabled
                                id="customerNumber"
                                label="Kundennummer:"
                                value={merchant.customerNumber}
                                // eslint-disable-next-line
                                onChange={() => { }}
                                alignLabelRight
                            />
                        }
                        <FormInput
                            id="pickUpNumberPrefix"
                            label="Abholcode Prefix (max. 3 Zeichen):"
                            value={pickUpNumberPrefix}
                            valid={this.formValidators.isPickUpNumberPrefixValid()}
                            formFeedBack="Darf maximal 3 Zeichen lang sein."
                            onChange={e => this.setState({
                                pickUpNumberPrefix: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                        <FormInput
                            id="maxShoppingCardProducts"
                            label="Maximale Anzahl Artikel pro Bestellung:"
                            type="number"
                            value={maxShoppingCartProducts}
                            valid={this.formValidators.isMaxShoppingCartProductsValid()}
                            formFeedBack="Eine Zahl zwischen 1 und 99."
                            onChange={e => this.setState({
                                maxShoppingCartProducts: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                        <hr />
                        <FormSelect
                            id="soundSelect"
                            disabled={this.props.isPlaying}
                            label="Signalton bei neuer Bestellung:"
                            value={selectedSound}
                            onChange={e => {
                                this.setState({
                                    selectedSound: e.target.value,
                                    pristine: false,
                                });
                                this.props.selectSound(e.target.value);
                                Sound.load(this.props.sounds[e.target.value]);
                                Sound.play();
                            }}
                            options={options}
                        />
                        <FormGroup row>
                            <Label for="soundRepeat" sm={4}>
                                Signalton wiederholen:
                            </Label>
                            <Col sm={8}>
                                <Toggle
                                    id="soundRepeat"
                                    checked={soundRepeat}
                                    onChange={() => this.setState({
                                        soundRepeat: !soundRepeat,
                                        pristine: false,
                                    })}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="newOrderPopup" sm={4}>
                                Hinweis bei neuer Bestellung anzeigen:
                            </Label>
                            <Col sm={8}>
                                <Toggle
                                    id="newOrderPopup"
                                    checked={newOrderPopup}
                                    onChange={() => this.setState({
                                        newOrderPopup: !newOrderPopup,
                                        pristine: false,
                                    })}
                                />
                            </Col>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            <Label for="newOrderPopup" sm={4}>
                                Verpackung vom Kunden erlauben:
                            </Label>
                            <Col sm={8}>
                                <Toggle
                                    id="ownPackagingEnabled"
                                    checked={ownPackagingEnabled}
                                    onChange={() => this.setState({
                                        ownPackagingEnabled: !ownPackagingEnabled,
                                        pristine: false,
                                    })}
                                />
                            </Col>
                        </FormGroup>
                        <hr />
                        <FormGroup row>
                            <Label for="paymentTypeOnSite" sm={4}>
                                Bezahlung vor Ort zulassen:
                            </Label>
                            <Col sm={8} className="my-auto">
                                <Toggle
                                    id="paymentTypeOnSite"
                                    checked={paymentTypeOnSite}
                                    onChange={() => this.setState({
                                        paymentTypeOnSite: !paymentTypeOnSite,
                                        pristine: false,
                                    })}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="paymentTypeStripe" sm={4}>
                                Bezahlung per Stripe zulassen:
                            </Label>
                            <Col sm={4} className="my-auto">
                                {this.getStripeSetting()}
                            </Col>
                            { merchant.stripe?.id &&
                            <Col sm={4} className="my-auto">
                                <a
                                    target="_blank"
                                    href="https://dashboard.stripe.com/"
                                    rel="noopener noreferrer"
                                    className="float-right"
                                >
                                    Stripe Dashboard <i className="fa fa-external-link" />
                                </a>
                            </Col>
                            }
                        </FormGroup>
                        <FormGroup row>
                            <Label for="preOrderEnabled" sm={4}>
                                Vorbestellungen zulassen:
                            </Label>
                            <Col sm={8}>
                                <Toggle
                                    id="preOrderEnabled"
                                    checked={preOrderEnabled}
                                    onChange={() => this.setState({
                                        preOrderEnabled: !preOrderEnabled,
                                        pristine: false,
                                    })}
                                />
                            </Col>
                        </FormGroup>
                        <FormInput
                            id="preOrderSortOffset"
                            label="Vorbestellungen früher einreihen (Minuten):"
                            type="number"
                            value={preOrderSortOffset}
                            valid={this.formValidators.isPreOrderSortOffsetValid()}
                            formFeedBack="Eine Zahl größer oder gleich 0."
                            onChange={e => this.setState({
                                preOrderSortOffset: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                        <hr />
                        <FormInput
                            id="oldPassword"
                            label="Altes Passwort:"
                            type="password"
                            value={oldPassword}
                            valid={this.formValidators.isOldPasswordValid()}
                            formFeedBack="Geben Sie ihr altes Passwort ein."
                            onChange={e => this.setState({
                                oldPassword: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                        <FormInput
                            id="newPassword"
                            label="Neues Passwort:"
                            type="password"
                            value={newPassword}
                            valid={this.formValidators.isNewPasswordValid()}
                            formFeedBack="Geben Sie ein neues Passwort ein (mind. 8 Zeichen)."
                            onChange={e => this.setState({
                                newPassword: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                        <FormInput
                            id="newPasswordRepeated"
                            label="Neues Passwort (Wiederholen):"
                            type="password"
                            value={newPasswordRepeated}
                            valid={this.formValidators.isNewPasswordRepeatedValid()}
                            formFeedBack="Geben Sie das Passwort nochmals ein."
                            onChange={e => this.setState({
                                newPasswordRepeated: e.target.value,
                                pristine: false,
                            })}
                            alignLabelRight
                        />
                    </Form>
                </Container>
            </div>
        );
    }

}
/* eslint-enable react/no-unused-state */

Settings.propTypes = {
    location: PropTypes.object, // React-Router
    merchant: PropTypes.object,
    history: PropTypes.object.isRequired,
    fetchMerchantData: PropTypes.func.isRequired,
    updateMerchantData: PropTypes.func.isRequired,
    sounds: PropTypes.object.isRequired,
    selectSound: PropTypes.func.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    selectedSound: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        merchant: state.app.merchant,
        sounds: state.audioPlayer.sounds,
        isPlaying: state.audioPlayer.isPlaying,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMerchantData: () => dispatch(fetchMerchantDataAction()),
        updateMerchantData: merchant => dispatch(updateMerchantDataAction(merchant)),
        selectSound: key => dispatch(selectSoundAction(key)),
        fetchClientId: () => dispatch(fetchClientIdAction()),
        authorizeMerchant: code => dispatch(authorizeMerchantAction(code)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
