import React from 'react';
import { Jumbotron } from 'reactstrap';

function ConnectionErrorBox() {
    return (
        <Jumbotron>
            <h1>Verbindungsfehler</h1>
            <p>Die Applikation kann keine Verbindung ins Internet herstellen.</p>
        </Jumbotron>
    );
}

export default ConnectionErrorBox;
