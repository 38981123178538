import React from 'react';
import PropTypes from 'prop-types';

function FormattedPhoneNumber({ phoneNumber, styled, className }) {
    const classes = styled ? 'float-right' : '';

    return (
        <span className={`${classes} ${className}`}>
            {
                `+${phoneNumber.substr(0, 2)} ${phoneNumber.substr(2, 3)} ${phoneNumber.substr(5)}`
            }
        </span>
    );
}

FormattedPhoneNumber.propTypes = {
    phoneNumber: PropTypes.string.isRequired,
    styled: PropTypes.bool,
    className: PropTypes.string,
};

FormattedPhoneNumber.defaultProps = {
    className: '',
};

export default FormattedPhoneNumber;
