import {
    SELECT_PRODUCT_OPTION_GROUP,
    SELECT_PRODUCT_OPTION,
} from './constants';

const initialState = {
    selectedProductOptionGroupId: null,
    selectedProductOptionId: null,
};

const productOptionGroups = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_PRODUCT_OPTION_GROUP:
            return {
                ...state,
                selectedProductOptionGroupId: action.productOptionGroupId,
            };
        case SELECT_PRODUCT_OPTION:
            return {
                ...state,
                selectedProductOptionId: action.productOptionId,
            };
        default:
            return state;
    }
};

export default productOptionGroups;
