import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { stop as stopAction } from 'containers/AudioPlayer/actions';
import { toggleNewOrderPopup as toggleNewOrderPopupAction } from './actions';

import './styles.css';

function NewOrderPopup({ isVisible, toggleNewOrderPopup, stop }) {

    function handleClick() {
        toggleNewOrderPopup(false);
        stop();
    }

    return isVisible
        ? (
            <div
                className="new-order-popup"
                onClick={handleClick}
                onKeyPress={handleClick}
                role="button"
                tabIndex="0"
            >
                <div className="new-order-popup-text">
                    <p>NEUE BESTELLUNG<br />EINGETROFFEN!</p>
                </div>
            </div>
        )
        : null;
}

NewOrderPopup.propTypes = {
    isVisible: PropTypes.bool.isRequired,

    toggleNewOrderPopup: PropTypes.func.isRequired,
    stop: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isVisible: state.newOrderPopup.isVisible,
    };
}

const mapDispatchToProps = {
    stop: stopAction,
    toggleNewOrderPopup: toggleNewOrderPopupAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderPopup);
