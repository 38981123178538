import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import Storage from 'services/Storage';
import { logoutSuccess } from 'containers/Login/actions';

export function normalize(array, key) {
    return Object.assign({}, ...array.map(item => ({ [item[key]]: item })));
}

export const USER_TYPE_MAP = Object.freeze({
    CUSTOMER: 'CUSTOMER',
    MERCHANT: 'MERCHANT',
});

export const STATE_ENUM = Object.freeze({
    0: 'OPEN',
    1: 'IN_PREPARATION',
    2: 'PREPARED',
    3: 'PICKED_UP',
    4: 'CANCELED',
});

export const STATE_MAP = Object.freeze({
    PENDING: 'Zahlung gestartet',
    OPEN: 'Offen',
    IN_PREPARATION: 'In Bearbeitung',
    PREPARED: 'Zubereitet',
    PICKED_UP: 'Abgeholt',
    CANCELED: 'Storniert',
    ABORTED: 'Abgebrochen',
});

export const PAYMENT_STATE_MAP = Object.freeze({
    PENDING: 'Ausstehend',
    AUTHORIZED: 'Bestätigt',
    PAID: 'Erfolgreich',
    CANCELED: 'Storniert',
    REFUNDED: 'Rückerstattet',
});

export const PAYMENT_TYPE_MAP = Object.freeze({
    ON_SITE: 'Vor Ort',
    STRIPE: 'Stripe',
});

export const DAY_ENUM = Object.freeze({
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
});

export const MONTH_NAMES = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

export const CANCEL_ENUM = Object.freeze({
    CLOSING_SOON: 'Restaurant schließt in Kürze',
    NOT_AVAILABLE_ANYMORE: 'Speisen nicht mehr verfügbar',
    INVALID_COMMENTS: 'Sonderwünsche nicht gültig/umsetzbar',
});

export const CONTENT_TYPE_ENUM = Object.freeze({
    JSON: 'application/json',
    // Excel2007 xlsx
    EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
});

/*
 * HELPER FUNCTIONS
 */
function logout(dispatch, errorMessageUnauthorized) {
    return Storage.unsetToken()
        .finally(() => {
            toast.error(errorMessageUnauthorized, {
                toastId: 'toast.login.invalid', // Avoid duplicates
                position: toast.POSITION.TOP_CENTER,
            });

            dispatch(logoutSuccess());
            dispatch(push('/login'));
        });
}

/**
 * Base request for making a fetch call.
 * @param dispatch
 * @param url
 * @param method
 * @param headers
 * @param payload
 * @param errorMessageUnauthorized
 * @return {Promise<T>}
 */
function baseRequest({
    dispatch,
    url,
    method = 'GET',
    headers,
    payload,
    errorMessageUnauthorized = 'Die Anmeldung ist abgelaufen',
}) {
    const options = {
        method,
        headers,
    };

    if (payload) {
        options.body = JSON.stringify(payload);
    }

    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                // response was successful (status in the range 200-299)
                return response;
            } else if (response.status === 401) {
                // Unauthorized -- token is invalid
                return logout(dispatch, errorMessageUnauthorized)
                    .then(() => Promise.reject(response.status));
            } else {
                return Promise.reject(response.status);
            }
        })
        .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType?.includes('application/json')) {
                return response.json();
            } else if (contentType?.includes('application/vnd')) {
                return response.blob();
            } else {
                return response.text();
            }
        });
}

/**
 * Fetch request without authentication (Authorization header).
 * @param dispatch
 * @param url
 * @param method
 * @param headers
 * @param payload
 * @param errorMessageUnauthorized
 * @return {Promise<T>}
 */
export function request({
    dispatch,
    url,
    method,
    headers,
    payload,
    errorMessageUnauthorized = 'Die Anmeldung ist abgelaufen',
}) {
    const appendedHeaders = {
        'Content-Type': 'application/json',
        ...headers,
    };
    return baseRequest({
        dispatch,
        url,
        method,
        payload,
        headers: appendedHeaders,
        errorMessageUnauthorized,
    });
}

/**
 * Fetch request with authentication (Authorization header).
 * If no token is available, or the token is expired, a logout is done.
 * @param dispatch
 * @param url
 * @param method
 * @param payload
 * @param headers
 * @param errorMessageUnauthorized
 * @return {Promise<Promise<never>>|Promise<T>}
 */
export function authorizedRequest({
    dispatch,
    url,
    method,
    payload,
    headers,
    errorMessageUnauthorized = 'Die Anmeldung ist abgelaufen',
}) {
    // token not available or expired
    if (!Storage.isLoggedIn()) {
        return logout(dispatch, errorMessageUnauthorized)
            .then(() => Promise.reject());
    }

    const token = Storage.getToken();
    const appendedHeaders = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...headers,
    };

    return baseRequest({
        dispatch,
        url,
        method,
        payload,
        headers: appendedHeaders,
        errorMessageUnauthorized,
    });
}

export function getCommaSeparator(locale) {
    return (new Intl.NumberFormat(locale).format(1.11).replace(/1/g, ''));
}

export function getThousandSeparator(locale) {
    return (new Intl.NumberFormat(locale).format(1111).replace(/1/g, ''));
}

export function getLocaleNumberValidator(locale) {
    switch (locale) {
        case 'de':
            return /^\d*([,]\d{0,2})?$/;
        case 'en':
        default:
            return /^\d*([.]\d{0,2})?$/;
    }
}

export function getPositiveIntegerValidator() {
    return /^\d*$/;
}

export function isSameDay(isoString1, isoString2) {
    return moment(isoString1).isSame(moment(isoString2), 'day');
}

/**
 * Get the minutes since midnight for the given time.
 * Examples:
 * "00:00" returns 0
 * "01:30" returns 90
 * "24:00" returns 1440
 * @param {String|Date|null} time Time string or Date object, or leave empty to use current time.
 * @returns {Number} Minutes since midnight.
 */
function getDayMinutes(time = null) {
    let d;
    if (typeof time === 'string') {
        if (time === '24:00') {
            // 24:00 -> next day 00:00
            d = new Date('01/02/70 00:00');
        } else {
            d = new Date(`01/01/70 ${time}`);
        }
    } else if (time instanceof Date) {
        d = time;
    } else {
        const now = new Date();
        d = new Date(`01/01/70 ${now.getHours()}:${now.getMinutes()}`);
    }

    return (d.getDate() === 2 ? 60 * 24 : 0) + (d.getHours() * 60) + d.getMinutes();
}

/**
 * Get the opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (= sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {Array} List of opening hours
 */
export function getOpeningHoursForDay(day, openingHours) {
    if (!openingHours) {
        return [];
    }

    const hours = openingHours.find(e => e.day === DAY_ENUM[day]);
    if (!hours) {
        return [];
    } else {
        return hours.hours;
    }
}

/**
 * Get all opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {Array} List of opening hours
 */
export function getOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getOpeningHoursForDay(today, openingHours);
}

/**
 * Get the next opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (=sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Next opening hours, or null if no more opening hours for the day.
 */
export function getNextOpeningHoursForDay(day, openingHours) {
    const hours = getOpeningHoursForDay(day, openingHours);

    return _.chain(hours)
        .filter(fromTo => getDayMinutes(fromTo.from) > getDayMinutes())
        .sortBy('from')
        .head()
        .value();
}

/**
 * Get the next opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Next opening hours, or null if no more opening hours for today.
 */
export function getNextOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getNextOpeningHoursForDay(today, openingHours);
}

/**
 * Get the current opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (=sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Current opening hours, or null if no opening hours for the day.
 */
export function getCurrentOpeningHoursForDay(day, openingHours) {
    const hours = getOpeningHoursForDay(day, openingHours);

    return _.chain(hours)
        .filter(fromTo => getDayMinutes(fromTo.from) <= getDayMinutes())
        .filter(fromTo => getDayMinutes(fromTo.to) >= getDayMinutes())
        .head()
        .value();
}

/**
 * Get the current opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Current opening hours, or null if no opening hours for today.
 */
export function getCurrentOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getCurrentOpeningHoursForDay(today, openingHours);
}

/**
 * Generate a time series with given step interval and start/end as limits
 * Times are rounded to the nearest step.
 * @param {number} step step interval in minutes
 * @param {string} start start date
 * @param {string} end end date
 * @param {string} [locale] locale to convert times to. UTC if undefined/null
 * @returns {[{iso: string, short: string}]} All steps between start and end.
 */
export function generateTimeSeries(step, start, end, locale = null) {
    const series = [];
    const startDate = new Date(start);
    const endDate = new Date(end);

    startDate.setMilliseconds(0);
    startDate.setSeconds(0);
    startDate.setMinutes(Math.floor(startDate.getMinutes() / step) * step);

    endDate.setMilliseconds(0);
    endDate.setSeconds(0);
    endDate.setMinutes(Math.floor(endDate.getMinutes() / step) * step);

    while (startDate < endDate) {
        startDate.setMinutes(startDate.getMinutes() + step);

        let seriesEntry = startDate.toISOString();
        if (locale) {
            seriesEntry = moment(seriesEntry).locale(locale).format();
        }

        series.push({
            iso: seriesEntry,
            short: `${moment(seriesEntry).format('HH')}:${moment(seriesEntry).format('mm')}`,
        });
    }

    return series;
}

/**
 * Checks if the given merchant is now open, allowed to send orders.
 * @return {boolean} True if open, otherwise false.
 */
export function isOpen(openingHours) {

    if (!openingHours) {
        return false;
    }

    const hours = getOpeningHoursForToday(openingHours);

    const actual = _.chain(hours)
        .filter(fromTo => getDayMinutes(fromTo.from) <= getDayMinutes())
        .filter(fromTo => getDayMinutes(fromTo.to) > getDayMinutes())
        .head()
        .value();

    return !_.isNil(actual);
}

/**
 * Returns a new array with the given value added between each array element and the next one (except the last).
 * @param {array} array - the array for adding the values
 * @param {*} value - the value to include
 */
export function includeValueBetween(array, value) {
    const arrayWithValue = [];
    array.forEach(elem => {
        arrayWithValue.push(elem);
        arrayWithValue.push(value);
    });
    arrayWithValue.pop();
    return arrayWithValue;
}

export function formatFromTo(from, to) {
    return `${from} - ${to}`;
}

export function formatHours(hours) {
    return hours.map(h => formatFromTo(h.from, h.to)).join(', ');
}

export function formatPhoneNumber(phoneNumber) {
    return `+${phoneNumber.substr(0, 2)} ${phoneNumber.substr(2, 3)} ${phoneNumber.substr(5)}`;
}

export function formatOrderNumber(orderNumber) {
    return `${orderNumber.slice(0, 3)}-${orderNumber.slice(3, 6)}-${orderNumber.slice(6)}`;
}

export function sortOrders(orders) {
    const offset = moment.duration('00:10:00');

    return _.sortBy(orders, order => {
        if (order.pickUpCustomerAt) {
            return moment(order.pickUpCustomerAt).subtract(offset);
        } else {
            return moment(order.createdAt);
        }
    });
}

/**
 * Converts a day to a range.
 * @param {Date|Moment|string} day The day which should be converted.
 * @returns {object} The range, including the start ('from') and the end ('end') of the day.
 * Start and end are local ISO 8601 strings (including the client's timezone) converted to UTC+0 (GMT)`.
 */
export function convertDayToRange(day) {
    return {
        from: moment(day)
            .startOf('day')
            .toISOString(),
        to: moment(day)
            .endOf('day')
            .toISOString(),
    };
}

/**
 * Converts a month of a year to a range starting from the first of the month to the last.
 * @param {number} year The year of the month; four digit representation (e.g. 2018).
 * @param {number} month The month, starting at zero for January to eleven for December.
 * @returns {object} The range, including the start ('from') and the end ('end') of the month.
 * Start and end are local ISO 8601 strings (including the client's timezone) converted to UTC+0 (GMT)`.
 */
export function convertMonthToRange(year, month) {
    return {
        from: moment()
            .year(year)
            .month(month)
            .startOf('month')
            .toISOString(),
        to: moment()
            .year(year)
            .month(month)
            .endOf('month')
            .toISOString(),
    };
}

export function formatLocalizedNumber(value, locale, minimumFractionDigits = 2) {
    return Number(value)
        .toLocaleString(locale, { minimumFractionDigits, maximumFractionDigits: 2, useGrouping: false });
}

export function normalizeLocalizedNumber(value, locale) {
    const thousandSeparator = getThousandSeparator(locale);
    const commaSeparator = getCommaSeparator(locale);
    return value
        .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '') // Thousand separator can occur more than once
        .replace(commaSeparator, '.');
}

export function isProductAvailable(product) {
    return product.availableCount === undefined || product.availableCount > 0;
}
