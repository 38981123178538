import { toast } from 'react-toastify';

import Storage from 'services/Storage';
import Push from 'services/Push';
import Device from 'services/Device';
import Network from 'services/Network';
import Sound from 'services/Sound';
import BrowserNotification from 'services/BrowserNotification';
import Deeplink from 'services/Deeplink';

import { request, authorizedRequest } from 'utils';
import { API_URI } from 'config';

import { setLogin } from 'containers/Login/actions';
import {
    selectCategory,
    selectProduct,
} from 'containers/MenuCard/actions';
import {
    selectProductOptionGroup,
    selectProductOption,
} from 'containers/ProductOptions/actions';
import { checkOpenState } from 'containers/Online/action';

import {
    CORDOVA_INIT_DONE,
    UPDATE_CONNECTION_INFO,
    PUSH_NOTIFICATION_RECEIVE,
    PUSH_NOTIFICATION_CLEAR,

    FETCH_MERCHANT_DATA_ERROR,
    FETCH_MERCHANT_DATA_REQUEST,
    FETCH_MERCHANT_DATA_SUCCESS,
    UPDATE_MERCHANT_DATA_REQUEST,
    UPDATE_MERCHANT_DATA_SUCCESS,
    UPDATE_MERCHANT_DATA_ERROR,

    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,

    LOAD_API_VERSION_REQUEST,
    LOAD_API_VERSION_SUCCESS,
    LOAD_API_VERSION_ERROR,

    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,

    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_ERROR,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_ERROR,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_ERROR,

    CHANGE_CATEGORY_POSITION_REQUEST,
    CHANGE_CATEGORY_POSITION_SUCCESS,
    CHANGE_CATEGORY_POSITION_ERROR,
    CHANGE_PRODUCT_POSITION_REQUEST,
    CHANGE_PRODUCT_POSITION_SUCCESS,
    CHANGE_PRODUCT_POSITION_ERROR,

    CREATE_PRODUCT_OPTION_GROUP_REQUEST,
    CREATE_PRODUCT_OPTION_GROUP_SUCCESS,
    CREATE_PRODUCT_OPTION_GROUP_ERROR,
    UPDATE_PRODUCT_OPTION_GROUP_REQUEST,
    UPDATE_PRODUCT_OPTION_GROUP_SUCCESS,
    UPDATE_PRODUCT_OPTION_GROUP_ERROR,
    DELETE_PRODUCT_OPTION_GROUP_REQUEST,
    DELETE_PRODUCT_OPTION_GROUP_SUCCESS,
    DELETE_PRODUCT_OPTION_GROUP_ERROR,

    CREATE_PRODUCT_OPTION_REQUEST,
    CREATE_PRODUCT_OPTION_SUCCESS,
    CREATE_PRODUCT_OPTION_ERROR,
    UPDATE_PRODUCT_OPTION_REQUEST,
    UPDATE_PRODUCT_OPTION_SUCCESS,
    UPDATE_PRODUCT_OPTION_ERROR,
    DELETE_PRODUCT_OPTION_REQUEST,
    DELETE_PRODUCT_OPTION_SUCCESS,
    DELETE_PRODUCT_OPTION_ERROR,

    CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST,
    CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS,
    CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR,
    CHANGE_PRODUCT_OPTION_POSITION_REQUEST,
    CHANGE_PRODUCT_OPTION_POSITION_SUCCESS,
    CHANGE_PRODUCT_OPTION_POSITION_ERROR,

    UPDATE_DEVICE_INFORMATION_REQUEST,
    UPDATE_DEVICE_INFORMATION_SUCCESS,
    UPDATE_DEVICE_INFORMATION_ERROR,

    FETCH_CLIENT_ID_REQUEST,
    FETCH_CLIENT_ID_SUCCESS,
    FETCH_CLIENT_ID_ERROR,

    AUTHORIZE_MERCHANT_REQUEST,
    AUTHORIZE_MERCHANT_SUCCESS,
    AUTHORIZE_MERCHANT_ERROR,
} from './constants';

export function cordovaInitDone() {
    return {
        type: CORDOVA_INIT_DONE,
    };
}

export function updateConnectionInfo(isConnected) {
    return {
        type: UPDATE_CONNECTION_INFO,
        isConnected,
    };
}

export function pushNotificationReceive(pushNotification) {
    return {
        type: PUSH_NOTIFICATION_RECEIVE,
        pushNotification,
    };
}

export function pushNotificationClear() {
    return {
        type: PUSH_NOTIFICATION_CLEAR,
    };
}

/**
 * Runs the initialization of the Cordova plugins.
 * Once all plugins have been initialized, the cordovaInitDone
 * action is dispatched.
 * @return {function(*)}
 */
export function initCordova(history) {
    return dispatch => {
        Promise.all([
            Storage.init(),
            Push.init(dispatch),
            Device.init(),
            Network.init(dispatch),
            Sound.init(dispatch),
            BrowserNotification.init(),
            Deeplink.init(history),
        ]).then(() => {
            console.log('All services initialized, fetching API version');
            dispatch(loadApiVersion());
            if (Storage.isLoggedIn()) {
                console.log('Token available, verifying if token is valid');
                dispatch(setLogin());
                dispatch(verifyToken());
                dispatch(checkOpenState(true));
                // schedules the updateDeviceInformation function every day
                scheduleUpdateDevice(dispatch);
            }
            dispatch(cordovaInitDone());
        }).catch(e => {
            console.log(`Error during initialization: ${e}`);
            dispatch(cordovaInitDone());
        });
    };
}

/**
 * Schedules the update of the device information for every day.
 * @param dispatch
 */
function scheduleUpdateDevice(dispatch) {
    (function dispatchUpdate() {
        if (Storage.isLoggedIn()) {
            console.log('Send device (including Push token) to backend');
            dispatch(updateDeviceInformation());
        }
        setTimeout(dispatchUpdate, 1000 * 60 * 60 * 24); // 1 day
    }());
}

export function clearPushNotifications() {
    return dispatch => dispatch(pushNotificationClear());
}

// #region fetchMerchantData

function fetchMerchantDataRequest() {
    return {
        type: FETCH_MERCHANT_DATA_REQUEST,
    };
}

function fetchMerchantDataSuccess(data) {
    return {
        type: FETCH_MERCHANT_DATA_SUCCESS,
        data,
    };
}

function fetchMerchantDataError(error) {
    return {
        type: FETCH_MERCHANT_DATA_ERROR,
        error,
    };
}

export function fetchMerchantData() {
    return dispatch => {
        dispatch(fetchMerchantDataRequest());

        const url = new URL('/api/merchant', API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(json => {
                dispatch(fetchMerchantDataSuccess(json));
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Laden der Händlerdaten (${error.statusText})`,
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(fetchMerchantDataError(error));
            });
    };
}

// #endregion

// #region updateMerchantData

const updateMerchantDataRequest = () => ({
    type: UPDATE_MERCHANT_DATA_REQUEST,
});

const updateMerchantDataSuccess = data => ({
    type: UPDATE_MERCHANT_DATA_SUCCESS,
    data,
});

const updateMerchantDataError = error => ({
    type: UPDATE_MERCHANT_DATA_ERROR,
    error,
});

export function updateMerchantData(merchant) {
    return dispatch => {
        dispatch(updateMerchantDataRequest());

        const url = new URL('/api/merchant', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PATCH',
            payload: merchant,
        })
            .then(() => {
                toast.success('Einstellungen aktualisiert', { position: toast.POSITION.TOP_CENTER });
                const merchantUpdate = {
                    ...merchant,
                    _version: merchant._version + 1,
                };
                return dispatch(updateMerchantDataSuccess(merchantUpdate));
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Aktualisieren der Einstellungen (${error.statusText})`,
                    { position: toast.POSITION.TOP_CENTER },
                );
                dispatch(updateMerchantDataError(error));
            });
    };
}

// #endregion

// #region verifyToken

export function verifyTokenRequest() {
    return {
        type: VERIFY_TOKEN_REQUEST,
    };
}

export function verifyTokenSuccess() {
    return {
        type: VERIFY_TOKEN_SUCCESS,
    };
}

export function verifyTokenError() {
    return {
        type: VERIFY_TOKEN_ERROR,
    };
}

export function verifyToken() {
    return dispatch => {
        dispatch(verifyTokenRequest());

        const url = new URL('/api/verify', API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(() => {
                dispatch(verifyTokenSuccess());
            })
            .catch(() => {
                dispatch(verifyTokenError());
            });
    };
}

// #endregion

// #region loadApiVersion

export function loadApiVersionRequest() {
    return {
        type: LOAD_API_VERSION_REQUEST,
    };
}

export function loadApiVersionSuccess(apiVersion) {
    return {
        type: LOAD_API_VERSION_SUCCESS,
        apiVersion,
    };
}

export function loadApiVersionError(error) {
    return {
        type: LOAD_API_VERSION_ERROR,
        error,
    };
}

export function loadApiVersion() {
    return dispatch => {
        dispatch(loadApiVersionRequest());

        const url = new URL('/api/public/version', API_URI).toString();

        return request({ dispatch, url })
            .then(json => {
                dispatch(loadApiVersionSuccess(json.merchant));
            })
            .catch(error => {
                dispatch(loadApiVersionError(error));
            });
    };
}

// #endregion

// #region createCategory

const createCategoryRequest = () => ({
    type: CREATE_CATEGORY_REQUEST,
});

const createCategorySuccess = data => ({
    type: CREATE_CATEGORY_SUCCESS,
    data,
});

const createCategoryError = error => ({
    type: CREATE_CATEGORY_ERROR,
    error,
});

export function createCategory(category) {
    return dispatch => {
        dispatch(createCategoryRequest());

        const url = new URL('/api/merchant/category', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: category,
        })
            .then(json => {
                dispatch(createCategorySuccess(json));
                dispatch(selectCategory(json._id));
                toast.success(
                    `Neue Kategorie "${category.name}" angelegt.`,
                    { position: toast.POSITION.TOP_CENTER },
                );
                return json;
            })
            .catch(error => {
                dispatch(createCategoryError(error));
                toast.error(
                    'Beim Speichern ist ein Fehler aufgetreten.',
                    { position: toast.POSITION.TOP_CENTER },
                );
                throw error;
            });
    };
}

// #endregion

// #region updateCategory

const updateCategoryRequest = () => ({
    type: UPDATE_CATEGORY_REQUEST,
});

const updateCategorySuccess = data => ({
    type: UPDATE_CATEGORY_SUCCESS,
    data,
});

const updateCategoryError = error => ({
    type: UPDATE_CATEGORY_ERROR,
    error,
});

export function updateCategory(category) {
    return dispatch => {
        dispatch(updateCategoryRequest());

        const url = new URL(`/api/merchant/category/${category._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PUT',
            payload: category,
        })
            .then(json => {
                dispatch(updateCategorySuccess(json));
                toast.success('Speichern erfolgreich.', { position: toast.POSITION.TOP_CENTER });
                return json;
            })
            .catch(error => {
                dispatch(updateCategoryError(error));
                toast.error(
                    'Beim Speichern ist ein Fehler aufgetreten.',
                    { position: toast.POSITION.TOP_CENTER },
                );
                throw error;
            });
    };
}

// #endregion

// #region deleteCategory

const deleteCategoryRequest = () => ({
    type: DELETE_CATEGORY_REQUEST,
});

const deleteCategorySuccess = cid => ({
    type: DELETE_CATEGORY_SUCCESS,
    cid,
});

const deleteCategoryError = error => ({
    type: DELETE_CATEGORY_ERROR,
    error,
});

export function deleteCategory(category) {
    return dispatch => {
        dispatch(deleteCategoryRequest());

        const url = new URL(`/api/merchant/category/${category._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'DELETE',
            payload: { _version: category._version },
        })
            .then(json => {
                dispatch(deleteCategorySuccess(category._id));
                return json;
            })
            .catch(error => {
                dispatch(deleteCategoryError(error));
                throw error;
            });
    };
}

// #endregion

// #region fetchClientId

const fetchClientIdRequest = () => ({
    type: FETCH_CLIENT_ID_REQUEST,
});

const fetchClientIdSuccess = () => ({
    type: FETCH_CLIENT_ID_SUCCESS,
});

const fetchClientIdError = error => ({
    type: FETCH_CLIENT_ID_ERROR,
    error,
});

export function fetchClientId() {
    return dispatch => {
        dispatch(fetchClientIdRequest());

        const url = new URL('/api/merchant/stripe/connect', API_URI).toString();

        return authorizedRequest({ dispatch, url })
            .then(json => {
                const { clientId } = json;
                dispatch(fetchClientIdSuccess());
                return { clientId };
            })
            .catch(error => {
                dispatch(fetchClientIdError(error));
                toast.error(
                    'Die zur Authorisierung notwendige Client ID konnte nicht abgerufen werden',
                    { position: toast.POSITION.TOP_CENTER }
                );
                throw error;
            });
    };
}

// #endregion

// #region authorizeMerchant

const authorizeMerchantRequest = () => ({
    type: AUTHORIZE_MERCHANT_REQUEST,
});

const authorizeMerchantSuccess = () => ({
    type: AUTHORIZE_MERCHANT_SUCCESS,
});

const authorizeMerchantError = error => ({
    type: AUTHORIZE_MERCHANT_ERROR,
    error,
});

export function authorizeMerchant(authorizationCode) {
    return dispatch => {
        dispatch(authorizeMerchantRequest());

        const url = new URL('/api/merchant/stripe/connect', API_URI).toString();
        const body = {
            code: authorizationCode,
        };

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: body,
        })
            .then(() => {
                dispatch(authorizeMerchantSuccess());
                toast.success(
                    'Stripe wurde erfolgreich aktiviert',
                    { position: toast.POSITION.TOP_CENTER }
                );
            })
            .catch(error => {
                dispatch(authorizeMerchantError(error));
                toast.error(
                    'Bei der Authorisierung durch Stripe ist ein Fehler aufgetreten.',
                    { position: toast.POSITION.TOP_CENTER }
                );
                throw error;
            });
    };
}

// #endregion

// #region createProduct

const createProductRequest = () => ({
    type: CREATE_PRODUCT_REQUEST,
});

const createProductSuccess = data => ({
    type: CREATE_PRODUCT_SUCCESS,
    data,
});

const createProductError = error => ({
    type: CREATE_PRODUCT_ERROR,
    error,
});

export function createProduct(product) {
    return dispatch => {
        dispatch(createProductRequest());

        const url = new URL('/api/merchant/product', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: product,
        })
            .then(json => {
                dispatch(createProductSuccess(json));
                dispatch(selectProduct(json._id));
                toast.success(`Produkt "${product.name}" angelegt.`, { position: toast.POSITION.TOP_CENTER });
            })
            .catch(error => {
                dispatch(createProductError(error));
                toast.error(`Fehler beim Erstellen des Produkts (${error})`, { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region updateProduct

const updateProductRequest = () => ({
    type: UPDATE_PRODUCT_REQUEST,
});

const updateProductSuccess = data => ({
    type: UPDATE_PRODUCT_SUCCESS,
    data,
});

const updateProductError = error => ({
    type: UPDATE_PRODUCT_ERROR,
    error,
});

export function updateProduct(product) {
    return dispatch => {
        dispatch(updateProductRequest());

        const url = new URL(`/api/merchant/product/${product._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PUT',
            payload: product,
        })
            .then(json => {
                dispatch(updateProductSuccess(json));
                toast.success(`Produkt "${product.name}" aktualisiert.`, { position: toast.POSITION.TOP_CENTER });
            })
            .catch(error => {
                dispatch(updateProductError(error));
                toast.error(
                    `Fehler beim Aktualisieren des Produkts (${error})`,
                    { position: toast.POSITION.TOP_CENTER },
                );
                throw error;
            });
    };
}

// #endregion

// #region deleteProduct

const deleteProductRequest = () => ({
    type: DELETE_PRODUCT_REQUEST,
});

const deleteProductSuccess = pid => ({
    type: DELETE_PRODUCT_SUCCESS,
    pid,
});

const deleteProductError = error => ({
    type: DELETE_PRODUCT_ERROR,
    error,
});

export function deleteProduct(product) {
    return dispatch => {
        dispatch(deleteProductRequest());

        const url = new URL(`/api/merchant/product/${product._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'DELETE',
            payload: { _version: product._version },
        })
            .then(() => {
                dispatch(deleteProductSuccess(product._id));
                toast.success('Produkt gelöscht.', { position: toast.POSITION.TOP_CENTER });
            })
            .catch(error => {
                dispatch(deleteProductError(error));
                toast.error(`Fehler beim Löschen des Produkts (${error})`, { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region createProductOptionGroup

const createProductOptionGroupRequest = () => ({
    type: CREATE_PRODUCT_OPTION_GROUP_REQUEST,
});

const createProductOptionGroupSuccess = data => ({
    type: CREATE_PRODUCT_OPTION_GROUP_SUCCESS,
    data,
});

const createProductOptionGroupError = error => ({
    type: CREATE_PRODUCT_OPTION_GROUP_ERROR,
    error,
});

export function createProductOptionGroup(productOptionGroup) {
    return dispatch => {
        dispatch(createProductOptionGroupRequest());

        const url = new URL('/api/merchant/product-option-group', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: productOptionGroup,
        })
            .then(json => {
                dispatch(createProductOptionGroupSuccess(json));
                dispatch(selectProductOptionGroup(json._id));
                toast.success(`Neue Optionengruppe "${productOptionGroup.name}" angelegt.`,
                    { position: toast.POSITION.TOP_CENTER });
                return json;
            })
            .catch(error => {
                dispatch(createProductOptionGroupError(error));
                toast.error('Beim Speichern ist ein Fehler aufgetreten.', { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region updateProductOptionGroup

const updateProductOptionGroupRequest = () => ({
    type: UPDATE_PRODUCT_OPTION_GROUP_REQUEST,
});

const updateProductOptionGroupSuccess = data => ({
    type: UPDATE_PRODUCT_OPTION_GROUP_SUCCESS,
    data,
});

const updateProductOptionGroupError = error => ({
    type: UPDATE_PRODUCT_OPTION_GROUP_ERROR,
    error,
});

export function updateProductOptionGroup(productOptionGroup) {
    return dispatch => {
        dispatch(updateProductOptionGroupRequest());

        const url = new URL(`/api/merchant/product-option-group/${productOptionGroup._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PUT',
            payload: productOptionGroup,
        })
            .then(json => {
                dispatch(updateProductOptionGroupSuccess(json));
                toast.success('Speichern erfolgreich.', { position: toast.POSITION.TOP_CENTER });
                return json;
            })
            .catch(error => {
                dispatch(updateProductOptionGroupError(error));
                toast.error('Beim Speichern ist ein Fehler aufgetreten.', { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region deleteProductOptionGroup

const deleteProductOptionGroupRequest = () => ({
    type: DELETE_PRODUCT_OPTION_GROUP_REQUEST,
});

const deleteProductOptionGroupSuccess = cid => ({
    type: DELETE_PRODUCT_OPTION_GROUP_SUCCESS,
    cid,
});

const deleteProductOptionGroupError = error => ({
    type: DELETE_PRODUCT_OPTION_GROUP_ERROR,
    error,
});

export function deleteProductOptionGroup(productOptionGroup) {
    return dispatch => {
        dispatch(deleteProductOptionGroupRequest());

        const url = new URL(`/api/merchant/product-option-group/${productOptionGroup._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'DELETE',
            payload: { _version: productOptionGroup._version },
        })
            .then(json => {
                dispatch(deleteProductOptionGroupSuccess(productOptionGroup._id));
                return json;
            })
            .catch(error => {
                dispatch(deleteProductOptionGroupError(error));
                throw error;
            });
    };
}

// #endregion

// #region createProductOption

const createProductOptionRequest = () => ({
    type: CREATE_PRODUCT_OPTION_REQUEST,
});

const createProductOptionSuccess = data => ({
    type: CREATE_PRODUCT_OPTION_SUCCESS,
    data,
});

const createProductOptionError = error => ({
    type: CREATE_PRODUCT_OPTION_ERROR,
    error,
});

export function createProductOption(productOption) {
    return dispatch => {
        dispatch(createProductOptionRequest());

        const url = new URL('/api/merchant/product-option', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: productOption,
        })
            .then(json => {
                dispatch(createProductOptionSuccess(json));
                dispatch(selectProductOption(json._id));
                toast.success(`Option "${productOption.name}" angelegt.`, { position: toast.POSITION.TOP_CENTER });
            })
            .catch(error => {
                dispatch(createProductOptionError(error));
                toast.error(`Fehler beim Erstellen der Option (${error})`, { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region updateProductOption

const updateProductOptionRequest = () => ({
    type: UPDATE_PRODUCT_OPTION_REQUEST,
});

const updateProductOptionSuccess = data => ({
    type: UPDATE_PRODUCT_OPTION_SUCCESS,
    data,
});

const updateProductOptionError = error => ({
    type: UPDATE_PRODUCT_OPTION_ERROR,
    error,
});

export function updateProductOption(productOption) {
    return dispatch => {
        dispatch(updateProductOptionRequest());

        const url = new URL(`/api/merchant/product-option/${productOption._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'PUT',
            payload: productOption,
        })
            .then(json => {
                dispatch(updateProductOptionSuccess(json));
                toast.success(`Option "${productOption.name}" aktualisiert.`, { position: toast.POSITION.TOP_CENTER });
            })
            .catch(error => {
                dispatch(updateProductOptionError(error));
                toast.error(`Fehler beim Aktualisieren der Option (${error})`, { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region deleteProductOption

const deleteProductOptionRequest = () => ({
    type: DELETE_PRODUCT_OPTION_REQUEST,
});

const deleteProductOptionSuccess = pid => ({
    type: DELETE_PRODUCT_OPTION_SUCCESS,
    pid,
});

const deleteProductOptionError = error => ({
    type: DELETE_PRODUCT_OPTION_ERROR,
    error,
});

export function deleteProductOption(productOption) {
    return dispatch => {
        dispatch(deleteProductOptionRequest());

        const url = new URL(`/api/merchant/product-option/${productOption._id}`, API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'DELETE',
            payload: { _version: productOption._version },
        })
            .then(json => {
                dispatch(deleteProductOptionSuccess(productOption._id));
                toast.success('Option gelöscht.', { position: toast.POSITION.TOP_CENTER });
                return json;
            })
            .catch(error => {
                dispatch(deleteProductOptionError(error));
                toast.error(`Fehler beim Löschen der Option (${error})`, { position: toast.POSITION.TOP_CENTER });
                throw error;
            });
    };
}

// #endregion

// #region changeCategoryPosition

const changeCategoryPositionRequest = () => ({
    type: CHANGE_CATEGORY_POSITION_REQUEST,
});

const changeCategoryPositionSuccess = (category, position, _version) => ({
    type: CHANGE_CATEGORY_POSITION_SUCCESS,
    category,
    position,
    _version,
});

const changeCategoryPositionError = error => ({
    type: CHANGE_CATEGORY_POSITION_ERROR,
    error,
});

/**
 * Changes the position of a category
 *
 * @param {object} category - the category to move
 * @param {number} position - the new absolute position
 * @param {number} merchantVersion - the merchant version
 * @return {Promise<Function>}
 */
export function changeCategoryPosition(category, position, merchantVersion) {
    return dispatch => {
        dispatch(changeCategoryPositionRequest());

        const url = new URL('/api/merchant/category-position', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: { categoryId: category._id, position, merchantVersion },
        })
            .then(() => {
                const _version = merchantVersion + 1;
                dispatch(changeCategoryPositionSuccess(category, position, _version));
            })
            .catch(error => {
                dispatch(changeCategoryPositionError(error));
                toast.error(
                    'Fehler beim Sortieren der Kategorien (Erneutes laden der Daten erforderlich)',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });
    };
}

// #endregion

// #region changeProductPosition

const changeProductPositionRequest = () => ({
    type: CHANGE_PRODUCT_POSITION_REQUEST,
});

const changeProductPositionSuccess = (product, position, _version) => ({
    type: CHANGE_PRODUCT_POSITION_SUCCESS,
    product,
    position,
    _version,
});

const changeProductPositionError = error => ({
    type: CHANGE_PRODUCT_POSITION_ERROR,
    error,
});

/**
 * Changes the position of a product
 *
 * @param {object} product - the product to move
 * @param {number} position - the new absolute position
 * @param {number} merchantVersion - the merchant version
 * @return {Promise<Function>}
 */
export function changeProductPosition(product, position, merchantVersion) {
    return dispatch => {
        dispatch(changeProductPositionRequest());

        const url = new URL('/api/merchant/product-position', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: { productId: product._id, position, merchantVersion },
        })
            .then(() => {
                const _version = merchantVersion + 1;
                dispatch(changeProductPositionSuccess(product, position, _version));
            })
            .catch(error => {
                dispatch(changeProductPositionError(error));
                toast.error(
                    'Fehler beim Sortieren der Produkte (Erneutes laden der Daten erforderlich)',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });
    };
}

// #endregion

// #region changeProductOPtionGroupPosition

const changeProductOptionGroupPositionRequest = () => ({
    type: CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST,
});

const changeProductOptionGroupPositionSuccess = (productOptionGroup, position, _version) => ({
    type: CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS,
    productOptionGroup,
    position,
    _version,
});

const changeProductOptionGroupPositionError = error => ({
    type: CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR,
    error,
});

/**
 * Changes the position of a product option group
 *
 * @param {object} productOptionGroup - the product option group to move
 * @param {number} position - the new absolute position
 * @param {number} merchantVersion - the merchant version
 * @return {Promise<Function>}
 */
export function changeProductOptionGroupPosition(productOptionGroup, position, merchantVersion) {
    return dispatch => {
        dispatch(changeProductOptionGroupPositionRequest());

        const url = new URL('/api/merchant/product-option-group-position', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: { productOptionGroupId: productOptionGroup._id, position, merchantVersion },
        })
            .then(() => {
                const _version = merchantVersion + 1;
                dispatch(changeProductOptionGroupPositionSuccess(productOptionGroup, position, _version));
            })
            .catch(error => {
                dispatch(changeProductOptionGroupPositionError(error));
                toast.error(
                    'Fehler beim Sortieren der Optionengruppen (Erneutes laden der Daten erforderlich)',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });
    };
}

// #endregion

// #region changeProductOptionPosition

const changeProductOptionPositionRequest = () => ({
    type: CHANGE_PRODUCT_OPTION_POSITION_REQUEST,
});

const changeProductOptionPositionSuccess = (productOption, position, _version) => ({
    type: CHANGE_PRODUCT_OPTION_POSITION_SUCCESS,
    productOption,
    position,
    _version,
});

const changeProductOptionPositionError = error => ({
    type: CHANGE_PRODUCT_OPTION_POSITION_ERROR,
    error,
});

/**
 * Changes the position of a product option
 *
 * @param {object} productOption - the product option to move
 * @param {number} position - the new absolute position
 * @param {number} merchantVersion - the merchant version
 * @return {function(*): *}
 */
export function changeProductOptionPosition(productOption, position, merchantVersion) {
    return dispatch => {
        dispatch(changeProductOptionPositionRequest());

        const url = new URL('/api/merchant/product-option-position', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: { productOptionId: productOption._id, position, merchantVersion },
        })
            .then(() => {
                const _version = merchantVersion + 1;
                dispatch(changeProductOptionPositionSuccess(productOption, position, _version));
            })
            .catch(error => {
                dispatch(changeProductOptionPositionError(error));
                toast.error(
                    'Fehler beim Sortieren der Optionen (Erneutes laden der Daten erforderlich)',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });
    };
}

// #endregion

// #region updateDeviceInformation

export function updateDeviceInformationRequest() {
    return {
        type: UPDATE_DEVICE_INFORMATION_REQUEST,
    };
}

export function updateDeviceInformationSuccess() {
    return {
        type: UPDATE_DEVICE_INFORMATION_SUCCESS,
    };
}

export function updateDeviceInformationError(error) {
    return {
        type: UPDATE_DEVICE_INFORMATION_ERROR,
        error,
    };
}

export function updateDeviceInformation() {
    return dispatch => {
        if (!Device.isDevice()) {
            console.info('Updating of device information skipped, no mobile device');
            return Promise.resolve();
        }

        dispatch(updateDeviceInformationRequest());

        const url = new URL('/api/merchant/device', API_URI).toString();

        const deviceInfo = {
            ...Device.getDeviceInfo(),
            token: Push.getPushToken(),
            locale: Storage.getLocale(),
        };

        return authorizedRequest({
            dispatch,
            url,
            method: 'PUT',
            payload: deviceInfo,
        })
            .then(() => {
                dispatch(updateDeviceInformationSuccess());
            })
            .catch(error => {
                dispatch(updateDeviceInformationError(error));
            });
    };
}

// #endregion
