/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import './styles.css';


function Toggle(props) {
    // We can't pass CSS variables to Switch.
    const onColor = window.getComputedStyle(document.documentElement).getPropertyValue('--green').trim();
    const offColor = window.getComputedStyle(document.documentElement).getPropertyValue('--red').trim();

    const { label, id } = props;
    const switchProps = {
        onColor,
        offColor,
    };

    let content;

    if (label) {
        content = (
            <label htmlFor={id}>
                <Switch
                    {...props}
                    {...switchProps}
                    className="toggle"
                />
                <span className="toggle-label-text">{label}</span>
            </label>
        );
    } else {
        content = (
            <Switch
                {...props}
                {...switchProps}
                className="toggle"
            />
        );
    }

    return (
        <div className="toggle-vert-align-outer">
            <div className="toggle-vert-align-inner">
                {content}
            </div>
        </div>
    );
}

Toggle.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Toggle;
