import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './styles.css';

function OrderStateButton({ isConnected, onClick }) {
    const color = isConnected ? 'success' : 'warning';
    const text = isConnected ? 'Verbunden' : 'Getrennt';

    return (
        <Button
            size="sm"
            className="reload-button"
            color={color}
            onClick={onClick}
        >
            <i className="fa fa-refresh" /> {text}
        </Button>
    );
}

OrderStateButton.propTypes = {
    isConnected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default OrderStateButton;
