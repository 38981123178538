/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
    component: Component,
    loggedIn,
    showQueueMonitor,
    scrollElement,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            if (loggedIn === true) {
                // kiosk mode with wrong path
                if (showQueueMonitor && rest.path !== '/queue-monitor') {
                    return <Redirect to={{ pathname: '/queue-monitor', state: { from: props.location } }} />;
                }

                // non-kiosk mode with queue monitor path
                if (!showQueueMonitor && rest.path === '/queue-monitor') {
                    return <Redirect to={{ pathname: '/monitor', state: { from: props.location } }} />;
                }

                return scrollElement
                    ? <Component {...props} scrollElement={scrollElement} />
                    : <Component {...props} />;
            }

            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
    />
);

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    showQueueMonitor: PropTypes.bool.isRequired,
    scrollElement: PropTypes.any,
    // PropTypes copied from <Route>
    computedMatch: PropTypes.object,
    path: PropTypes.string,
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: PropTypes.any,
    render: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    location: PropTypes.object,
};

export default PrivateRoute;
