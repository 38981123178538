import { toast } from 'react-toastify';

import { API_URI } from 'config';
import { authorizedRequest } from 'utils';

import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_ERROR,
    SET_CART,
    SET_NOTE,
    SET_OWN_PACKAGING,
    SET_SELECTED_CATEGORY_ID,
    RESET_CART,
    SET_PICK_UP_TIME,
} from './constants';

// #region action creators

export function createOrderRequest() {
    return {
        type: CREATE_ORDER_REQUEST,
    };
}

export function createOrderSuccess(response) { // TODO: TBD Do we actually need the response?
    return {
        type: CREATE_ORDER_SUCCESS,
        response,
    };
}

export function createOrderError(error) {
    return {
        type: CREATE_ORDER_ERROR,
        error,
    };
}

export function setCart(cart) {
    return {
        type: SET_CART,
        cart,
    };
}

export function setNote(note) {
    return {
        type: SET_NOTE,
        note,
    };
}

export function setOwnPackaging(ownPackaging) {
    return {
        type: SET_OWN_PACKAGING,
        ownPackaging,
    };
}

export function setSelectedCategoryId(id) {
    return {
        type: SET_SELECTED_CATEGORY_ID,
        id,
    };
}

export function setPickUpTime(pickUpTime) {
    return {
        type: SET_PICK_UP_TIME,
        pickUpTime,
    };
}

export function resetCart() {
    return {
        type: RESET_CART,
    };
}

// #endregion

// #region dispatcher functions

export function createOrder(order) {
    return dispatch => {
        dispatch(createOrderRequest());

        const url = new URL('/api/merchant/order', API_URI).toString();

        return authorizedRequest({
            dispatch,
            url,
            method: 'POST',
            payload: order,
        })
            .then(json => {
                toast.success(
                    'Bestellung erfolgreich erstellt',
                    { position: toast.POSITION.TOP_CENTER, toastId: 'create.order.success' }
                );
                dispatch(createOrderSuccess(json));
                dispatch(resetCart());
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Erstellen der Bestellung (${error.message})`,
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(createOrderError(error));
                throw error;
            });
    };
}

// #endregion
