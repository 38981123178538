import Storage from 'services/Storage';
import { CHANGE_LOCALE } from './constants';

const initialState = {
    locale: Storage.getLocale(),
};

function languageProviderReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        default:
            return state;
    }
}

export default languageProviderReducer;
