import moment from 'moment';
import { normalize } from 'normalizr';
import { toast } from 'react-toastify';

import File from 'services/File';
import FileOpener from 'services/FileOpener';
import { API_URI } from 'config';
import { CONTENT_TYPE_ENUM, authorizedRequest } from 'utils';

import { order } from './schema';
import {
    SET_FILTERS,
    FETCH_ORDERS_REQUEST,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_ERROR,
    DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS,
    DOWNLOAD_FILE_ERROR,
} from './constants';

export const setFilters = filters => ({
    type: SET_FILTERS,
    filters,
});

export const fetchOrdersRequest = () => ({
    type: FETCH_ORDERS_REQUEST,
});

export const fetchOrdersSuccess = payload => ({
    type: FETCH_ORDERS_SUCCESS,
    payload,
});

export const fetchOrdersError = error => ({
    type: FETCH_ORDERS_ERROR,
    error,
});

export const fetchOrders = (from, to) => {
    const params = new URLSearchParams();

    if (from && to) {
        params.set('from', moment(from).toISOString());
        params.set('to', moment(to).toISOString());
    }

    return dispatch => {
        dispatch(fetchOrdersRequest());

        const url = new URL(`/api/merchant/order?${params.toString()}`, API_URI).toString();

        authorizedRequest({ dispatch, url })
            .then(json => {
                dispatch(fetchOrdersSuccess(normalize(json, [order])));
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Laden der Bestellungen (${error.message})`,
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(fetchOrdersError(error));
                console.log(error);
            });
    };
};

/**
 * Enables file downloads with AJAX calls by saving the data as blob and generating a clickable link
 * to download the file.
 * @param {Blob} data The data which was downloaded by the AJAX call.
 * @param {string} fileName Filename.
 */
function downloadFile(data, fileName) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const { cordova } = window;

    if (cordova.platformId === 'browser') {
        try {
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                window.navigator.msSaveBlob(blob, fileName);
            } else {
                const blobURL = window.URL.createObjectURL(blob);
                const tempLink = document.createElement('a');
                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', fileName);

                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);
            }
        } catch (err) {
            toast.error(
                'Fehler beim Speichern der Datei',
                { position: toast.POSITION.TOP_CENTER }
            );
        }
    } else if (cordova.platformId === 'ios') {
        /*
         * Files private to the app, but that are meaningful to other application,
         * for example office files.
         */
        const path = cordova.file.documentsDirectory;

        File.saveFileInDirectory(path, fileName, blob)
            .then(fileURL => {
                FileOpener.open(fileURL, CONTENT_TYPE_ENUM.EXCEL)
                    .catch(() => {
                        //
                    });
            })
            .catch(() => {
                toast.error(
                    'Fehler beim Speichern der Datei',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });

    } else if (cordova.platformId === 'android') {
        /*
         * Android doesn't know the documents directory.
         * This is the default download location from the system browser.
         * Maps to file:///storage/emulated/0/Download
         */
        const path = `${cordova.file.externalRootDirectory}/Download`;

        File.saveFileInDirectory(path, fileName, blob)
            .then(fileURL => {
                FileOpener.showDownloadFinished(fileName, CONTENT_TYPE_ENUM.EXCEL, fileURL, blob.size)
                    .catch(() => {
                        //
                    });
            })
            .catch(() => {
                toast.error(
                    'Fehler beim Speichern der Datei',
                    { position: toast.POSITION.TOP_CENTER }
                );
            });
    }
}

export const fetchExcelRequest = () => ({
    type: DOWNLOAD_FILE_REQUEST,
});

export const fetchExcelSuccess = () => ({
    type: DOWNLOAD_FILE_SUCCESS,
});

export const fetchExcelError = error => ({
    type: DOWNLOAD_FILE_ERROR,
    error,
});

export const fetchExcel = (from, to) => {
    const params = new URLSearchParams();
    let fileName = 'Bestellungen';

    if (from && to) {
        params.set('from', moment(from).toISOString());
        params.set('to', moment(to).toISOString());
        fileName += `-${moment(from).format('YYYY-MM')}`;
    }

    // add timestamp in seconds so that the file is not overwritten
    fileName += `-${Math.floor(new Date().getTime() / 1000)}.xlsx`;

    return dispatch => {
        dispatch(fetchExcelRequest());

        const url = new URL(`/api/merchant/order/download?${params.toString()}`, API_URI).toString();

        authorizedRequest({
            dispatch,
            url,
            headers: { Accept: CONTENT_TYPE_ENUM.EXCEL },
        })
            .then(blob => {
                downloadFile(blob, fileName);
                dispatch(fetchExcelSuccess());
            })
            .catch(error => {
                toast.error(
                    `Fehler beim Laden der Datei (${error.message})`,
                    { position: toast.POSITION.TOP_CENTER }
                );
                dispatch(fetchExcelError(error.message));
                console.log(error);
            });
    };
};
