import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MenuCardCategoryItem from 'components/MenuCardCategoryItem';
import MenuCardColumnButton from 'components/MenuCardColumnButton';
import MenuCardProductItem from 'components/MenuCardProductItem';
import CreateEditProductOptionGroupForm from 'components/CreateEditProductOptionGroupForm';
import CreateEditProductOptionForm from 'components/CreateEditProductOptionForm';
import DeleteDialog from 'components/DeleteDialog';

import {
    changeProductOptionGroupPosition as changeProductOptionGroupPositionAction,
    changeProductOptionPosition as changeProductOptionPositionAction,
    createProductOptionGroup as createProductOptionGroupAction,
    deleteProductOptionGroup as deleteProductOptionGroupAction,
    updateProductOptionGroup as updateProductOptionGroupAction,
    createProductOption as createProductOptionAction,
    deleteProductOption as deleteProductOptionAction,
    updateProductOption as updateProductOptionAction,
} from 'containers/App/actions';

import {
    selectLocale,
    selectMerchant,
    selectProductOptionGroups,
    selectProductOptions,
    selectSelectedProductOptionGroup,
    selectSelectedProductOption,
} from 'containers/App/selectors';

import {
    selectProductOptionGroup as selectProductOptionGroupAction,
    selectProductOption as selectProductOptionAction,
} from './actions';

import './styles.css';

class ProductOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newProductOptionGroup: false,
            newProductOption: false,
            productOptionGroupFormPristine: true,
            productOptionFormPristine: true,
            dialogContext: {
                selectedProductOptionGroup: null,
                selectedProductOption: null,
            },
            isClearProductOptionGroupFormDataDialogOpen: false,
            isClearProductOptionFormDataDialogOpen: false,
            forceClearProductOptionGroupForm: false,
            forceClearProductOptionForm: false,
        };

        this.handleMoveProductOptionGroupPositionDown = this.handleMoveProductOptionGroupPositionDown.bind(this);
        this.handleMoveProductOptionGroupPositionUp = this.handleMoveProductOptionGroupPositionUp.bind(this);
        this.handleMoveProductOptionPositionDown = this.handleMoveProductOptionPositionDown.bind(this);
        this.handleMoveProductOptionPositionUp = this.handleMoveProductOptionPositionUp.bind(this);

        this.confirmChangeProductOptionGroup = this.confirmChangeProductOptionGroup.bind(this);
        this.confirmChangeProductOption = this.confirmChangeProductOption.bind(this);

        this.handleClickProductOptionGroup = this.handleClickProductOptionGroup.bind(this);
        this.handleClickProductOption = this.handleClickProductOption.bind(this);

        this.handleChangeProductOptionGroup = this.handleChangeProductOptionGroup.bind(this);
        this.handleCreateProductOptionGroup = this.handleCreateProductOptionGroup.bind(this);
        this.handleUpdateProductOptionGroup = this.handleUpdateProductOptionGroup.bind(this);
        this.handleDeleteProductOptionGroup = this.handleDeleteProductOptionGroup.bind(this);

        this.handleChangeProductOption = this.handleChangeProductOption.bind(this);
        this.handleCreateProductOption = this.handleCreateProductOption.bind(this);
        this.handleUpdateProductOption = this.handleUpdateProductOption.bind(this);
        this.handleDeleteProductOption = this.handleDeleteProductOption.bind(this);

        this.handleClickNewProductOptionGroup = this.handleClickNewProductOptionGroup.bind(this);
        this.handleClickNewProductOption = this.handleClickNewProductOption.bind(this);
        this.handleNewProductOptionGroup = this.handleNewProductOptionGroup.bind(this);
        this.handleNewProductOption = this.handleNewProductOption.bind(this);

        this.toggleClearProductOptionGroupFormDataDialog = this.toggleClearProductOptionGroupFormDataDialog.bind(this);
        this.toggleClearProductOptionFormDataDialog = this.toggleClearProductOptionFormDataDialog.bind(this);
        this.handleClearProductOptionGroupFormData = this.handleClearProductOptionGroupFormData.bind(this);
        this.handleClearProductOptionFormData = this.handleClearProductOptionFormData.bind(this);
    }

    confirmChangeProductOptionGroup(productOptionGroup) {

        if (!this.state.productOptionFormPristine || !this.state.productOptionGroupFormPristine) {
            this.setState({
                isClearProductOptionGroupFormDataDialogOpen: true,
                dialogContext: {
                    selectedProductOptionGroup: productOptionGroup,
                },
            });
        } else {
            this.handleClickProductOptionGroup(productOptionGroup);
        }
    }

    confirmChangeProductOption(productOption) {
        if (!this.state.productOptionFormPristine) {
            this.setState({
                isClearProductOptionFormDataDialogOpen: true,
                dialogContext: {
                    selectedProductOption: productOption,
                },
            });
        } else {
            this.handleClickProductOption(productOption);
        }
    }

    handleClickProductOptionGroup(productOptionGroup) {
        this.props.selectProductOptionGroup(productOptionGroup._id);
        this.props.selectProductOption(null);
        this.setState({
            newProductOptionGroup: false,
            newProductOption: false,
            productOptionGroupFormPristine: true,
            productOptionFormPristine: true,
        });
    }

    handleClickProductOption(productOption) {
        this.props.selectProductOption(productOption._id);
        this.setState({
            newProductOption: false,
            productOptionFormPristine: true,
        });
    }

    handleClickNewProductOptionGroup(event) {
        if (!this.state.productOptionGroupFormPristine) {
            this.setState({
                isClearProductOptionGroupFormDataDialogOpen: true,
            });
        } else {
            this.handleNewProductOptionGroup();
        }

        event.preventDefault();
    }

    handleNewProductOptionGroup() {
        this.props.selectProductOptionGroup(null);
        this.props.selectProductOption(null);
        this.setState({
            newProductOptionGroup: true,
            productOptionGroupFormPristine: true,
        });
    }

    handleClickNewProductOption(event) {
        if (!this.state.productOptionFormPristine) {
            this.setState({
                isClearProductOptionFormDataDialogOpen: true,
            });
        } else {
            this.handleNewProductOption();
        }
        event.preventDefault();
    }

    handleNewProductOption() {
        this.props.selectProductOption(null);
        this.setState({
            newProductOption: true,
            productOptionFormPristine: true,
        });
    }

    handleChangeProductOptionGroup() {
        this.setState({
            productOptionGroupFormPristine: false,
            forceClearProductOptionGroupForm: false,
        });
    }

    handleCreateProductOptionGroup(productOptionGroup) {
        return this.props.createProductOptionGroup(productOptionGroup).then(() => {
            this.setState({
                newProductOptionGroup: false,
                productOptionGroupFormPristine: true,
            });
        });
    }

    handleUpdateProductOptionGroup(productOptionGroup) {
        return this.props.updateProductOptionGroup(productOptionGroup).then(() => {
            this.setState({
                newProductOptionGroup: false,
                productOptionGroupFormPristine: true,
            });
        });
    }

    handleDeleteProductOptionGroup(productOptionGroup) {
        if (productOptionGroup) {
            this.props.deleteProductOptionGroup(productOptionGroup);
        }
        this.setState({
            newProductOptionGroup: false,
            productOptionGroupFormPristine: true,
        });
    }

    handleChangeProductOption() {
        this.setState({
            productOptionFormPristine: false,
            forceClearProductOptionForm: false,
        });
    }

    handleCreateProductOption(productOption) {
        return this.props.createProductOption(productOption).then(() => {
            this.setState({
                newProductOption: false,
                productOptionFormPristine: true,
            });
        });
    }

    handleUpdateProductOption(productOption) {
        return this.props.updateProductOption(productOption).then(() => {
            this.setState({
                newProductOption: false,
                productOptionFormPristine: true,
            });
        });
    }

    handleDeleteProductOption(productOption) {
        if (productOption) {
            this.props.deleteProductOption(productOption);
        }
        this.setState({
            newProductOption: false,
            productOptionFormPristine: true,
        });
    }

    handleMoveProductOptionGroupPositionDown(productOptionGroup) {
        const {
            productOptionGroups,
            changeProductOptionGroupPosition,
            merchant,
        } = this.props;

        const index = productOptionGroups.findIndex(elem => elem._id === productOptionGroup._id);
        if (index < productOptionGroups.length - 1) {
            changeProductOptionGroupPosition(productOptionGroup, index + 1, merchant._version);
        }
    }

    handleMoveProductOptionGroupPositionUp(productOptionGroup) {
        const {
            productOptionGroups,
            changeProductOptionGroupPosition,
            merchant,
        } = this.props;

        const index = productOptionGroups.findIndex(elem => elem._id === productOptionGroup._id);
        if (index > 0) {
            changeProductOptionGroupPosition(productOptionGroup, index - 1, merchant._version);
        }
    }


    handleMoveProductOptionPositionDown(productOption) {
        const {
            productOptions,
            selectedProductOptionGroup,
            changeProductOptionPosition,
            merchant,
        } = this.props;

        const index = productOptions.findIndex(elem => elem._id === productOption._id);

        if (index < productOptions.length - 1) {

            const productOptionsFilteredByProductOptionGroup = productOptions
                .filter(elem => elem.optionGroup === selectedProductOptionGroup._id);

            const indexFilteredByProductOptionGroup = productOptionsFilteredByProductOptionGroup.findIndex(
                elem => elem._id === productOption._id
            );

            if (indexFilteredByProductOptionGroup < productOptionsFilteredByProductOptionGroup.length - 1) {
                const realIndexOfFollowingProductOption = productOptions.findIndex(
                    elem => elem._id ===
                        productOptionsFilteredByProductOptionGroup[indexFilteredByProductOptionGroup + 1]._id
                );
                changeProductOptionPosition(productOption, realIndexOfFollowingProductOption, merchant._version);
            }
        }
    }

    handleMoveProductOptionPositionUp(productOption) {
        const {
            productOptions,
            selectedProductOptionGroup,
            changeProductOptionPosition,
            merchant,
        } = this.props;

        const index = productOptions.findIndex(elem => elem._id === productOption._id);

        if (index > 0) {

            const productOptionsFilteredByProductOptionGroup = productOptions
                .filter(elem => elem.optionGroup === selectedProductOptionGroup._id);

            const indexFilteredByProductOptionGroup = productOptionsFilteredByProductOptionGroup.findIndex(
                elem => elem._id === productOption._id
            );

            if (indexFilteredByProductOptionGroup > 0) {
                const realIndexOfPreviousProductOption = productOptions
                    .findIndex(
                        elem => elem._id ===
                            productOptionsFilteredByProductOptionGroup[indexFilteredByProductOptionGroup - 1]._id
                    );
                changeProductOptionPosition(productOption, realIndexOfPreviousProductOption, merchant._version);
            }
        }
    }

    toggleClearProductOptionGroupFormDataDialog() {
        this.setState(state => ({
            isClearProductOptionGroupFormDataDialogOpen: !state.isClearProductOptionGroupFormDataDialogOpen,
            dialogContext: {
                selectedProductOptionGroup: null,
            },
        }));
    }

    toggleClearProductOptionFormDataDialog() {
        this.setState(state => ({
            isClearProductOptionFormDataDialogOpen: !state.isClearProductOptionFormDataDialogOpen,
            dialogContext: {
                selectedProductOption: null,
            },
        }));
    }

    handleClearProductOptionGroupFormData(event) {

        const { selectedProductOptionGroup } = this.state.dialogContext;

        if (selectedProductOptionGroup) {
            this.handleClickProductOptionGroup(selectedProductOptionGroup);
        } else {
            this.setState({
                forceClearProductOptionGroupForm: true,
            });
            this.handleNewProductOptionGroup();
        }

        event.preventDefault();
    }

    handleClearProductOptionFormData(event) {

        const { selectedProductOption } = this.state.dialogContext;

        if (selectedProductOption) {
            this.handleClickProductOption(selectedProductOption);
        } else {
            this.setState({
                forceClearProductOptionForm: true,
            });
            this.handleNewProductOption();
        }

        event.preventDefault();
    }

    render() {
        const {
            locale,
            merchant,
            productOptionGroups,
            productOptions,
            selectedProductOptionGroup,
            selectedProductOption,
        } = this.props;

        const {
            newProductOptionGroup,
            newProductOption,
            isClearProductOptionGroupFormDataDialogOpen,
            isClearProductOptionFormDataDialogOpen,
            forceClearProductOptionGroupForm,
            forceClearProductOptionForm,
        } = this.state;

        if (!merchant) {
            return null;
        }

        const showProductOptionGroupCol = (selectedProductOptionGroup != null || newProductOptionGroup);
        const showProductOptionCol = showProductOptionGroupCol && (selectedProductOption != null || newProductOption);

        return (
            <Row noGutters className="h-100 w-100">
                <Col
                    className="flex-column border-right pt-3 h-100"
                    style={{ display: 'flex' }}
                >
                    <div className="flex-grow-0 p-2">
                        <h4>Optionengruppen</h4>
                    </div>
                    <div className="flex-grow-1 h-100 overflow-auto px-2" role="listbox">
                        {productOptionGroups
                            .map((productOptionGroup, index) => (
                                <MenuCardCategoryItem
                                    key={productOptionGroup._id}
                                    category={{
                                        ...productOptionGroup,
                                        name: productOptionGroup.nameInternal || productOptionGroup.name,
                                        enabled: true,
                                    }}
                                    numberOfProducts={
                                        productOptions.filter(
                                            productOption => productOption.optionGroup === productOptionGroup._id
                                        ).length
                                    }
                                    selected={
                                        selectedProductOptionGroup
                                            && selectedProductOptionGroup._id === productOptionGroup._id
                                    }
                                    hideArrowUp={index === 0}
                                    hideArrowDown={index === productOptionGroup.length - 1}
                                    onClick={() => this.confirmChangeProductOptionGroup(productOptionGroup)}
                                    onArrowDownClick={
                                        () => this.handleMoveProductOptionGroupPositionDown(productOptionGroup)
                                    }
                                    onArrowUpClick={
                                        () => this.handleMoveProductOptionGroupPositionUp(productOptionGroup)
                                    }
                                />
                            ))
                        }
                    </div>
                    <MenuCardColumnButton
                        onClick={this.handleClickNewProductOptionGroup}
                        value="Optionengruppe hinzufügen"
                    />
                </Col>

                <Col
                    className="flex-column border-right pt-3 h-100"
                    style={{ display: showProductOptionGroupCol ? 'flex' : 'none' }}
                >
                    <CreateEditProductOptionGroupForm
                        new={newProductOptionGroup}
                        productOptionGroup={selectedProductOptionGroup}
                        forceClearFormData={forceClearProductOptionGroupForm}
                        onChange={this.handleChangeProductOptionGroup}
                        onCreate={this.handleCreateProductOptionGroup}
                        onUpdate={this.handleUpdateProductOptionGroup}
                        onDelete={this.handleDeleteProductOptionGroup}
                    />
                    <div className="flex-grow-1 overflow-auto p-2" role="listbox">
                        {selectedProductOptionGroup && productOptions
                            .filter(
                                productOption => productOption.optionGroup === selectedProductOptionGroup._id
                            )
                            .map((productOption, index, arr) => (
                                <MenuCardProductItem
                                    key={productOption._id}
                                    locale={locale}
                                    product={{
                                        ...productOption,
                                        price: productOption.priceAdditional,
                                        available: true,
                                    }}
                                    selected={
                                        selectedProductOption && selectedProductOption._id === productOption._id
                                    }
                                    hideArrowUp={index === 0}
                                    hideArrowDown={index === arr.length - 1}
                                    onClick={() => this.confirmChangeProductOption(productOption)}
                                    onArrowUpClick={() => this.handleMoveProductOptionPositionUp(productOption)}
                                    onArrowDownClick={() => this.handleMoveProductOptionPositionDown(productOption)}
                                />
                            ))
                        }
                    </div>
                    <MenuCardColumnButton
                        onClick={this.handleClickNewProductOption}
                        value="Option hinzufügen"
                        disabled={newProductOptionGroup}
                    />
                </Col>

                <Col
                    className="product-form-col flex-column border-right pt-3 h-100"
                    style={{ display: showProductOptionCol ? 'flex' : 'none', overflow: 'auto' }}
                >
                    <CreateEditProductOptionForm
                        locale={locale}
                        new={newProductOption}
                        productOption={selectedProductOption}
                        productOptionGroup={selectedProductOptionGroup}
                        forceClearFormData={forceClearProductOptionForm}
                        optionGroups={merchant.productOptionGroups}
                        onChange={this.handleChangeProductOption}
                        onCreate={this.handleCreateProductOption}
                        onUpdate={this.handleUpdateProductOption}
                        onDelete={this.handleDeleteProductOption}
                    />
                </Col>

                {!showProductOptionCol &&
                    <Col
                        className="border-left d-flex justify-content-center align-items-center"
                        style={{ flexGrow: showProductOptionGroupCol ? 1 : 2 }}
                    >
                        {showProductOptionGroupCol ?
                            <span>
                                {selectedProductOptionGroup && productOptions
                                    .filter(productOption => (
                                        productOption.productOptionGroup === selectedProductOptionGroup._id
                                    )).length
                                    ? 'Bitte wählen Sie eine Option aus.'
                                    : 'Keine Optionen vorhanden.'
                                }
                            </span> :
                            <span>
                                {productOptionGroups.length
                                    ? 'Bitte wählen Sie eine Optionengruppe aus.'
                                    : 'Keine Optionengruppen vorhanden.'
                                }
                            </span>
                        }
                    </Col>
                }

                <DeleteDialog
                    isOpen={isClearProductOptionGroupFormDataDialogOpen}
                    onConfirm={this.handleClearProductOptionGroupFormData}
                    toggle={this.toggleClearProductOptionGroupFormDataDialog}
                    object={{
                        header: 'Änderungen verwerfen',
                        body: 'Nicht gespeicherte Änderungen wieder verwerfen?',
                        confirmButtonText: 'Änderungen verwerfen',
                    }}
                />

                <DeleteDialog
                    isOpen={isClearProductOptionFormDataDialogOpen}
                    onConfirm={this.handleClearProductOptionFormData}
                    toggle={this.toggleClearProductOptionFormDataDialog}
                    object={{
                        header: 'Änderungen verwerfen',
                        body: 'Nicht gespeicherte Änderungen wieder verwerfen?',
                        confirmButtonText: 'Änderungen verwerfen',
                    }}
                />

            </Row>
        );
    }

}

ProductOptions.propTypes = {
    locale: PropTypes.string.isRequired,
    merchant: PropTypes.object,
    productOptionGroups: PropTypes.array,
    productOptions: PropTypes.array,
    selectedProductOptionGroup: PropTypes.object,
    selectedProductOption: PropTypes.object,
    changeProductOptionGroupPosition: PropTypes.func.isRequired,
    changeProductOptionPosition: PropTypes.func.isRequired,
    createProductOptionGroup: PropTypes.func.isRequired,
    updateProductOptionGroup: PropTypes.func.isRequired,
    deleteProductOptionGroup: PropTypes.func.isRequired,
    selectProductOptionGroup: PropTypes.func.isRequired,
    createProductOption: PropTypes.func.isRequired,
    updateProductOption: PropTypes.func.isRequired,
    deleteProductOption: PropTypes.func.isRequired,
    selectProductOption: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        locale: selectLocale(state),
        merchant: selectMerchant(state),
        productOptionGroups: selectProductOptionGroups(state),
        productOptions: selectProductOptions(state),
        selectedProductOptionGroup: selectSelectedProductOptionGroup(state),
        selectedProductOption: selectSelectedProductOption(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createProductOptionGroup: productOptionGroup => dispatch(createProductOptionGroupAction(productOptionGroup)),
        updateProductOptionGroup: productOptionGroup => dispatch(updateProductOptionGroupAction(productOptionGroup)),
        deleteProductOptionGroup: productOptionGroupId => dispatch(
            deleteProductOptionGroupAction(productOptionGroupId)
        ),
        selectProductOptionGroup: productOptionGroupId => dispatch(
            selectProductOptionGroupAction(productOptionGroupId)
        ),
        createProductOption: productOption => dispatch(createProductOptionAction(productOption)),
        updateProductOption: productOption => dispatch(updateProductOptionAction(productOption)),
        deleteProductOption: productOptionId => dispatch(
            deleteProductOptionAction(productOptionId)
        ),
        selectProductOption: productOptionId => dispatch(
            selectProductOptionAction(productOptionId)
        ),
        changeProductOptionGroupPosition: (productOptionGroup, position, _version) => (
            dispatch(changeProductOptionGroupPositionAction(productOptionGroup, position, _version))
        ),
        changeProductOptionPosition: (productOption, position, _version) => (
            dispatch(changeProductOptionPositionAction(productOption, position, _version))
        ),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductOptions));
