import Sound from 'services/Sound';
import { EVENTSOURCE_ON_MESSAGE } from 'containers/Monitor/constants';
import { shouldNotifyNewOrder } from './index';

const sound = ({ getState }) => next => action => {
    if (action.type === EVENTSOURCE_ON_MESSAGE) {
        const state = getState();
        const { data: order } = action;

        triggerNotification(action, state, order);
    }

    return next(action);
};

function triggerNotification(action, state, order) {
    if (shouldNotifyNewOrder(state, order)) {
        Sound.play(state.app.merchant.newOrderSoundRepeat);
    }
}

export default sound;
