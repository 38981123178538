import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Col,
    Form,
    Row,
} from 'reactstrap';

import FormInput from 'components/FormInput';
import DeleteDialog from 'components/DeleteDialog';
import Toggle from 'components/Toggle';
import StateMenuHeader from 'components/StateMenuHeader';

class CreateEditProductOptionGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = CreateEditProductOptionGroupForm.getEmptyState();

        this.handleChangeProductOptionGroupName = this.handleChangeProductOptionGroupName.bind(this);
        this.handleChangeProductOptionGroupNameInternal = this.handleChangeProductOptionGroupNameInternal.bind(this);
        this.handleChangeProductOptionGroupMultipleChoice =
            this.handleChangeProductOptionGroupMultipleChoice.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.initFormValidators = this.initFormValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);

        this.initFormValidators();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.productOptionGroup
            && nextProps.productOptionGroup._id !== prevState.productOptionGroupId
            && !prevState.deleteDialogOpen) {
            return CreateEditProductOptionGroupForm.getStateFromProps(nextProps);
        }

        if (nextProps.forceClearFormData || nextProps.new !== prevState.new) {
            return CreateEditProductOptionGroupForm.getEmptyState();
        }

        if (!prevState.pristine) {
            return null;
        }

        return null;
    }

    static getEmptyState() {
        return {
            new: true,
            pristine: true,
            touched: false,
            productOptionGroupId: null,
            productOptionGroupName: '',
            productOptionGroupNameInternal: '',
            productOptionGroupMultipleChoice: true,
            deleteDialogOpen: false,
        };
    }

    static getStateFromProps(props) {
        const { productOptionGroup } = props;

        return {
            new: false,
            pristine: true,
            touched: false,
            forceShowValidationErrors: false,
            productOptionGroupId: productOptionGroup._id,
            productOptionGroupName: productOptionGroup.name,
            productOptionGroupNameInternal: productOptionGroup.nameInternal || '',
            productOptionGroupMultipleChoice: productOptionGroup.multipleChoice,
            deleteDialogOpen: false,
        };
    }

    static getNewProductOptionGroupFromPropsAndState(props, state) {
        const { productOptionGroup } = props;
        const {
            productOptionGroupName,
            productOptionGroupNameInternal,
            productOptionGroupMultipleChoice,
        } = state;
        const updatedProductOptionGroup = {
            ...productOptionGroup,
            name: productOptionGroupName,
            nameInternal: productOptionGroupNameInternal,
            multipleChoice: productOptionGroupMultipleChoice,
        };

        return updatedProductOptionGroup;
    }

    initFormValidators() {
        this.formValidators = {
            isProductOptionGroupNameValid: () => !!this.state.productOptionGroupName
                && this.state.productOptionGroupName.length <= 75,
            isProductOptionGroupNameInternalValid: () => !this.state.productOptionGroupNameInternal ||
                (this.state.productOptionGroupNameInternal && this.state.productOptionGroupNameInternal.length <= 75),
        };
    }

    isFormValid() {

        let isValid = true;
        Object.values(this.formValidators).forEach(validator => {
            if (!validator()) {
                isValid = false;
            }
        });

        return isValid;
    }

    handleChangeProductOptionGroupName(event) {
        this.setState({
            productOptionGroupName: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductOptionGroupNameInternal(event) {
        this.setState({
            productOptionGroupNameInternal: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductOptionGroupMultipleChoice(event) {
        this.setState(state => ({
            productOptionGroupMultipleChoice: !state.productOptionGroupMultipleChoice,
            pristine: false,
            touched: true,
        }));
        this.props.onChange(event);
    }

    handleOnBlur() {
        this.setState({
            touched: true,
        });
    }

    handleCreate(event, updatedProductOptionGroup) {
        this.props.onCreate(updatedProductOptionGroup).then(() => {
            this.setState({
                pristine: true,
            });
        });
        event.preventDefault();
    }

    handleUpdate(event) {

        if (this.isFormValid()) {
            this.setState({
                forceShowValidationErrors: false,
            });
        } else {
            this.setState({
                forceShowValidationErrors: true,
            });
            return;
        }

        const updatedProductOptionGroup =
            CreateEditProductOptionGroupForm.getNewProductOptionGroupFromPropsAndState(this.props, this.state);

        if (this.props.new) {
            this.handleCreate(event, updatedProductOptionGroup);
        } else {
            this.props.onUpdate(updatedProductOptionGroup).then(() => {
                this.setState({
                    pristine: true,
                });
            });
        }

        event.preventDefault();
    }

    handleDelete(event) {
        if (this.props.new) {
            this.setState(CreateEditProductOptionGroupForm.getEmptyState());
            this.props.onDelete(null);
        } else {
            const deleteProductOptionGroup =
                CreateEditProductOptionGroupForm.getNewProductOptionGroupFromPropsAndState(this.props, this.state);
            this.props.onDelete(deleteProductOptionGroup);
        }

        event.preventDefault();
    }

    toggleDeleteDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }));
    }

    render() {
        const {
            pristine,
            productOptionGroupName,
            productOptionGroupNameInternal,
            deleteDialogOpen,
            productOptionGroupMultipleChoice,
        } = this.state;

        return (
            <div>
                <StateMenuHeader
                    text={this.state.new ? 'Neue Optionengruppe' : `Optionengruppe "${productOptionGroupName}"`}
                    available
                    enabled
                />
                <div className="flex-grow-0 px-2">
                    <Form onSubmit={null}>
                        <FormInput
                            label="Bezeichnung:"
                            id="productOptionGroupName"
                            value={productOptionGroupName}
                            valid={this.formValidators.isProductOptionGroupNameValid()}
                            formFeedBack="Feld darf nicht leer sein und max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductOptionGroupName}
                            onBlur={this.handleOnBlur}
                            focus={this.props.new && !this.state.touched}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <FormInput
                            label="Interner Name:"
                            id="productOptionGroupNameInternal"
                            value={productOptionGroupNameInternal}
                            valid={this.formValidators.isProductOptionGroupNameInternalValid()}
                            formFeedBack="Feld darf max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductOptionGroupNameInternal}
                            onBlur={this.handleOnBlur}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                    </Form>
                    <Row noGutters className="form-toggle-row">
                        <Toggle
                            id="toggle-productOptionGroup-multiple-choice"
                            label={`${productOptionGroupMultipleChoice
                                ? 'Mehrfachauswahl (optional)'
                                : 'Einfachauswahl (verpflichtend)'}`
                            }
                            data-testid="toggle-multi-select"
                            checked={productOptionGroupMultipleChoice}
                            onChange={this.handleChangeProductOptionGroupMultipleChoice}
                        />
                    </Row>
                    <Row noGutters className="align-items-center pt-2">
                        <Col className="pr-1" xs="10">
                            <Button
                                className="w-100"
                                disabled={pristine}
                                onClick={this.handleUpdate}
                            >
                                Optionengruppe speichern
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100"
                                color="danger"
                                data-testid="button-delete-option-group"
                                onClick={this.toggleDeleteDialog}
                            >
                                <i className="fa fa-trash-o" />
                            </Button>
                        </Col>
                        <DeleteDialog
                            isOpen={deleteDialogOpen}
                            toggle={this.toggleDeleteDialog}
                            onConfirm={this.handleDelete}
                            object={{
                                header: this.props.new ? 'Änderungen verwerfen' : 'Optionengruppe löschen',
                                body: this.props.new
                                    ? 'Wollen Sie die nicht gespeicherten Änderungen wieder verwerfen?'
                                    : `Wollen Sie die Optionengruppe ${productOptionGroupName} und die
                                        dazu gehörigen Produkte löschen?`,
                                confirmButtonText: this.props.new ? 'Änderungen verwerfen' : 'Optionengruppe löschen',
                            }}
                        />
                    </Row>
                </div>
            </div>
        );
    }

}

CreateEditProductOptionGroupForm.propTypes = {
    new: PropTypes.bool,
    productOptionGroup: PropTypes.object,  // eslint-disable-line react/no-unused-prop-types
    forceClearFormData: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

CreateEditProductOptionGroupForm.defaultProps = {
    forceClearFormData: false,
};

export default CreateEditProductOptionGroupForm;
