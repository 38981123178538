import { createSelector } from 'reselect';

export const selectLoading = state => state.orders.loading;
export const selectFilters = state => state.orders.filters;
export const selectResult = state => state.orders.result;
export const selectOrders = state => state.orders.entities.orders;
export const selectCustomers = state => state.orders.entities.customers;
export const selectMerchants = state => state.orders.entities.merchants;

export const selectFilteredResult = createSelector(
    [selectResult, selectOrders],
    (result, orders) => result.filter(id => orders[id].state !== 'CANCELED')
);

export const selectFilteredOrders = createSelector(
    [selectOrders],
    orders => orders ? Object.values(orders).filter(
        o => o.state !== 'CANCELED' && o.state !== 'ABORTED' && o.state !== 'PENDING'
    ) : {}
);
