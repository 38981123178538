import {
    CREATE_ORDER_REQUEST,
    CREATE_ORDER_SUCCESS,
    CREATE_ORDER_ERROR,
    SET_CART,
    SET_NOTE,
    SET_OWN_PACKAGING,
    SET_SELECTED_CATEGORY_ID,
    RESET_CART,
    SET_PICK_UP_TIME,
} from './constants';

const initialState = {
    isCreating: false,
    error: null,
    note: '',
    ownPackaging: false,
    cart: {},
    selectedCategoryId: '',
    pickUpTime: 'now',
};

function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_ORDER_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: null,
            };
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isCreating: false,
                error: null,
            };
        case CREATE_ORDER_ERROR:
            return {
                ...state,
                isCreating: false,
                error: action.error,
            };
        case SET_CART:
            return {
                ...state,
                cart: action.cart,
            };
        case SET_NOTE:
            return {
                ...state,
                note: action.note,
            };
        case SET_OWN_PACKAGING:
            return {
                ...state,
                ownPackaging: action.ownPackaging,
            };
        case SET_SELECTED_CATEGORY_ID:
            return {
                ...state,
                selectedCategoryId: action.id,
            };
        case SET_PICK_UP_TIME:
            return {
                ...state,
                pickUpTime: action.pickUpTime,
            };
        case RESET_CART:
            return {
                ...state,
                cart: {},
                note: '',
                ownPackaging: false,
                pickUpTime: 'now',
            };
        default:
            return state;
    }
}

export default cartReducer;
