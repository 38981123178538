import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import FormSelect from '../FormSelect';

const NO_SELECTION = '0';
const MAX_PRODUCT_OPTION_GROUPS = 5;

class OptionGroupsSelect extends React.Component {

    constructor(props) {
        super(props);

        ({ groups: this.optionGroups = [] } = this.props);
        this.optionGroupsSelectOptions = this.optionGroups.reduce((obj, item) => {
            // eslint-disable-next-line no-param-reassign
            obj[item._id] = item.nameInternal || item.name;
            return obj;
        }, { [NO_SELECTION]: '-- Keine Auswahl' });

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, idx) {
        event.target.blur();

        const { selected = [] } = this.props;
        const { value } = event.target;

        let newSelection = [...selected];
        newSelection[idx] = value;
        newSelection = [...new Set(newSelection)].filter(e => e !== NO_SELECTION);

        this.props.onChange(event, newSelection);

        event.preventDefault();
    }

    render() {

        let { selected = [] } = this.props;
        if (selected.length < Math.min(MAX_PRODUCT_OPTION_GROUPS, this.optionGroups.length)) {
            selected = selected.concat([NO_SELECTION]);
        }

        if (this.optionGroups.length === 0) {
            const noOptionGroups = { nooptions: 'Keine Optionengruppen vorhanden' };
            return (<FormSelect
                id="no-option-groups"
                options={noOptionGroups}
                value={noOptionGroups.nooptions}
                key={noOptionGroups.nooptions}
                disabled
            />);
        }

        return selected.map((value, idx, groupId) => {
            const remainingOptions = Object.fromEntries(Object.entries(this.optionGroupsSelectOptions)
                .filter(([key]) => !selected.includes(key) || key === value));

            const options = {
                [NO_SELECTION]: this.optionGroupsSelectOptions[NO_SELECTION],
                ...remainingOptions,
            };

            return (<FormSelect
                id={`option-group-select-${idx}`}
                options={options}
                value={value}
                key={value}
                onChange={e => this.handleClick(e, idx, groupId)}
            />);
        });
    }

}

OptionGroupsSelect.propTypes = {
    selected: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};

export default OptionGroupsSelect;
