import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Price from 'components/Price';

import { isProductAvailable } from 'utils';

import './style.css';

function MenuCardProductItem({
    locale,
    product,
    selected,
    hideArrowUp,
    hideArrowDown,
    disableArrows,
    onClick,
    onKeyPress,
    onArrowUpClick,
    onArrowDownClick,
    showOptionsIndicator,
    omitInternalName,
}) {
    let productColor = '#000';

    if (!product.enabled || !isProductAvailable(product)) {
        productColor = '#aaa';
    }

    /* eslint-disable max-len */
    return (
        <Row
            role="menuitem"
            tabIndex="0"
            className={
                `flex-nowrap menu-card-product-item mt-2 align-items-center ${selected && 'menu-card-category-item-selected'}`
            }
            key={product._id}
            onClick={onClick}
            onKeyPress={onKeyPress}
            noGutters
        >
            <Col className="flex-grow-0 mx-2">
                <div
                    className="menu-card-product-item-colorDisplay border rounded-circle"
                    style={{
                        display: product.color ? null : 'none',
                        backgroundColor: `${product.color}`,
                    }}
                />
            </Col>
            <Col
                className="flex-grow-1 mr-2 truncated-name product-name"
                style={{ color: productColor }}
            >
                {omitInternalName ? product.name : (product.nameInternal || product.name)}
                <div className="product-state-text">
                    {!isProductAvailable(product) && 'Aufgegessen '}
                    {!product.enabled && 'Deaktiviert'}
                </div>
            </Col>
            <Col className="flex-grow-0 ml-auto mr-2">
                <Price
                    price={product.price}
                    locale={locale}
                />
            </Col>
            <Col
                className="menu-card-product-item-arrow flex-grow-0 mx-2 mr-2"
                style={{
                    visibility: hideArrowUp ? 'hidden' : 'visible',
                    display: disableArrows && 'none',
                }}
                onClick={onArrowUpClick}
            >
                <i className="fa fa-arrow-up text-secondary" />
            </Col>
            <Col
                className="menu-card-product-item-arrow flex-grow-0 mx-2 mr-2"
                style={{
                    visibility: hideArrowDown ? 'hidden' : 'visible',
                    display: disableArrows && 'none',
                }}
                onClick={onArrowDownClick}
            >
                <i className="fa fa-arrow-down text-secondary" />
            </Col>
            {
                showOptionsIndicator &&
                <Col
                    className="menu-card-product-options-indicator flex-grow-0 ml-3 mr-2"
                >
                    <i className={
                        `fa
                        ${selected ? 'fa-caret-up' : 'fa-sliders'}
                        ${product?.optionGroups?.length > 0 || 'fa-hidden'}`
                    }
                    />
                </Col>
            }
        </Row>
    );
    /* eslint-enable max-len */
}

MenuCardProductItem.propTypes = {
    locale: PropTypes.string.isRequired,
    product: PropTypes.any.isRequired,
    selected: PropTypes.bool,
    hideArrowUp: PropTypes.bool,
    hideArrowDown: PropTypes.bool,
    disableArrows: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    onArrowUpClick: PropTypes.func,
    onArrowDownClick: PropTypes.func,
    showOptionsIndicator: PropTypes.bool,
    omitInternalName: PropTypes.bool,
};

export default MenuCardProductItem;
