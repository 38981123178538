import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    Label,
    Col,
    Row,
    Button,
    FormGroup,
} from 'reactstrap';

import {
    getLocaleNumberValidator,
    getPositiveIntegerValidator,
    normalizeLocalizedNumber,
    formatLocalizedNumber,
    isProductAvailable,
} from 'utils';

import FormInput from 'components/FormInput';
import AllergensSelect from 'components/AllergensSelect';
import OptionGroupsSelect from 'components/OptionGroupsSelect';
import ColorPicker from 'components/ColorPicker';
import DeleteDialog from 'components/DeleteDialog';
import WarningDialog from 'components/WarningDialog';
import StateMenuHeader from 'components/StateMenuHeader';
import CreateEditCategoryForm from 'components/CreateEditCategoryForm';
import Toggle from 'components/Toggle';

import './styles.css';

class CreateEditProductForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = CreateEditProductForm.getEmptyState(props);

        this.optionGroups = props.optionGroups;

        this.handleChangeProductName = this.handleChangeProductName.bind(this);
        this.handleChangeProductNameInternal = this.handleChangeProductNameInternal.bind(this);
        this.handleChangeProductDescription = this.handleChangeProductDescription.bind(this);
        this.handleChangeProductPrice = this.handleChangeProductPrice.bind(this);
        this.handleChangeProductTaxRate = this.handleChangeProductTaxRate.bind(this);
        this.handleChangeProductAllergens = this.handleChangeProductAllergens.bind(this);
        this.handleChangeProductOptionGroups = this.handleChangeProductOptionGroups.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleChangeAvailable = this.handleChangeAvailable.bind(this);
        this.handleChangeAvailableMax = this.handleChangeAvailableMax.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
        this.toggleWarningDialog = this.toggleWarningDialog.bind(this);
        this.toggleColorPicker = this.toggleColorPicker.bind(this);
        this.initFormValidators = this.initFormValidators.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.saveOrUpdateProduct = this.saveOrUpdateProduct.bind(this);
        this.confirmWarningDialog = this.confirmWarningDialog.bind(this);

        this.initFormValidators();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.product
            && (nextProps.product._id !== prevState.productId               // create product
                || nextProps.product._version !== prevState.productVersion) // update product
            && !prevState.deleteDialogOpen) {
            return CreateEditProductForm.getStateFromProps(nextProps);
        }

        if ((!nextProps.new && !nextProps.product)
            || nextProps.forceClearFormData
            || nextProps.new !== prevState.new) {
            return CreateEditProductForm.getEmptyState(nextProps);
        }

        if (nextProps.product?.availableCount !== prevState.productAvailableCountOriginal) {
            return CreateEditProductForm.getStateFromProps(nextProps);
        }

        if (!prevState.pristine) {
            return null;
        }

        return null;
    }

    static getEmptyState(props) {
        const { category } = props;

        return {
            new: true,
            pristine: true,
            touched: false,
            forceShowValidationErrors: false,
            productId: null,
            productColor: '#000',
            productName: '',
            productNameInternal: '',
            productDescription: '',
            productPrice: 0,
            formattedProductPrice: '0',
            productTaxRate: 0,
            formattedProductTaxRate: '0',
            productAllergens: [],
            productAvailableCount: undefined,
            productAvailableCountOriginal: undefined,
            productAvailableMax: 0,
            productEnabled: true,
            productCategory: category ? category._id : '',
            productOptionGroups: [],
            deleteDialogOpen: false,
            warningDialogOpen: false,
            warningDialogMessage: '',
            colorPickerOpen: false,
            productVersion: undefined,
        };
    }

    static getStateFromProps(props) {
        const { product, locale } = props;
        return {
            new: false,
            pristine: true,
            touched: false,
            productId: product._id,
            productColor: product.color,
            productName: product.name,
            productNameInternal: product.nameInternal || '',
            productDescription: product.description,
            productPrice: Math.trunc(product.price) / 100, // We receive an integer representation
            formattedProductPrice: formatLocalizedNumber(Math.trunc(product.price) / 100, locale),
            productTaxRate: Math.trunc(product.taxRate) / 100,
            formattedProductTaxRate: formatLocalizedNumber(Math.trunc(product.taxRate) / 100, locale),
            productAllergens: product.allergens,
            productAvailableCount: product.availableCount,
            productAvailableCountOriginal: product.availableCount,
            productAvailableMax: product.availableMax,
            productEnabled: product.enabled,
            productCategory: product.category,
            productOptionGroups: product.optionGroups,
            productVersion: product._version,
            deleteDialogOpen: false,
            warningDialogOpen: false,
            warningDialogMessage: '',
            colorPickerOpen: false,
        };
    }

    static getNewProductFromPropsAndState(props, state) {
        const { product } = props;
        const {
            productColor,
            productName,
            productNameInternal,
            productDescription,
            productPrice,
            productTaxRate,
            productAllergens,
            productAvailableCount,
            productAvailableMax,
            productEnabled,
            productCategory,
            productOptionGroups,
            productVersion,
        } = state;
        const updatedProduct = {
            ...product,
            color: productColor,
            name: productName,
            nameInternal: productNameInternal,
            description: productDescription,
            price: Math.round(productPrice * 100), // The API needs an integer representation
            taxRate: Math.round(productTaxRate * 100),
            allergens: productAllergens,
            availableCount: productAvailableCount,
            availableMax: productAvailableMax,
            enabled: productEnabled,
            category: productCategory,
            optionGroups: productOptionGroups,
            _version: productVersion,
        };

        return updatedProduct;
    }

    initFormValidators() {
        this.formValidators = {
            isProductNameValid: () => !!this.state.productName && this.state.productName.length <= 75,
            isNameInternalValid: () => !this.state.productNameInternal ||
                (this.state.productNameInternal && this.state.productNameInternal.length <= 75),
            isPriceValid: () => !Number.isNaN(this.state.productPrice)
                && this.state.productPrice >= 0,
            isTaxRateValid: () => !Number.isNaN(this.state.productTaxRate)
                && this.state.productTaxRate >= 0
                && this.state.productTaxRate <= 100,
            isAvailableMaxValid: () => !Number.isNaN(this.state.productAvailableMax)
                && this.state.productAvailableMax >= 0
                && this.state.productAvailableMax <= 10000,
        };
    }

    isFormValid() {

        let isValid = true;
        Object.values(this.formValidators).forEach(validator => {
            if (!validator()) {
                isValid = false;
            }
        });

        return isValid;
    }

    isProductAvailable(state) {
        return isProductAvailable(CreateEditProductForm.getNewProductFromPropsAndState({}, state));
    }

    handleOnBlur() {
        this.setState({
            touched: true,
        });
    }

    handleChangeProductName(event) {
        this.setState({
            productName: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductNameInternal(event) {
        this.setState({
            productNameInternal: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductDescription(event) {
        this.setState({
            productDescription: event.target.value,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductPrice(event) {
        const { locale, onChange } = this.props;

        const validator = getLocaleNumberValidator(locale);

        if (validator.test(event.target.value)) {
            this.setState({
                formattedProductPrice: event.target.value,
                productPrice: normalizeLocalizedNumber(event.target.value, locale),
                pristine: false,
            });
            onChange(event);
        }
    }

    handleChangeProductTaxRate(event) {
        const { locale, onChange } = this.props;

        const validator = getLocaleNumberValidator(locale);

        if (validator.test(event.target.value)) {
            this.setState({
                formattedProductTaxRate: event.target.value,
                productTaxRate: normalizeLocalizedNumber(event.target.value, locale),
                pristine: false,
            });
            onChange(event);
        }
    }

    handleChangeProductAllergens(event, selected) {
        this.setState({
            productAllergens: selected,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleChangeProductOptionGroups(event, selected) {
        this.setState({
            productOptionGroups: selected,
            pristine: false,
            touched: true,
        });
        this.props.onChange(event);
    }

    handleColorChange(event) {
        this.setState({
            productColor: event.hex,
            pristine: false,
            touched: true,
        });
        this.toggleColorPicker();
        this.props.onChange(event);
    }

    handleChangeAvailable() {
        this.setState(state => {
            const productAvailable = !this.isProductAvailable(state);

            let productAvailableCount;
            if (productAvailable) {
                if (state.productAvailableMax > 0) {
                    productAvailableCount = state.productAvailableCountOriginal > 0
                        ? state.productAvailableCountOriginal
                        : state.productAvailableMax;
                } else {
                    productAvailableCount = undefined;
                }
            } else {
                productAvailableCount = 0;
            }

            return {
                productAvailableCount,
                pristine: false,
            };
        });
        this.props.onChange();
    }

    handleChangeAvailableMax(event) {
        const { locale, onChange } = this.props;

        const validator = getPositiveIntegerValidator(locale);

        if (validator.test(event.target.value)) {
            this.setState({
                productAvailableMax: event.target.value === '' ? '' : Number(event.target.value),
                pristine: false,
            });
            onChange(event);
        }
    }

    handleChangeEnabled() {
        this.setState(state => ({
            productEnabled: !state.productEnabled,
            pristine: false,
        }));
        this.props.onChange();
    }

    handleCreate(event, updatedProduct) {
        this.props.onCreate(updatedProduct).then(() => {
            this.setState({
                pristine: true,
            });
        });
        event.preventDefault();
    }

    handleUpdate(event) {

        if (this.isFormValid()) {
            this.setState({
                forceShowValidationErrors: false,
            });
        } else {
            this.setState({
                forceShowValidationErrors: true,
            });
            return;
        }

        if (Number(this.state.productPrice) === 0) {
            this.setState({
                warningDialogOpen: true,
                warningDialogMessage: 'Der Preis beträgt 0.00 €. Trotzdem speichern?',
            });
        } else {
            this.saveOrUpdateProduct(event);
        }
        event.preventDefault();
    }

    confirmWarningDialog(event) {
        this.saveOrUpdateProduct(event);
    }

    saveOrUpdateProduct(event) {
        const updatedProduct = CreateEditProductForm.getNewProductFromPropsAndState(this.props, this.state);

        if (this.props.new) {
            this.handleCreate(event, updatedProduct);
        } else {
            this.props.onUpdate(updatedProduct).then(() => {
                this.setState({
                    pristine: true,
                });
            });
        }

    }

    handleDelete(event) {
        if (this.props.new) {
            this.setState(CreateEditProductForm.getEmptyState(this.props));
            this.props.onDelete(null);
        } else {
            const deleteProduct = CreateEditProductForm.getNewProductFromPropsAndState(this.props, this.state);
            this.props.onDelete(deleteProduct);
        }

        event.preventDefault();
    }

    toggleDeleteDialog() {
        this.setState(state => ({
            deleteDialogOpen: !state.deleteDialogOpen,
        }));
    }

    toggleWarningDialog() {
        this.setState(state => ({
            warningDialogOpen: !state.warningDialogOpen,
        }));
    }

    toggleColorPicker() {
        this.setState(state => ({
            colorPickerOpen: !state.colorPickerOpen,
        }));
    }

    render() {
        const { locale } = this.props;
        const {
            pristine,
            deleteDialogOpen,
            warningDialogOpen,
            warningDialogMessage,
            colorPickerOpen,
            productName,
            productNameInternal,
            productDescription,
            productColor,
            productPrice,
            formattedProductPrice,
            productTaxRate,
            formattedProductTaxRate,
            productAllergens,
            productAvailableCount,
            productAvailableMax,
            productEnabled,
            productOptionGroups,
        } = this.state;

        const productAvailable = this.isProductAvailable(this.state);

        return (
            <div className="d-flex" style={{ flexDirection: 'column' }}>
                <StateMenuHeader
                    text={this.state.new ? 'Neues Produkt' : `Produkt "${productName}"`}
                    available={productAvailable}
                    enabled={productEnabled}
                />
                <div className="px-2" style={{ overflow: 'auto' }}>
                    <Form onSubmit={null}>
                        <FormInput
                            label="Bezeichnung:"
                            id="productName"
                            value={productName}
                            valid={this.formValidators.isProductNameValid()}
                            formFeedBack="Feld darf nicht leer sein und max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductName}
                            onBlur={this.handleOnBlur}
                            focus={this.props.new && !this.state.touched}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <FormInput
                            label="Interner Name:"
                            id="productNameInternal"
                            value={productNameInternal}
                            valid={this.formValidators.isNameInternalValid()}
                            formFeedBack="Feld darf max. 75 Zeichen enthalten."
                            onChange={this.handleChangeProductNameInternal}
                            onBlur={this.handleOnBlur}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <FormInput
                            label="Beschreibung:"
                            id="productDescription"
                            type="textarea"
                            value={productDescription}
                            onChange={this.handleChangeProductDescription}
                            onBlur={this.handleOnBlur}
                            forceHideValidationErrors={this.state.pristine && !this.state.forceShowValidationErrors}
                            forceShowValidationErrors={this.state.forceShowValidationErrors}
                        />
                        <Row noGutters className="align-items-center pt-2 mb-3">
                            <Col xs="12">
                                <Label for="productColor">
                                    Farbe:
                                </Label>
                            </Col>
                            <Col xs="12">
                                <ColorPicker
                                    color={productColor}
                                    onToggle={this.toggleColorPicker}
                                    isOpen={colorPickerOpen}
                                    onChangeComplete={this.handleColorChange}
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="align-items-center pt-2 mb-3">
                            <Col xs="12">
                                <Label for="productAllergens">
                                    Allergene:
                                </Label>
                            </Col>
                            <Col xs="12">
                                <AllergensSelect
                                    id="productAllergens"
                                    selected={productAllergens}
                                    onChange={this.handleChangeProductAllergens}
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="align-items-center pt-2 mb-3">
                            <Col xs="12">
                                <Label for="productOptionGroups">
                                    Optionengruppen für dieses Produkt:
                                </Label>
                            </Col>
                            <Col xs="12">
                                <OptionGroupsSelect
                                    id="productOptionGroups"
                                    groups={this.optionGroups}
                                    selected={productOptionGroups}
                                    onChange={this.handleChangeProductOptionGroups}
                                />
                            </Col>
                        </Row>
                        <Row noGutters className="align-items-top pt-2 mb-3">
                            <Col xs="6" className="pr-2">
                                <FormInput
                                    label="Preis (Brutto):"
                                    id="productPrice"
                                    type="text"
                                    value={formattedProductPrice}
                                    valid={this.formValidators.isPriceValid()}
                                    formFeedBack="Preis darf nicht negativ sein."
                                    onChange={this.handleChangeProductPrice}
                                    onBlur={() => {
                                        this.handleOnBlur();
                                        this.setState({
                                            productPrice: Number(productPrice).toFixed(2),
                                            formattedProductPrice: formatLocalizedNumber(productPrice, locale),
                                        });
                                    }}
                                    inputGroupText="&euro;"
                                    alignInputTextRight
                                    forceHideValidationErrors={
                                        this.state.pristine && !this.state.forceShowValidationErrors
                                    }
                                    forceShowValidationErrors={this.state.forceShowValidationErrors}
                                    inputMode="decimal"
                                />
                            </Col>
                            <Col xs="6" className="pl-2">
                                <FormInput
                                    label="Steuersatz:"
                                    id="productTaxRate"
                                    type="text"
                                    value={formattedProductTaxRate}
                                    valid={this.formValidators.isTaxRateValid()}
                                    formFeedBack="Steuersatz muss zwischen 0 und 100 % liegen."
                                    onChange={this.handleChangeProductTaxRate}
                                    onBlur={() => {
                                        this.handleOnBlur();
                                        this.setState({
                                            productTaxRate: Number(productTaxRate).toFixed(2),
                                            formattedProductTaxRate: formatLocalizedNumber(productTaxRate, locale, 0),
                                        });
                                    }}
                                    inputGroupText="&#37;"
                                    alignInputTextRight
                                    forceHideValidationErrors={
                                        this.state.pristine && !this.state.forceShowValidationErrors
                                    }
                                    forceShowValidationErrors={this.state.forceShowValidationErrors}
                                    inputMode="decimal"
                                />
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="w-100 flex-grow-0 product-form-toggle-area px-2">
                    <div className="product-available-form-area">
                        <Row noGutters className="form-toggle-row pb-0">
                            <Toggle
                                id="toggle-product-available"
                                label={`Produkt ${productAvailable ? 'verfügbar' : 'aufgegessen'}`}
                                checked={productAvailable}
                                onChange={this.handleChangeAvailable}
                            />
                        </Row>
                        {productAvailable ?
                            <div style={{ marginLeft: '64px' }}>
                                <FormInput
                                    label="Limit"
                                    id="product-available-limit"
                                    value={productAvailableMax}
                                    valid={this.formValidators.isAvailableMaxValid()}
                                    formFeedBack="Limit muss zwischen 0 (unlimitiert) und 10000 liegen."
                                    onChange={this.handleChangeAvailableMax}
                                    onBlur={() => {
                                        this.handleOnBlur();
                                        this.setState({
                                            productAvailableMax: Number(productAvailableMax),
                                        });
                                    }}
                                    alignLabelRight
                                    forceHideValidationErrors={
                                        this.state.pristine && !this.state.forceShowValidationErrors
                                    }
                                    forceShowValidationErrors={this.state.forceShowValidationErrors}
                                />
                                <FormGroup row>
                                    <Label sm={4}>Verfügbar</Label>
                                    <Col sm={8} id="product-available-current">
                                        <span>{productAvailableCount === undefined
                                            ? 'Unlimitiert'
                                            : productAvailableCount}
                                        </span>
                                    </Col>
                                </FormGroup>
                            </div> : null
                        }
                    </div>
                    <Row noGutters className="form-toggle-row">
                        <Toggle
                            id="toggle-product-enabled"
                            label={`Produkt ${productEnabled ? 'sichtbar' : 'versteckt'}`}
                            checked={productEnabled}
                            onChange={this.handleChangeEnabled}
                        />
                    </Row>
                    <Row noGutters className="align-items-center pt-2">
                        <Col className="pr-1" xs="10">
                            <Button
                                className="w-100"
                                disabled={pristine}
                                onClick={this.handleUpdate}
                            >
                                Produkt speichern
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100"
                                color="danger"
                                onClick={this.toggleDeleteDialog}
                            >
                                <i className="fa fa-trash-o" />
                            </Button>
                        </Col>
                    </Row>
                    <DeleteDialog
                        isOpen={deleteDialogOpen}
                        toggle={this.toggleDeleteDialog}
                        onConfirm={this.handleDelete}
                        object={{
                            header: this.props.new ? 'Änderungen verwerfen' : 'Produkt löschen',
                            body: this.props.new
                                ? 'Wollen Sie die nicht gespeicherten Änderungen wieder verwerfen?'
                                : 'Wollen Sie das Produkt wirklich löschen?',
                            confirmButtonText: this.props.new ? 'Änderungen verwerfen' : 'Produkt löschen',
                        }}
                    />
                    <WarningDialog
                        isOpen={warningDialogOpen}
                        toggle={this.toggleWarningDialog}
                        onConfirm={this.confirmWarningDialog}
                        message={warningDialogMessage}
                        confirmButtonText="Speichern"
                    />
                </div>
            </div>
        );
    }

}

// eslint wrongly reports unused prop types
CreateEditProductForm.propTypes = {
    locale: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    new: PropTypes.bool,  // eslint-disable-line react/no-unused-prop-types
    product: PropTypes.object,  // eslint-disable-line react/no-unused-prop-types
    category: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    forceClearFormData: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    optionGroups: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

CreateEditCategoryForm.defaultProps = {
    forceClearFormData: false,
};

export default CreateEditProductForm;
