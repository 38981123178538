
import {
    BLOCK_CUSTOMER_ERROR,
    BLOCK_CUSTOMER_REQUEST,
    BLOCK_CUSTOMER_SUCCESS,
    GET_CUSTOMER_BLACKLIST_ERROR,
    GET_CUSTOMER_BLACKLIST_REQUEST,
    GET_CUSTOMER_BLACKLIST_SUCCESS,
    UNBLOCK_CUSTOMER_ERROR,
    UNBLOCK_CUSTOMER_REQUEST,
    UNBLOCK_CUSTOMER_SUCCESS,
} from './constants';

const initialState = {
    pending: false,
    blockedCustomers: [],
    error: null,
};

const customerBlackList = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMER_BLACKLIST_REQUEST:
            return {
                ...state,
                pending: true,
                error: false,
            };
        case GET_CUSTOMER_BLACKLIST_SUCCESS:
            return {
                ...state,
                blockedCustomers: action.data,
                pending: false,
                error: false,
            };
        case GET_CUSTOMER_BLACKLIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case BLOCK_CUSTOMER_REQUEST:
            return {
                ...state,
                pending: true,
                error: false,
            };
        case BLOCK_CUSTOMER_SUCCESS:
            return {
                ...state,
                blockedCustomers: state.blockedCustomers.concat(action.data),
                pending: false,
                error: false,
            };
        case BLOCK_CUSTOMER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case UNBLOCK_CUSTOMER_REQUEST:
            return {
                ...state,
                pending: true,
                error: false,
            };
        case UNBLOCK_CUSTOMER_SUCCESS:
            return {
                ...state,
                blockedCustomers: state.blockedCustomers.filter(elem => elem._id !== action.blackListEntryId),
                pending: false,
                error: false,
            };
        case UNBLOCK_CUSTOMER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default customerBlackList;
