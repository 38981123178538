import {
    PLAY_AUDIO,
    STOP_AUDIO,
    SELECT_SOUND,
    SET_SOUNDS,
} from './constants';

const initialState = {
    isPlaying: false,
    loop: false,
    selected: '',
    sounds: {},
};

function audioPlayerReducer(state = initialState, action) {
    switch (action.type) {
        case PLAY_AUDIO:
            return {
                ...state,
                isPlaying: true,
                loop: action.loop,
            };
        case STOP_AUDIO:
            return {
                ...state,
                isPlaying: false,
            };
        case SELECT_SOUND: {
            return {
                ...state,
                selected: action.key,
            };
        }
        case SET_SOUNDS:
            return {
                ...state,
                sounds: action.sounds,
            };
        default:
            return state;
    }
}

export default audioPlayerReducer;
