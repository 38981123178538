import {
    MERCHANT_STATE_ERROR,
    MERCHANT_STATE_REQUEST,
    MERCHANT_STATE_SUCCESS,
    IS_MERCHANT_ACTIVATED_ERROR,
    IS_MERCHANT_ACTIVATED_REQUEST,
    IS_MERCHANT_ACTIVATED_SUCCESS,
    CHECK_OPEN_STATE,
} from './constants';

const initialState = {
    shouldCheckOpenState: false,

    pending: false,
    available: null,
    error: null,
};

const onlineReducer = (state = initialState, action) => {
    switch (action.type) {
        case MERCHANT_STATE_REQUEST:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case MERCHANT_STATE_SUCCESS:
            return {
                ...state,
                pending: true,
                available: action.data,
                error: null,
            };
        case MERCHANT_STATE_ERROR:
            return {
                ...state,
                pending: true,
                error: action.error,
            };
        case IS_MERCHANT_ACTIVATED_REQUEST:
            return {
                ...state,
                pending: true,
                error: null,
            };
        case IS_MERCHANT_ACTIVATED_SUCCESS:
            return {
                ...state,
                pending: true,
                available: action.data,
                error: null,
            };
        case IS_MERCHANT_ACTIVATED_ERROR:
            return {
                ...state,
                pending: true,
                error: action.error,
            };
        case CHECK_OPEN_STATE:
            return {
                ...state,
                shouldCheckOpenState: action.check,
            };
        default:
            return state;
    }
};

export default onlineReducer;
