import React from 'react';
import PropTypes from 'prop-types';
import { CirclePicker } from 'react-color';

/**
 * Color Picker Component
 *
 * @param {(string|object)} color - the current active color,
 *      string as hex code or object with rgb or hsl values and optionally the a value
 * @param {function} onChange - called every time the color is changed: (color, event) => ()
 * @param {function} onToggle - called when color picker is clicked
 * @param {boolean} isOpen - if true the selectable colors are shown
 * @param {function} onChangeComplete - called when the color has changed: (event) => ()
 */
function ColorPicker({
    color,
    onChange,
    onToggle,
    isOpen,
    onChangeComplete,
}) {
    return (
        <div>
            <div
                role="button"
                tabIndex="0"
                onClick={onToggle}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        onToggle();
                    }
                }}
                className="MenuCard-colorSelector border rounded-circle"
                style={{
                    backgroundColor: color,
                }}
            />
            {isOpen &&
                <div>
                    <div className="w-100 my-3">
                        <CirclePicker
                            width="100%"
                            color={color}
                            onChange={onChange}
                            onChangeComplete={onChangeComplete}
                            colors={[
                                '#BE2231', '#D43900', '#aa5d00', '#BC8D00', '#9C27B0', '#3C1362', '#00008b',
                                '#007CB5', '#007A7C', '#006060', '#006E04', '#696969', '#3E3E3E', '#000000',
                            ]}
                        />
                    </div>
                    <p className="text-center w-100 mt-1 text-secondary">
                        <small><i className="fa fa-info-circle" /> Wählen Sie eine Farbe.</small>
                    </p>
                </div>
            }
        </div>
    );
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
    color: 'FFF',
};

export default ColorPicker;
