import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

import Toggle from 'components/Toggle';
import Storage from 'services/Storage';

import './styles.css';

class LoginForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            queueMonitor: Storage.getQueueMonitor(),
        };

        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleQueueMonitorChange = this.handleQueueMonitorChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.props.onSubmit(this.state.username, this.state.password);
        }
    }

    handleUserNameChange(event) {
        if (/^\d{0,8}$/.test(event.target.value)) {
            this.setState({ username: event.target.value });
        }
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handleQueueMonitorChange() {
        this.setState(state => ({
            queueMonitor: !state.queueMonitor,
        }));
    }

    handleSubmit(event) {
        const { username, password, queueMonitor } = this.state;

        this.props.onSubmit(username, password, queueMonitor);
        event.preventDefault();
    }

    render() {
        const { username, password, queueMonitor } = this.state;

        return (
            <Form>
                <FormGroup>
                    <Label for="username">Benutzername</Label>
                    <Input
                        className="username-input"
                        type="number"
                        step="1"
                        min="0"
                        max="99999999"
                        name="username"
                        id="username"
                        value={username}
                        onChange={this.handleUserNameChange}
                        onKeyPress={this.onKeyPress}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="password">Passwort</Label>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={this.handlePasswordChange}
                        onKeyPress={this.onKeyPress}
                    />
                </FormGroup>
                <FormGroup>
                    <Toggle
                        id="queueMonitor"
                        label="Kiosk Modus"
                        onChange={this.handleQueueMonitorChange}
                        checked={queueMonitor}
                    />
                </FormGroup>
                <div>
                    <Button
                        className="Login-button"
                        onClick={this.handleSubmit}
                    >
                        Anmelden
                    </Button>
                </div>
            </Form>
        );
    }

}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
