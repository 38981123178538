import Storage from 'services/Storage';

import {
    updateConnectionInfo as updateConnectionInfoAction,
    verifyToken,
} from 'containers/App/actions';
import {
    fetchQueueForToday,
    closeServerSentOrders,
    openServerSentOrders,
} from 'containers/Monitor/action';
import { checkOpenState } from 'containers/Online/action';

class Network {

    static init(dispatch) {

        document.addEventListener('deviceready', () => {

            /**
             * Called when the network connection switches from offline to online.
             */
            document.addEventListener('online', () => {
                console.log('Network: Switched state to network online');

                dispatch(verifyToken())
                    .then(() => {
                        dispatch(openServerSentOrders());
                    })
                    .finally(() => {
                        Network.updateConnectionInfo(dispatch, true);
                    });
            });

            /**
             * Called when the network connection switches from online to offline.
             */
            document.addEventListener('offline', () => {
                console.log('Network: Switched state to network offline');
                Network.updateConnectionInfo(dispatch, false);
                dispatch(closeServerSentOrders());
            });

            /**
             * Called when the app is brought from background to foreground,
             * or the tab was inactive and is now active (focused).
             */
            document.addEventListener('resume', () => {
                console.log('Network: Switched state to resume app');
                Network.updateConnectionInfo(dispatch, Network.isConnected());

                if (Storage.isLoggedIn()) {
                    dispatch(checkOpenState(true));

                    if (window.cordova.platformId !== 'browser') {
                        // mobile apps may not have received any orders while
                        // being in background -- fetch orders
                        dispatch(fetchQueueForToday());
                    }
                }
            });

            Network.updateConnectionInfo(dispatch, Network.isConnected());

        }, false);

    }

    static updateConnectionInfo(dispatch, isConnected) {
        dispatch(updateConnectionInfoAction(isConnected));
    }

    static isConnected() {
        if (!navigator.connection) {
            return false;
        }

        const networkState = navigator.connection.type;
        return !(!networkState || networkState.toLowerCase() === 'none');
    }

}

export default Network;
