import React from 'react';
import PropTypes from 'prop-types';

function FormattedOrderNumber({ value }) {
    return (
        <span data-testid={`supportId-${value}`}>
            {`${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`}
        </span>
    );
}

FormattedOrderNumber.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FormattedOrderNumber;
