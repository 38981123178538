import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import LoginSettingsCard from 'components/LoginSettingsCard';
import PrivateRoute from 'components/PrivateRoute';

import History from 'containers/History';
import Settings from 'containers/Settings';
import Monitor from 'containers/Monitor';
import Menu from 'containers/MenuCard';
import ProductOptions from 'containers/ProductOptions';
import About from 'containers/About';
import Login from 'containers/Login';
import CustomerBlackList from 'containers/CustomerBlackList';
import PushReceiver from 'containers/PushReceiver';
import QueueMonitor from 'containers/QueueMonitor';
import Cart from 'containers/Cart';

function Routes({ isLoggedIn, showQueueMonitor }) {
    return (
        <main className="container-fluid p-0" style={{ display: isLoggedIn ? 'flex' : 'block' }}>
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/login-settings" component={LoginSettingsCard} />
                <PrivateRoute
                    exact
                    path="/queue-monitor"
                    component={QueueMonitor}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/monitor"
                    component={Monitor}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/menu"
                    component={Menu}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/product-options"
                    component={ProductOptions}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/customer-blacklist"
                    component={CustomerBlackList}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    path="/history"
                    component={History}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/settings"
                    component={Settings}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/about"
                    component={About}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />
                <PrivateRoute
                    exact
                    path="/cart"
                    component={Cart}
                    loggedIn={isLoggedIn}
                    showQueueMonitor={showQueueMonitor}
                />

                <Redirect exact from="/" to="/monitor" />
            </Switch>
            <PushReceiver />
        </main>
    );
}

Routes.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    showQueueMonitor: PropTypes.bool.isRequired,
};

export default Routes;
