import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

class DeleteDialog extends React.Component {

    constructor(props) {
        super(props);

        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleConfirm(event) {
        this.props.onConfirm(event);
        this.props.toggle();
    }

    render() {
        const {
            isOpen,
            toggle,
            object,
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>
                    {object.header}
                </ModalHeader>
                <ModalBody>
                    {object.body}
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        onClick={toggle}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        color="danger"
                        onClick={this.handleConfirm}
                    >
                        {object.confirmButtonText}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

}

DeleteDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    object: PropTypes.object.isRequired,
};

export default DeleteDialog;
