import {
    CORDOVA_INIT_DONE,
    UPDATE_CONNECTION_INFO,
    PUSH_NOTIFICATION_RECEIVE,
    PUSH_NOTIFICATION_CLEAR,

    FETCH_MERCHANT_DATA_ERROR,
    FETCH_MERCHANT_DATA_REQUEST,
    FETCH_MERCHANT_DATA_SUCCESS,
    UPDATE_MERCHANT_DATA_REQUEST,
    UPDATE_MERCHANT_DATA_SUCCESS,
    UPDATE_MERCHANT_DATA_ERROR,

    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,

    LOAD_API_VERSION_SUCCESS,
    LOAD_API_VERSION_ERROR,
    LOAD_API_VERSION_REQUEST,

    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERROR,

    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_ERROR,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_ERROR,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_ERROR,

    CHANGE_CATEGORY_POSITION_REQUEST,
    CHANGE_CATEGORY_POSITION_SUCCESS,
    CHANGE_CATEGORY_POSITION_ERROR,
    CHANGE_PRODUCT_POSITION_REQUEST,
    CHANGE_PRODUCT_POSITION_SUCCESS,
    CHANGE_PRODUCT_POSITION_ERROR,

    CREATE_PRODUCT_OPTION_GROUP_REQUEST,
    CREATE_PRODUCT_OPTION_GROUP_SUCCESS,
    CREATE_PRODUCT_OPTION_GROUP_ERROR,
    UPDATE_PRODUCT_OPTION_GROUP_REQUEST,
    UPDATE_PRODUCT_OPTION_GROUP_SUCCESS,
    UPDATE_PRODUCT_OPTION_GROUP_ERROR,
    DELETE_PRODUCT_OPTION_GROUP_REQUEST,
    DELETE_PRODUCT_OPTION_GROUP_SUCCESS,
    DELETE_PRODUCT_OPTION_GROUP_ERROR,

    CREATE_PRODUCT_OPTION_REQUEST,
    CREATE_PRODUCT_OPTION_SUCCESS,
    CREATE_PRODUCT_OPTION_ERROR,
    UPDATE_PRODUCT_OPTION_REQUEST,
    UPDATE_PRODUCT_OPTION_SUCCESS,
    UPDATE_PRODUCT_OPTION_ERROR,
    DELETE_PRODUCT_OPTION_REQUEST,
    DELETE_PRODUCT_OPTION_SUCCESS,
    DELETE_PRODUCT_OPTION_ERROR,

    CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST,
    CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS,
    CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR,
    CHANGE_PRODUCT_OPTION_POSITION_REQUEST,
    CHANGE_PRODUCT_OPTION_POSITION_SUCCESS,
    CHANGE_PRODUCT_OPTION_POSITION_ERROR,

    UPDATE_DEVICE_INFORMATION_REQUEST,
    UPDATE_DEVICE_INFORMATION_SUCCESS,
    UPDATE_DEVICE_INFORMATION_ERROR,
} from './constants';

const initialState = {
    cordovaInitDone: false,
    isConnected: false,

    apiVersionLoading: false,
    apiVersionError: null,
    apiVersion: null,

    pushNotification: null,

    merchant: null,
    merchantLastFetched: null,
    pending: false,
    error: null,

    updatePending: false,
    updateError: null,

    verifyPending: false,
    tokenLastVerified: null,

    updateCategoryPending: false,
    updateCategoryError: null,

    updateProductPending: false,
    updateProductError: null,

    updateDeviceInformationLoading: false,
    updateDeviceInformationError: null,
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case CORDOVA_INIT_DONE:
            return {
                ...state,
                cordovaInitDone: true,
            };
        case UPDATE_CONNECTION_INFO:
            return {
                ...state,
                isConnected: action.isConnected,
            };
        case PUSH_NOTIFICATION_RECEIVE:
            return {
                ...state,
                pushNotification: action.pushNotification,
            };
        case PUSH_NOTIFICATION_CLEAR:
            return {
                ...state,
                pushNotification: null,
            };
        case FETCH_MERCHANT_DATA_REQUEST:
            return {
                ...state,
                error: true,
            };
        case FETCH_MERCHANT_DATA_SUCCESS:
            return {
                ...state,
                merchant: action.data,
                merchantLastFetched: new Date(),
                pending: false,
                error: false,
            };
        case FETCH_MERCHANT_DATA_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            };
        case UPDATE_MERCHANT_DATA_REQUEST:
            return {
                ...state,
                updatePending: true,
            };
        case UPDATE_MERCHANT_DATA_SUCCESS:
            return {
                ...state,
                merchant: {
                    ...state.merchant,
                    ...action.data,
                },
                updatePending: false,
            };
        case UPDATE_MERCHANT_DATA_ERROR:
            return {
                ...state,
                updatePending: false,
                updateError: action.error,
            };
        case VERIFY_TOKEN_REQUEST:
            return {
                ...state,
                verifyPending: true,
            };
        case VERIFY_TOKEN_SUCCESS:
        case VERIFY_TOKEN_ERROR:
            return {
                ...state,
                verifyPending: false,
                tokenLastVerified: (new Date()).toISOString(),
            };
        case LOAD_API_VERSION_REQUEST:
            return {
                ...state,
                apiVersionLoading: true,
                apiVersionError: null,
                apiVersion: null,
            };
        case LOAD_API_VERSION_ERROR:
            return {
                ...state,
                apiVersionLoading: false,
                apiVersionError: action.error,
                apiVersion: null,
            };
        case LOAD_API_VERSION_SUCCESS:
            return {
                ...state,
                apiVersionLoading: false,
                apiVersionError: null,
                apiVersion: action.apiVersion,
            };
        case CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                updateCategoryPending: true,
                updateCategoryError: null,
            };
        case CREATE_CATEGORY_SUCCESS: {
            const newState = { ...state, updateCategoryPending: false, updateCategoryError: null };
            newState.merchant.categories = newState.merchant.categories?.concat(action.data) ?? [action.data];
            return newState;
        }
        case CREATE_CATEGORY_ERROR:
            return {
                ...state,
                updateCategoryPending: false,
                updateCategoryError: action.error,
            };
        case UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                updateCategoryPending: true,
                updateCategoryError: null,
            };
        case UPDATE_CATEGORY_SUCCESS: {
            const newState = { ...state, updateCategoryPending: false, updateCategoryError: null };
            newState.merchant.categories =
                newState.merchant.categories.map(c => c._id === action.data._id ? action.data : c);
            return newState;
        }
        case UPDATE_CATEGORY_ERROR:
            return {
                ...state,
                updateCategoryPending: false,
                updateCategoryError: action.error,
            };
        case DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                updateCategoryPending: true,
                updateCategoryError: null,
            };
        case DELETE_CATEGORY_SUCCESS: {
            const newState = { ...state, updateCategoryPending: false, updateCategoryError: null };
            newState.merchant.categories = newState.merchant.categories.filter(c => c._id !== action.cid);
            return newState;
        }
        case DELETE_CATEGORY_ERROR:
            return {
                ...state,
                updateCategoryPending: false,
                updateCategoryError: action.error,
            };
        case CREATE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProductPending: true,
                updateProductError: null,
            };
        case CREATE_PRODUCT_SUCCESS: {
            const newState = { ...state, updateProductPending: false, updateProductError: null };
            newState.merchant.products = newState.merchant.products?.concat(action.data) ?? [action.data];
            return newState;
        }
        case CREATE_PRODUCT_ERROR:
            return {
                ...state,
                updateProductPending: false,
                updateProductError: action.error,
            };
        case UPDATE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProductPending: true,
                updateProductError: null,
            };
        case UPDATE_PRODUCT_SUCCESS: {
            const newState = { ...state, updateProductPending: false, updateProductError: null };
            newState.merchant.products =
                newState.merchant.products.map(p => p._id === action.data._id ? action.data : p);
            return newState;
        }
        case UPDATE_PRODUCT_ERROR:
            return {
                ...state,
                updateProductPending: false,
                updateProductError: action.error,
            };
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                updateProductPending: true,
                updateProductError: null,
            };
        case DELETE_PRODUCT_SUCCESS: {
            const newState = { ...state, updateProductPending: false, updateProductError: null };
            newState.merchant.products = newState.merchant.products.filter(c => c._id !== action.pid);
            return newState;
        }
        case DELETE_PRODUCT_ERROR:
            return {
                ...state,
                updateProductPending: false,
                updateProductError: action.error,
            };
        case CHANGE_PRODUCT_POSITION_REQUEST:
            return {
                ...state,
                updateProductPending: true,
                updateProductError: null,
            };
        case CHANGE_PRODUCT_POSITION_SUCCESS: {
            const newState = { ...state, updateProductPending: false, updateProductError: null };
            newState.merchant.products = newState.merchant.products
                .filter(elem => elem._id !== action.product._id);
            newState.merchant.products.splice(action.position, 0, action.product);
            newState.merchant._version = action._version;
            return newState;
        }
        case CREATE_PRODUCT_OPTION_GROUP_REQUEST:
            return {
                ...state,
                updateProductOptionGroupPending: true,
                updateProductOptionGroupError: null,
            };
        case CREATE_PRODUCT_OPTION_GROUP_SUCCESS: {
            const newState = { ...state, updateProductOptionGroupPending: false, updateProductOptionGroupError: null };
            newState.merchant.productOptionGroups =
                newState.merchant.productOptionGroups?.concat(action.data) ?? [action.data];
            return newState;
        }
        case CREATE_PRODUCT_OPTION_GROUP_ERROR:
            return {
                ...state,
                updateProductOptionGroupPending: false,
                updateProductOptionGroupError: action.error,
            };
        case UPDATE_PRODUCT_OPTION_GROUP_REQUEST:
            return {
                ...state,
                updateProductOptionGroupPending: true,
                updateProductOptionGroupError: null,
            };
        case UPDATE_PRODUCT_OPTION_GROUP_SUCCESS: {
            const newState = { ...state, updateProductOptionGroupPending: false, updateProductOptionGroupError: null };
            newState.merchant.productOptionGroups =
                newState.merchant.productOptionGroups.map(c => c._id === action.data._id ? action.data : c);
            return newState;
        }
        case UPDATE_PRODUCT_OPTION_GROUP_ERROR:
            return {
                ...state,
                updateProductOptionGroupPending: false,
                updateProductOptionGroupError: action.error,
            };
        case DELETE_PRODUCT_OPTION_GROUP_REQUEST:
            return {
                ...state,
                updateProductOptionGroupPending: true,
                updateProductOptionGroupError: null,
            };
        case DELETE_PRODUCT_OPTION_GROUP_SUCCESS: {
            const newState = { ...state, updateProductOptionGroupPending: false, updateProductOptionGroupError: null };
            newState.merchant.productOptionGroups =
                newState.merchant.productOptionGroups.filter(c => c._id !== action.cid);

            // remove deleted optionGroup from products
            newState.merchant.products = newState.merchant.products.map(product => {
                if (!product.optionGroups?.includes(action.cid)) {
                    return product;
                }

                return {
                    ...product,
                    _version: product._version + 1,
                    optionGroups: product.optionGroups?.filter(optionGroupId => optionGroupId !== action.cid),
                };
            });

            return newState;
        }
        case DELETE_PRODUCT_OPTION_GROUP_ERROR:
            return {
                ...state,
                updateProductOptionGroupPending: false,
                updateProductOptionGroupError: action.error,
            };
        case CREATE_PRODUCT_OPTION_REQUEST:
            return {
                ...state,
                updateProductOptionPending: true,
                updateProductOptionError: null,
            };
        case CREATE_PRODUCT_OPTION_SUCCESS: {
            const newState = { ...state, updateProductOptionPending: false, updateProductOptionError: null };
            newState.merchant.productOptions = newState.merchant.productOptions?.concat(action.data) ?? [action.data];
            return newState;
        }
        case CREATE_PRODUCT_OPTION_ERROR:
            return {
                ...state,
                updateProductOptionPending: false,
                updateProductOptionError: action.error,
            };
        case UPDATE_PRODUCT_OPTION_REQUEST:
            return {
                ...state,
                updateProductOptionPending: true,
                updateProductOptionError: null,
            };
        case UPDATE_PRODUCT_OPTION_SUCCESS: {
            const newState = { ...state, updateProductOptionPending: false, updateProductOptionError: null };
            newState.merchant.productOptions =
                newState.merchant.productOptions.map(p => p._id === action.data._id ? action.data : p);
            return newState;
        }
        case UPDATE_PRODUCT_OPTION_ERROR:
            return {
                ...state,
                updateProductOptionPending: false,
                updateProductOptionError: action.error,
            };
        case DELETE_PRODUCT_OPTION_REQUEST:
            return {
                ...state,
                updateProductOptionPending: true,
                updateProductOptionError: null,
            };
        case DELETE_PRODUCT_OPTION_SUCCESS: {
            const newState = { ...state, updateProductOptionPending: false, updateProductOptionError: null };
            newState.merchant.productOptions = newState.merchant.productOptions.filter(c => c._id !== action.pid);
            return newState;
        }
        case DELETE_PRODUCT_OPTION_ERROR:
            return {
                ...state,
                updateProductOptionPending: false,
                updateProductOptionError: action.error,
            };
        case CHANGE_PRODUCT_POSITION_ERROR:
            return {
                ...state,
                updateProductPending: false,
                updateProductError: action.error,
            };
        case CHANGE_CATEGORY_POSITION_REQUEST:
            return {
                ...state,
                updateCategoryPending: true,
                updateCategoryError: null,
            };
        case CHANGE_CATEGORY_POSITION_SUCCESS: {
            const newState = { ...state, updateCategoryPending: false, updateCategoryError: null };
            newState.merchant.categories =
                newState.merchant.categories.filter(elem => elem._id !== action.category._id);
            newState.merchant.categories.splice(action.position, 0, action.category);
            newState.merchant._version = action._version;
            return newState;
        }
        case CHANGE_CATEGORY_POSITION_ERROR:
            return {
                ...state,
                updateCategoryPending: false,
                updateCategoryError: action.error,
            };
        case CHANGE_PRODUCT_OPTION_GROUP_POSITION_REQUEST:
            return {
                ...state,
                updateProductOptionGroupPending: true,
                updateProductOptionGroupError: null,
            };
        case CHANGE_PRODUCT_OPTION_GROUP_POSITION_SUCCESS: {
            const newState = { ...state, updateProductOptionGroupPending: false, updateProductOptionGroupError: null };
            newState.merchant.productOptionGroups =
                newState.merchant.productOptionGroups.filter(elem => elem._id !== action.productOptionGroup._id);
            newState.merchant.productOptionGroups.splice(action.position, 0, action.productOptionGroup);
            newState.merchant._version = action._version;
            return newState;
        }
        case CHANGE_PRODUCT_OPTION_GROUP_POSITION_ERROR:
            return {
                ...state,
                updateProductOptionGroupPending: false,
                updateProductOptionGroupError: action.error,
            };
        case CHANGE_PRODUCT_OPTION_POSITION_REQUEST:
            return {
                ...state,
                updateProductOptionPending: true,
                updateProductOptionError: null,
            };
        case CHANGE_PRODUCT_OPTION_POSITION_SUCCESS: {
            const newState = { ...state, updateProductOptionPending: false, updateProductOptionError: null };
            newState.merchant.productOptions =
                newState.merchant.productOptions.filter(elem => elem._id !== action.productOption._id);
            newState.merchant.productOptions.splice(action.position, 0, action.productOption);
            newState.merchant._version = action._version;
            return newState;
        }
        case CHANGE_PRODUCT_OPTION_POSITION_ERROR:
            return {
                ...state,
                updateProductOptionPending: false,
                updateProductOptionError: action.error,
            };
        case UPDATE_DEVICE_INFORMATION_REQUEST:
            return {
                ...state,
                updateDeviceInformationLoading: true,
                updateDeviceInformationError: null,
            };
        case UPDATE_DEVICE_INFORMATION_SUCCESS:
            return {
                ...state,
                updateDeviceInformationLoading: false,
                updateDeviceInformationError: null,
            };
        case UPDATE_DEVICE_INFORMATION_ERROR:
            return {
                ...state,
                updateDeviceInformationLoading: false,
                updateDeviceInformationError: action.error,
            };
        default:
            return state;
    }
}

export default appReducer;
