import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table } from 'reactstrap';

import FormattedOrderNumber from 'components/FormattedOrderNumber';
import FormattedPhoneNumber from 'components/FormattedPhoneNumber';

import {
    STATE_MAP,
    CANCEL_ENUM,
    USER_TYPE_MAP,
} from 'utils';

function _renderCanceledReasons(order) {
    let reasons = null;

    if (order.canceledReasons) {
        let rowspan = order.canceledReasons.length;

        if (order.canceledComment) {
            rowspan += 1;
        }

        reasons = order.canceledReasons.map((r, i) => {
            if (i === 0) { // First element: set rowspan
                return (
                    <tr key={`r-${r}`}>
                        <th rowSpan={rowspan}>Stornobegründung</th>
                        <td>{CANCEL_ENUM[r]}</td>
                    </tr>
                );
            } else {
                return (
                    <tr key={`r-${r}`}>
                        <td>{CANCEL_ENUM[r]}</td>
                    </tr>
                );
            }
        });
    }

    if (order.canceledComment) {
        if (reasons) {
            reasons.push((
                <tr key="r-comment">
                    <td>{order.canceledComment}</td>
                </tr>
            ));
        } else {
            reasons = [(
                <tr key="r-comment">
                    <th>Stornobegründung</th>
                    <td>{order.canceledComment}</td>
                </tr>
            )];
        }
    }

    return reasons;
}

function OrderDetailsTable({
    order,
    customer,
    isCustomerBlocked,
    onBlock,
    hideFields,
}) {
    const renderBlockButton = (typeof onBlock === 'function');
    const hide = hideFields || [];

    return (
        <Table className="details-table">
            <tbody>
                <tr>
                    <th className="border-0">Abholnummer</th>
                    <td className="border-0 text-right">{order.pickUpNumber}</td>
                </tr>
                <tr>
                    <th>Bestellnummer</th>
                    <td className="text-right">
                        <FormattedOrderNumber value={order.supportId} />
                    </td>
                </tr>
                {(() => {
                    if (order.pickUpCustomerAt) {
                        return (
                            <tr>
                                <th>Abholung</th>
                                <td className="text-right">
                                    {moment(order.pickUpCustomerAt).format('lll')}
                                </td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr>
                                <th>Abholung</th>
                                <td className="text-right">Sofort</td>
                            </tr>
                        );
                    }
                })()}
                {(() => {
                    if (order.ownPackaging) {
                        return (
                            <tr>
                                <th>Verpackung</th>
                                <td className="text-right">Kunde</td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr>
                                <th>Verpackung</th>
                                <td className="text-right">Restaurant</td>
                            </tr>
                        );
                    }
                })()}
                <tr>
                    <th>Bestellt</th>
                    <td className="text-right">{moment(order.createdAt).format('lll')}</td>
                </tr>
                {order.preparedAt &&
                    <tr>
                        <th>Zubereitet</th>
                        <td className="text-right">{moment(order.preparedAt).format('lll')}</td>
                    </tr>
                }
                {order.pickedUpAt &&
                    <tr>
                        <th>Abgeholt</th>
                        <td className="text-right">{moment(order.pickedUpAt).format('lll')}</td>
                    </tr>
                }
                {order.canceledAt &&
                    <tr>
                        <th>Storniert</th>
                        <td className="text-right">{moment(order.canceledAt).format('lll')}</td>
                    </tr>
                }
                {order.canceledBy &&
                    <tr>
                        <th>Storniert von</th>
                        <td className="text-right">
                            { order.canceledBy === USER_TYPE_MAP.CUSTOMER ? 'Kunde' : 'Restaurant' }
                        </td>
                    </tr>
                }
                {_renderCanceledReasons(order)}
                {order.createdBy === USER_TYPE_MAP.CUSTOMER &&
                    <tr>
                        <th>Telefonnummer</th>
                        <td
                            className={
                                isCustomerBlocked
                                    ? 'text-danger'
                                    : ''
                            }
                        >
                            <FormattedPhoneNumber phoneNumber={customer.phoneNumber} />
                            {isCustomerBlocked && (' (blockiert)')}
                            {!isCustomerBlocked && renderBlockButton && (
                                <button
                                    type="button"
                                    className="btn btn-link border-0 m-0 p-0 align-baseline float-right"
                                    onClick={onBlock}
                                >
                                    blockieren
                                </button>
                            )}
                        </td>
                    </tr>
                }
                {(() => {
                    if (order.note && order.note.length > 0) {
                        return (
                            <tr>
                                <th>Anmerkungen</th>
                                <td className="text-right">{order.note}</td>
                            </tr>
                        );
                    }
                    return null;
                })()}
                {!hide.includes('state') &&
                    <tr>
                        <th>Status</th>
                        <td className="text-right">{STATE_MAP[order.state]}</td>
                    </tr>
                }
            </tbody>
        </Table>
    );
}

OrderDetailsTable.propTypes = {
    order: PropTypes.object.isRequired,
    customer: PropTypes.object,
    onBlock: PropTypes.func,
    isCustomerBlocked: PropTypes.bool,
    hideFields: PropTypes.array,
};

export default OrderDetailsTable;
