import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Alert,
    FormGroup,
    Input,
    Label,
    Button,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';

import Toggle from 'components/Toggle';
import Price from 'components/Price';
import MenuCardProductItem from 'components/MenuCardProductItem';
import MenuCardCategoryItem from 'components/MenuCardCategoryItem';
import { getCurrentOpeningHoursForToday, generateTimeSeries, isProductAvailable } from 'utils';
import { PICK_UP_TYPE } from './constants';

import {
    createOrder as createOrderAction,
    setCart as setCartAction,
    resetCart as resetCartAction,
    setNote as setNoteAction,
    setOwnPackaging as setOwnPackagingAction,
    setSelectedCategoryId as setSelectedCategoryIdAction,
    setPickUpTime as setPickUpTimeAction,
} from './actions';

import './styles.css';

function calcTotal(cart) {
    return Object.values(cart).reduce((total, product) => total + product.price, 0);
}

function calcProductPrice(product) {
    return product.options.reduce((total, option) => total + option.priceAdditional, product.price);
}

function collectOptions(options) {
    return Array.from(options.values())
        .flatMap(value => [...value])
        .map(JSON.parse)
        .sort((a, b) => a._id > b._id ? 1 : -1);
}

function Cart({
    merchant,
    locale,
    cart,
    note,
    ownPackaging,
    selectedCategoryId,
    pickUpTime,
    createOrder,
    setCart,
    resetCart,
    setNote,
    setOwnPackaging,
    setSelectedCategoryId,
    setPickUpTime,
}) {
    // To avoid Redux actions during onChange we update a local state variable
    // and update the Redux store 'note' onBlur.
    const [tmpNote, setTmpNote] = useState(note);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(new Map());

    if (!merchant) return null; // TODO: Find a better solution

    const {
        categories, products, productOptionGroups, productOptions,
    } = merchant;

    function submitOrder() {
        const order = {
            note,
            ownPackaging,
            paymentType: 'ON_SITE',
            pickUpType: pickUpTime === 'now' ? PICK_UP_TYPE.now : PICK_UP_TYPE.preOrder,
            ...(pickUpTime !== 'now' && {
                pickUpCustomerAt: pickUpTime,
            }),
        };


        order.products = Object.keys(cart).map(pid => ({
            _id: cart[pid]._id,
            _version: cart[pid]._version,
            quantity: cart[pid].quantity,
            ...(cart[pid].options && cart[pid].options.length && {
                options: cart[pid].options.map(option => ({
                    _id: option._id,
                    _version: option._version,
                })),
            }),
        }));

        createOrder(order).then(() => setTmpNote(''));
    }

    function addToCart(product, options) {
        if (!product.enabled || !isProductAvailable(product)) {
            return;
        }

        const optionsArray = collectOptions(options);

        // create distinct product id by joining product._id and all option._id's.
        const productId = `${[product._id].concat(optionsArray?.map(option => option._id)).join('/')}`;

        const quantity = cart[productId] ? cart[productId].quantity + 1 : 1;

        setCart({
            ...cart,
            [productId]: {
                _id: product._id,
                _version: product._version,
                quantity,
                name: product.name,
                price: quantity * calcProductPrice({
                    price: product.price,
                    options: optionsArray,
                }),
                options: optionsArray,
            },
        });
        resetSelection();
    }

    function removeFromCart(productId) {
        const { [productId]: _, ...other } = cart;
        setCart({ ...other });
        resetSelection();
    }

    function resetOrder() {
        resetCart();
        setTmpNote('');
        resetSelection();
    }

    function resetSelection() {
        setSelectedProduct(null);
        setSelectedOptions(new Map());
    }

    function selectedProductHasOptions() {
        return selectedProduct && Array.isArray(selectedProduct.optionGroups);
    }

    function handleOptionChange(optionGroup, option, event) {
        const orderOptionGroupsMap = new Map(selectedOptions);
        const optionGroupId = optionGroup._id;

        const orderOption = JSON.stringify(option);

        const orderOptionsSet = new Set(orderOptionGroupsMap.get(optionGroupId));
        if (optionGroup.multipleChoice) { // checkbox
            if (event.target.checked) {
                orderOptionsSet.add(orderOption);
            } else {
                orderOptionsSet.delete(orderOption);
            }
        } else { // radio button
            orderOptionsSet.clear();
            orderOptionsSet.add(orderOption);
        }

        orderOptionGroupsMap.set(optionGroupId, orderOptionsSet);
        setSelectedOptions(orderOptionGroupsMap);
    }

    function isOptionSelected(optionGroup, option) {
        const selectedOption = selectedOptions.get(optionGroup._id);

        return selectedOption && selectedOption.has(JSON.stringify(option));
    }

    function setSelectedProductAndInitOptions(product) {
        if (!product.enabled || !isProductAvailable(product)) {
            return;
        }

        setSelectedProduct(product);

        setSelectedOptions(
            product.optionGroups.reduce((map, optionGroupId) => {
                const preSelectedOptions = new Set();

                // pre-select first value of radio button group
                const productOptionGroup = productOptionGroups.find(group => group._id === optionGroupId);
                if (productOptionGroup && !productOptionGroup.multipleChoice) {
                    const preSelectedOption = productOptions.find(
                        option => option.optionGroup === optionGroupId && option.enabled
                    );
                    if (preSelectedOption) {
                        preSelectedOptions.add(JSON.stringify(preSelectedOption));
                    }
                }

                return map.set(optionGroupId, new Set(preSelectedOptions));
            }, new Map())
        );
    }

    function generatePickUpOptions() {
        const options = [];
        const times = getCurrentOpeningHoursForToday(merchant.openingHours);

        options.push(<option key="pu-now" value="now">Sobald wie möglich</option>);

        if (times && times.from && times.to) {
            const start = new Date();
            // first entry is at least 30 mins from now
            start.setMinutes(start.getMinutes() + 30);

            const end = new Date();
            end.setHours(times.to.split(':')[0]);
            end.setMinutes(times.to.split(':')[1]);

            const series = generateTimeSeries(15, start.toISOString(), end.toISOString(), locale);

            series.map(s => (
                options.push(<option key={`pu-${s.short}`} value={s.iso}>{s.short}</option>)
            ));
        }

        return options;
    }

    const renderedCategories = categories?.map(c => (
        <MenuCardCategoryItem
            key={c._id}
            category={c}
            selected={selectedCategoryId === c._id}
            disableArrows
            onClick={() => {
                setSelectedCategoryId(c._id);
                resetSelection();
            }}
        />
    ));

    const renderedProducts = products
        ?.filter(p => p.category === selectedCategoryId && p.enabled)
        .map(p => {
            if (selectedProduct && selectedProduct._id !== p._id) {
                return null;
            }

            return (
                <MenuCardProductItem
                    key={p._id}
                    locale={locale}
                    product={
                        selectedProduct && selectedProduct._id === p._id
                            ? {
                                ...p,
                                price: calcProductPrice({
                                    price: p.price,
                                    options: collectOptions(selectedOptions),
                                }),
                            }
                            : p
                    }
                    selected={selectedProduct && selectedProduct._id === p._id}
                    disableArrows
                    showOptionsIndicator
                    omitInternalName
                    onClick={() => {
                        if (selectedProduct && selectedProduct._id === p._id) {
                            resetSelection();
                        } else if (Array.isArray(p.optionGroups) && p.optionGroups.length > 0) {
                            setSelectedProductAndInitOptions(p);
                        } else {
                            addToCart(p, new Map());
                        }
                    }}
                />
            );
        });

    function renderProductOptions() {

        if (!selectedProductHasOptions()) {
            return null;
        }

        const optionElements =  selectedProduct.optionGroups.map(optionGroupId => {
            const optionGroup = productOptionGroups.find(group => group._id === optionGroupId);

            return (
                <FormGroup key={optionGroup._id}>
                    <legend><small>{optionGroup.name}</small></legend>
                    {optionGroup && productOptions
                        .filter(option => option.optionGroup === optionGroup._id)
                        .map(option => (
                            option.enabled &&
                            <FormGroup check key={option._id}>
                                <Row noGutters>
                                    <Col xs="10">
                                        <Label>
                                            <Input
                                                type={optionGroup.multipleChoice ? 'checkbox' : 'radio'}
                                                name={optionGroup._id}
                                                value={option._id}
                                                checked={isOptionSelected(optionGroup, option)}
                                                onChange={event => handleOptionChange(optionGroup, option, event)}
                                            />
                                            {option.name}
                                        </Label>
                                    </Col>
                                    <Col xs="2">
                                        { option.priceAdditional > 0 && <Price
                                            price={option.priceAdditional}
                                            locale={locale}
                                            tag="small"
                                        /> }
                                    </Col>
                                </Row>

                            </FormGroup>
                        ))}
                </FormGroup>
            );
        });

        return [
            optionElements,
        ];
    }

    const renderedCart = Object.keys(cart).map(pid => (
        <ListGroupItem key={`${pid}-${cart[pid].quantity}`}>
            <Row>
                <Col xs="2" className="my-auto">
                    <strong>{`${cart[pid].quantity}x`}</strong>
                </Col>
                <Col xs="6" className="my-auto cart-order-product">
                    {cart[pid].name}
                    <ul>
                        {
                            cart[pid].options && cart[pid].options.map(option => (
                                <li key={option._id}>
                                    <small>{option.name}</small>
                                </li>
                            ))
                        }
                    </ul>
                </Col>
                <Col xs="2" className="my-auto px-0 text-right">
                    <Price locale={locale} price={cart[pid].price} />
                </Col>
                <Col xs="2" className="my-auto text-right">
                    <Button
                        outline
                        color="secondary"
                        size="sm"
                        onClick={() => removeFromCart(pid)}
                    >
                        <i className="fa fa-times" />
                    </Button>
                </Col>
            </Row>
        </ListGroupItem>
    ));

    return (
        <Row className="no-gutters h-100 w-100">
            <Col
                className="flex-column border-right pt-3 h-100"
                style={{ display: 'flex' }}
            >
                <div className="flex-grow-0 p-2">
                    <h4>Kategorien</h4>
                </div>
                <ListGroup className="px-2">
                    {renderedCategories}
                </ListGroup>
            </Col>
            <Col
                className="flex-column border-right pt-3 h-100"
                style={{ display: 'flex' }}
            >
                <div className="flex-grow-0 p-2">
                    <h4>Produkte</h4>
                </div>
                <ListGroup
                    className={`flex-grow-0 px-2 ${selectedProductHasOptions() || 'overflow-auto'}`}
                    role="listbox"
                >
                    {renderedProducts}
                </ListGroup>
                <div className="flex-grow-1 overflow-auto px-2 pt-3 product-options-form" role="listbox">
                    {renderProductOptions()}
                    <FormGroup
                        row
                        className="px-2 no-gutters"
                        style={{ display: selectedProductHasOptions() || 'none' }}
                    >
                        <Col xs="10" className="pr-1">
                            <Button
                                block
                                onClick={() => addToCart(selectedProduct, selectedOptions)}
                            >
                                In den Warenkorb
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                block
                                color="danger"
                                onClick={() => resetSelection()}
                            >
                                <i className="fa fa-trash-o" />
                            </Button>
                        </Col>
                    </FormGroup>
                </div>
            </Col>
            <Col
                className="flex-column border-right pt-3 h-100"
                style={{ display: 'flex' }}
            >
                <div className="flex-grow-0 p-2">
                    <h4>
                        Bestellung
                        <br />
                        (Gesamt: <Price locale={locale} price={calcTotal(cart)} tag="span" />)
                    </h4>
                </div>
                <FormGroup row className="px-2 no-gutters">
                    <Col xs="10" className="pr-1">
                        <Button
                            block
                            disabled={Object.keys(cart).length <= 0}
                            onClick={submitOrder}
                        >
                            Bestellen
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            block
                            color="danger"
                            onClick={resetOrder}
                        >
                            <i className="fa fa-trash-o" />
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup row className="px-2 no-gutters">
                    <Col>
                        <Input
                            type="textarea"
                            name="note"
                            id="note"
                            placeholder="Anmerkung"
                            value={tmpNote}
                            maxLength="100"
                            onChange={e => setTmpNote(e.target.value)} // Only change tmpNote on every change
                            onBlur={e => setNote(e.target.value)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row className="px-2 no-gutters">
                    <Col xs="4" className="pick-up-time-label">
                        <Label>Abholung</Label>
                    </Col>
                    <Col xs="8">
                        <Input
                            type="select"
                            name="pick-up-time"
                            value={pickUpTime}
                            onChange={e => setPickUpTime(e.target.value)}
                        >
                            {generatePickUpOptions()}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row className="px-2 no-gutters">
                    <Col>
                        <Toggle
                            name="ownPackaging"
                            id="ownPackaging"
                            label="Eigene Verpackung"
                            checked={ownPackaging}
                            onChange={() => setOwnPackaging(!ownPackaging)}
                        />
                    </Col>
                </FormGroup>
                {Object.keys(cart).length > 0
                    ? <ListGroup className="px-2">{renderedCart}</ListGroup>
                    : <Alert color="light">Warenkorb leer</Alert>
                }
            </Col>
        </Row>
    );
}

Cart.propTypes = {
    merchant: PropTypes.object,
    locale: PropTypes.string.isRequired,
    cart: PropTypes.any.isRequired,
    note: PropTypes.string.isRequired,
    ownPackaging: PropTypes.bool.isRequired,
    pickUpTime: PropTypes.string.isRequired,
    selectedCategoryId: PropTypes.string.isRequired,
    createOrder: PropTypes.func.isRequired,
    setCart: PropTypes.func.isRequired,
    resetCart: PropTypes.func.isRequired,
    setNote: PropTypes.func.isRequired,
    setOwnPackaging: PropTypes.func.isRequired,
    setSelectedCategoryId: PropTypes.func.isRequired,
    setPickUpTime: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        merchant: state.app.merchant,
        locale: state.languageProvider.locale,
        cart: state.cart.cart,
        note: state.cart.note,
        ownPackaging: state.cart.ownPackaging,
        selectedCategoryId: state.cart.selectedCategoryId,
        pickUpTime: state.cart.pickUpTime,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createOrder: order => dispatch(createOrderAction(order)),
        setCart: cart => dispatch(setCartAction(cart)),
        resetCart: () => dispatch(resetCartAction()),
        setNote: note => dispatch(setNoteAction(note)),
        setOwnPackaging: ownPackaging => dispatch(setOwnPackagingAction(ownPackaging)),
        setSelectedCategoryId: id => dispatch(setSelectedCategoryIdAction(id)),
        setPickUpTime: pickUpTime => dispatch(setPickUpTimeAction(pickUpTime)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
