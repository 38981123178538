import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './styles.css';

class AllergensSelect extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, value, isSelected) {
        let newValues;

        event.target.blur();

        if (isSelected) {
            newValues = [...this.props.selected, value];
        } else {
            newValues = this.props.selected.filter(s => s !== value);
        }

        this.props.onChange(event, newValues);

        event.preventDefault();
    }

    render() {
        const { selected } = this.props;

        const allergens = [
            'A', 'B', 'C', 'D', 'E', 'F',
            'G', 'H', 'L', 'M', 'N', 'O', 'P', 'R',
        ];

        const buttons = allergens.map(allergen => {
            const isSelected = selected.includes(allergen);

            return (
                <Button
                    key={`allergens-${allergen}`}
                    className={`allergenselect-button ${isSelected ? 'allergen-selected' : 'allergen-not-selected'}`}
                    outline
                    active={isSelected}
                    color="secondary"
                    onClick={e => this.handleClick(e, allergen, !isSelected)}
                >
                    {allergen}
                </Button>
            );
        });

        return (
            <div>
                {buttons}
            </div>
        );
    }

}

AllergensSelect.propTypes = {
    selected: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default AllergensSelect;
