import React, { Component } from 'react';
import {
    Col,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Storage from 'services/Storage';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { LOCALES } from '../../i18n';

class SettingsFormGeneral extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locale: Storage.getLocale(),
        };
    }

    onLocaleChange(e) {
        const locale = e.target.value;
        this.setState({ locale });
        this.props.dispatch(changeLocale(locale));
    }

    render() {
        return (
            <Form>
                <FormGroup row>
                    <Label for="locale" sm={2}>Sprache</Label>
                    <Col sm={10}>
                        <Input
                            type="select"
                            name="locale"
                            id="locale"
                            value={this.state.locale}
                            onChange={e => this.onLocaleChange(e)}
                        >
                            {LOCALES.map(locale => <option key={locale}>{locale}</option>)}
                        </Input>
                    </Col>
                </FormGroup>
            </Form>
        );
    }

}

SettingsFormGeneral.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect()(SettingsFormGeneral));
