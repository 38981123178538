/* eslint-disable global-require */

import enTranslationMessages from './translations/en.json';
import deTranslationMessages from './translations/de.json';

if (!Intl.PluralRules) {
    require('intl-pluralrules');
}

if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
    require('@formatjs/intl-relativetimeformat/dist/locale-data/de');
}

export function getBrowserLocale() {
    const navigator = typeof window === 'undefined' ? null : window.navigator;
    let lang = 'en';

    if (!navigator) {
        return lang;
    } else if (navigator.languages && navigator.languages.length) {
        // latest versions of Chrome and Firefox set this correctly
        [lang] = navigator.languages;
    } else if (navigator.userLanguage) {
        // IE only
        lang = navigator.userLanguage;
    } else {
        // latest versions of Chrome, Firefox, and Safari set this correctly
        lang = navigator.language;
    }

    if (lang.toLowerCase().startsWith('de')) {
        return 'de';
    } else {
        return 'en';
    }
}

export const LOCALES = [
    // 'en',
    'de',
];

export const translationMessages = {
    en: enTranslationMessages,
    de: deTranslationMessages,
};
