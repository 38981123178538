
/*
 * CONSTANTS
 */

export const MERCHANT_STATE_REQUEST = 'Available/MERCHANT_STATE_REQUEST';
export const MERCHANT_STATE_SUCCESS = 'Available/MERCHANT_STATE_SUCCESS';
export const MERCHANT_STATE_ERROR = 'Available/MERCHANT_STATE_ERROR';

export const IS_MERCHANT_ACTIVATED_REQUEST = 'Available/IS_MERCHANT_ACTIVATED_REQUEST';
export const IS_MERCHANT_ACTIVATED_SUCCESS = 'Available/IS_MERCHANT_ACTIVATED_SUCCESS';
export const IS_MERCHANT_ACTIVATED_ERROR = 'Available/IS_MERCHANT_ACTIVATED_ERROR';

export const CHECK_OPEN_STATE = 'Available/CHECK_OPEN_STATE';
