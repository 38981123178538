import EventSource from 'eventsource';

import { API_URI } from 'config';

import Storage from './Storage';

let eventSource = null;

export default class OrdersEventSource {

    // eslint-disable-next-line max-params
    static open(
        dispatch,
        onOpen,
        onPing,
        onMessage,
        onError,
    ) {
        if (eventSource) {
            eventSource.close();
            console.log('OrdersEventSource: connection closed to open new one');
        }

        const url = new URL('/api/merchant/order/stream', API_URI).toString();

        eventSource = new EventSource(url, {
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
            },
        });

        eventSource.onopen = () => {
            console.log('OrdersEventSource: connection opened');
            dispatch(onOpen());
        };

        eventSource.addEventListener('order', e => {
            console.log('OrdersEventSource: order received');
            dispatch(onMessage(JSON.parse(e.data)));
        });

        eventSource.addEventListener('ping', () => {
            // dispatch(onPing());
        });

        eventSource.onerror = err => {
            console.log(`OrdersEventSource: connection error${
                err && err.message ? `: ${err.message}` : ''}`);
            eventSource.close();
            dispatch(onError(err));
        };
    }

    static close(dispatch, onClose) {
        if (eventSource) {
            eventSource.close();
            console.log('OrdersEventSource: connection closed');
            eventSource = null;
            dispatch(onClose());
        }
    }

}
