import React from 'react';
import { Jumbotron } from 'reactstrap';

import { APP_STORE_IOS_URL, APP_STORE_ANDROID_URL } from 'config';

function UpgradeScreen() {
    let buttonName = 'Update';
    let infoMsg = 'Um hol\'s app weiter verwenden zu können installiere bitte die neueste Version.';
    let link = '/';

    if (window.cordova && window.cordova.platformId === 'android') {
        link = APP_STORE_ANDROID_URL;
    } else if (window.cordova && window.cordova.platformId === 'ios') {
        link = APP_STORE_IOS_URL;
    } else {
        buttonName = 'Neu laden';
        infoMsg = 'Um hol\'s app weiter verwenden zu können lade bitte die Seite neu.';
    }

    return (
        <Jumbotron>
            <h1>hol&apos;s app Update Verfügbar</h1>
            <p>
                Es ist eine neuere Version von hol&apos;s app verfügbar.&nbsp;
                {infoMsg}
            </p>
            <p className="lead">
                <a
                    className="btn btn-primary btn-lg"
                    style={{ width: '12rem' }}
                    href={link}
                    role="button"
                >
                    {buttonName}
                </a>
            </p>
        </Jumbotron>
    );
}

export default UpgradeScreen;
