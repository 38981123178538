import {
    play as playAction,
    stop as stopAction,
} from 'containers/AudioPlayer/actions';

const PLAY_OPTIONS = {
    numberOfLoops: 1,
    playAudioWhenScreenIsLocked: true,
};

class Sound {

    static getPlugin() {
        const { cordova } = window;
        if (cordova && window.Media) {
            return window.Media;
        } else {
            throw Error('Sound: Media plugin not available');
        }
    }

    constructor() {
        this.media = undefined;
        this.loop = false;
        this.dispatch = undefined;
    }

    init(dispatch) {
        document.addEventListener('deviceready', () => {
            this.dispatch = dispatch;
            console.log('Sound: Service initialized');
            return Promise.resolve();
        });
    }

    load(file) {
        const { cordova } = window;
        let filePath;

        this.loop = false;

        if (this.media) {
            this.release();
        }

        if (cordova && cordova.platformId === 'android') {
            filePath = `/android_asset/www/${file}`;
        } else {
            // iOS searches in the www directory for the file
            filePath = file;
        }

        const Media = Sound.getPlugin();

        if (filePath) {
            this.media = new Media(
                filePath,
                () => this._handleStop(),
                () => this._handleStop(),
                status => this._handleStatus(status),
            );
        } else {
            this.media = null;
        }
    }

    _handlePlay(loop) {
        if (this.dispatch) {
            this.dispatch(playAction(loop));
        }
    }

    _handleStop() {
        if (this.dispatch && !this.loop) {
            this.dispatch(stopAction());
        }
    }

    _handleStatus(status) {
        const Media  = Sound.getPlugin();
        if (this.loop && status === Media.MEDIA_STOPPED) {
            this.play(true);
        }
    }

    play(loop = false) {
        this.loop = loop;
        if (this.media) {
            this.media.seekTo(0);
            this.media.play({ ...PLAY_OPTIONS });
            this._handlePlay(loop);
            if (!this.media.src) {
                // Dispatch the audio stop action
                this.media.successCallback();
            }
        }
    }

    stop() {
        if (this.media) {
            this.media.pause();
            this._handleStop();
            this.loop = false;
        }
    }

    release() {
        if (this.media) {
            this.media.release();
        }
    }

}

export default new Sound();
