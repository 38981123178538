/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */

/**
 * Public API URIs.
 * Webpacks define plugin will set the uri
 * @type {array}
 */
export const API_URI = __API_URI__; // eslint-disable-line no-undef

/**
 * The REST API version required to work properly.
 * @type {number}
 */
export const API_VERSION = 4;

/**
 * The prefix used to store config items.
 * @type {string}
 */
export const APP_CONFIG_PREFIX = 'HOLS_APP_MERCHANT';

/**
 * links for updating the app
 * @type {string}
 */
export const APP_STORE_IOS_URL = 'itms-apps://itunes.apple.com/app/1436496654';
export const APP_STORE_ANDROID_URL = 'market://details?id=at.holsapp.merchant';
