class FileOpener {

    /**
     * Opens the file with the default viewer on iOS.
     * @param {string} filePath
     * @param {string} mimeType
     * @return {Promise}
     */
    static open(filePath, mimeType) {
        return new Promise((resolve, reject) => {
            const { cordova } = window;

            cordova.plugins.fileOpener2.open(
                filePath,
                mimeType,
                {
                    error: e => {
                        console.error('FileOpener: Error opening file', e);
                        reject(e);
                    },
                    success: () => {
                        console.info('FileOpener: File has been opened');
                        resolve();
                    },
                }
            );
        });
    }

    /**
     * Shows a notification about the downloaded file on Android.
     * @param {string} fileName
     * @param {string} contentType
     * @param {string} filePath
     * @param {number} length
     */
    static showDownloadFinished(fileName, contentType, filePath, length) {
        return new Promise((resolve, reject) => {
            const { cordova } = window;

            cordova.plugins.fileOpener2.showDownloadFinished(
                fileName,
                contentType,
                filePath,
                length,
                {
                    error: e => {
                        console.error('FileOpener: Error showing download finished', e);
                        reject(e);
                    },
                    success: () => {
                        console.info('FileOpener: Show finished download succeeded');
                        resolve();
                    },
                }
            );

        });
    }

}

export default FileOpener;
